import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import smsView from 'WEBSITE/Components/SMSPanel/images/smsView.png';
import onlineSMS from 'WEBSITE/Components/SMSPanel/images/onlineSMS.png';
import customSMS from 'WEBSITE/Components/SMSPanel/images/customSMS.png';
import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/LibraryManagement/images/quickBenefits.png';
import oneSolution from 'WEBSITE/Components/SMSPanel/images/oneSolution.png';
import SMSPortal from 'WEBSITE/Components/SMSPanel/images/SMSPortal.png';
import instantSMS from 'WEBSITE/Components/SMSPanel/images/instantSMS.png';
import longTerm from 'WEBSITE/Components/LibraryManagement/images/longTerm.png';
import effectiveCost from 'WEBSITE/Components/SMSPanel/images/effectiveCost.png';
import manageContact from 'WEBSITE/Components/SMSPanel/images/manageContact.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/SMSPanel/smspanel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SMSPanel extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>SMS - Panel</h1>
                    </div>
                    <div className="main-text">
                        <h4>
                            The most innovative, easy to use, and comprehensive SMS service platform. Scale up seamlessly with our robust SMS gateway and send SMS to all registered stakeholders of the organization. Provide your stakeholders with important updates, notifications, & more by automating internal communication with a unique integrated SMS panel.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report" onClick={() => { document.getElementById('report').scrollIntoView(); }}>
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="sms-view">
                    <div className="smsView">
                    <div className="d-flex justify-content-center">

                        <img src={`${smsView}`} className="smsView-img" />
                        </div>
                    </div>
                </div>
                <div className="features-container2" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                    </div>
                    <div className="online-sms">
                        <div className="online-sms-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Send SMS Online</h5>
                            <div className="feature-description">
                                <p>Now send sms with unique integrated "vmedulife" sms panel built specifically with an eye on the requirement of educational institutes. Send SMS within a few clicks and track the records.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Simple and easy to use SMS panel
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Easy to integrate
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            No extra cost for SMS panel
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="onlineSMS-image col-md-8" style={{ paddingRight: 0 }}>
                        <div className="d-flex justify-content-center">
                            <img src={`${onlineSMS}`} className="onlineSMS-img" />
                            </div>
                        </div>
                    </div>
                    <div className="custom-sms">
                        <div className="customSMS-image col-md-8" style={{ paddingLeft: 0 }}>
                            <img src={`${customSMS}`} className="customSMS-img" />
                        </div>
                        <div className="custom-sms-text col-md-4" style={{ paddingRight: 0 }}>
                            <h5>Customized SMS Template</h5>
                            <div className="feature-description">
                                <p>Send the customized SMS to the stakeholders. Default templates to ready to use the module. Approval process for sending SMS.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Customized Template
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Approval for sending SMS
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Ready to use default template
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="benefits-container1" id="benefits">
                    <div className="benefits-info">
                        <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                            <h3>Benefits</h3>
                            {/* <div className="benefits-text">
                                <p>Perhaps the most important benefit of inventory is saving organization money. Enhanced the efficiency & productivity in operations. Gives transparency, decreases inaccuracies and avoid redundancy.</p>
                            </div> */}
                        </div>
                        {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                            <img src={`${benefitsLink}`} className="benefitLink-img" />
                        </div> */}
                    </div>
                    <div className="benefits-data">
                        <div className="quickBenefits1">
                            <div className="quickBenefits1-header">
                                <img src={`${quickBenefits}`} className="quickBenefits-img" />
                                <h3>Quick Benefits</h3>
                            </div>
                            <div className="quickBenefits1-list">
                                <div className="onePlaceSolution">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${oneSolution}`} className="onePlaceSolution-img" />
                                    </div>
                                    <h5>One Place Solution</h5>
                                    <p>Made sure that all your needs and requirements are clubbed at one place for smooth operation and quick process.</p>
                                </div>
                                <div className="SMSPortal">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${SMSPortal}`} className="SMSPortal-img" />
                                    </div>
                                    <h5>Customize SMS Panel</h5>
                                    <p>Allows  you to customize each and every aspect of SMS Panel to design according to the organization for clean and brisk process.</p>
                                </div>
                                <div className="instantSMS">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${instantSMS}`} className="instantSMS-img" />
                                    </div>
                                    <h5>Instant SMS Delivery</h5>
                                    <p>Gives you the detail view of SMS Status whether it is in queue, pending or delivered condition, it helps to track.</p>
                                </div>
                            </div>
                        </div>
                        <div className="longTerm-benefits1">
                            <div className="longTerm-benefits1-header">
                                <img src={`${longTerm}`} className="longTerm-benefits-img" />
                                <h3>Long Term Benefits</h3>
                            </div>
                            <div className="longBenefits1-list">
                                <div className="effectiveCost">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${effectiveCost}`} className="effectiveCost-img" />
                                    </div>
                                    <h5>Cost Efficient</h5>
                                    <p>Offers you the best in class facilities in minimum cost where you can fulfill  your all requirements in less cost  and time.</p>
                                </div>
                                <div className="manageContact">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${manageContact}`} className="manageContact-img" />
                                    </div>
                                    <h5>Contact Management</h5>
                                    <p>Support you to have agility for managing contacts within few clicks at your finger tips very effortlessly.</p>
                                </div>
                                <div className="senderName">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${SMSPortal}`} className="senderName-img" />
                                    </div>
                                    <h5>Custom Sender Name</h5>
                                    <p>Aid in customizing the organization name for best in class feature which helps to promote and  branding of the respective organization.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container" id="report">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Datewise SMS Delivery Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Monthly SMS Delivery Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Daily SMS Consumption Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>SMS Balance Report</p>
                        </div>
                    </div>
                </div>
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                         
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default SMSPanel;