import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import facultyProfileView from 'WEBSITE/Components/FacultyProfile/images/facultyProfileView.png';
import certifications from 'WEBSITE/Components/FacultyProfile/images/certifications.png';
import experience from 'WEBSITE/Components/FacultyProfile/images/experience.png';
import achievements from 'WEBSITE/Components/FacultyProfile/images/achievements.png';
import affiliation from 'WEBSITE/Components/FacultyProfile/images/affiliation.png';
import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/LibraryManagement/images/quickBenefits.png';
import facultyHub from 'WEBSITE/Components/FacultyProfile/images/facultyHub.png';
import customProfile from 'WEBSITE/Components/FacultyProfile/images/customProfile.png';
import accreditationStandard from 'WEBSITE/Components/FacultyProfile/images/accreditationStandard.png';
import longTerm from 'WEBSITE/Components/ResultAnalysis/images/longTerm.png';
import allTimeAccess from 'WEBSITE/Components/FacultyProfile/images/allTimeAccess.png';
import profileRepository from 'WEBSITE/Components/FacultyProfile/images/profileRepository.png';
import records from 'WEBSITE/Components/FacultyProfile/images/records.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/FacultyProfile/facultyprofile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class FacultyProfile extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Faculty Profile</h1>
                    </div>
                     <div className="main-text">
                        <h4>
                        “Faculty Profile” is a term to indicate the quality of teachers in terms of their qualification, publications, contribution in research, teacher characteristics, adequacy of recruitment procedures, faculty availability, and professional development.Teachers take initiative to learn and keep abreast with the latest developments, to innovate, continuously seek improvement in their work and strive for individual and institutional excellence.
                        </h4>
                    </div> 
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report" onClick={() => { document.getElementById('report').scrollIntoView(); }}>
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="facultyProfile-view">
                    <div className="facultyProfileView">
                        <img src={`${facultyProfileView}`} className="facultyProfileView-img" />
                    </div>
                </div>
                <div className="features-container1" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                    </div>
                    <div className="certifications">
                        <div className="certifications-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Certifications</h5>
                            <div className="feature-description">
                                <p>Adding value to your profile, certificates is one of them. One can easily access and upgrade their respective profile using “vmedulife” faculty profile which is access based.</p>
                                {/* <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Fully Customized
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Maintaining Course Code & Course Credit
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Maintaining Academic Year-wise data of each course
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Customize student list for courses as per requirements
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="certifications-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${certifications}`} className="certifications-img" />
                        </div>
                    </div>
                </div>
                <div className="experience">
                    <div className="experience-image col-md-8" style={{ paddingLeft: 0 }}>
                        <img src={`${experience}`} className="experience-img" />
                    </div>
                    <div className="experience-text col-md-4" style={{ paddingRight: 0 }}>
                        <h5>Professional Experience</h5>
                        <div className="feature-description">
                            <p>Upgrading professional experience has never been easy before, “vmedulife” offers you the best in concept feature with hassle free process where updating professional experience is completed within few clicks at your finger tips.</p>
                            {/* <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Assigning Single Course to Multiple Faculty and vice versa
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Manage course-wise access
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Workload Management view of individual Faculty
                                    </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="achievements">
                    <div className="achievements-text col-md-4" style={{ paddingLeft: 0 }}>
                        <h5>Achievements / Awards</h5>
                        <div className="feature-description">
                            <p>Highlighting your profile with achievements / awards is the only factor everybody likes it, and “vmedulife” has given this facility with exceptional feature and at time saving process.</p>
                            {/* <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Import Syllabus quickly and easily
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Link to Course Outcome
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Link to Learner’s Outcome
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Link to Intellectual skills, Motor skills etc.
                                    </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="achievements-image col-md-8" style={{ paddingRight: 0 }}>
                        <img src={`${achievements}`} className="achievements-img" />
                    </div>
                </div>
                  {/*  <div className="affiliation">
                    <div className="affiliation-image col-md-8" style={{ paddingLeft: 0 }}>
                        <img src={`${affiliation}`} className="affiliation-img" />
                    </div>
                    <div className="affiliation-text col-md-4" style={{ paddingRight: 0 }}>
                        <h5>Professional Affiliation</h5>
                     <div className="affiliation-description">
                            <p>Highlighting your profile with affiliation / awards is the only factor everybody likes it, and “vmedulife” has given this facility with exceptional feature and at time saving process.</p>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Import Syllabus quickly and easily
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Link to Course Outcome
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Link to Learner’s Outcome
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Link to Intellectual skills, Motor skills etc.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="benefits-container1" id="benefits">
                    <div className="benefits-info">
                        <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                            <h3>Benefits</h3>
                            {/* <div className="benefits-text">
                                <p>Perhaps the most important benefit of inventory is saving organisation money. Enhanced the efficiency & productivity in operations. Gives transparency, decreases inaccuracies and avoid redundancy.</p>
                            </div> */}
                        </div>
                        {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                            <img src={`${benefitsLink}`} className="benefitLink-img" />
                        </div> */}
                    </div>
                    <div className="benefits-data">
                        <div className="quickBenefits1">
                            <div className="quickBenefits1-header">
                                <img src={`${quickBenefits}`} className="quickBenefits-img" />
                                <h3>Quick Benefits</h3>
                            </div>
                            <div className="quickBenefits1-list">
                                <div className="facultyHub">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${facultyHub}`} className="facultyHub-img" />
                                    </div>
                                    <h5>Faculty Hub</h5>
                                    <p>Offers you to Maintain all faculty related important documents and data at one place with high security and safety.</p>
                                </div>
                                <div className="customProfile">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${customProfile}`} className="customProfile-img" />
                                    </div>
                                    <h5>Custom Profile</h5>
                                    <p>Gives an upper hand to segregate and keep separate database for each and every individual faculty with it’s best in class concept of customized profile.</p>
                                </div>
                                <div className="accreditationStandard">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${accreditationStandard}`} className="accreditationStandard-img" />
                                    </div>
                                    <h5>Accreditation Standards</h5>
                                    <p>Allows you to work with rules, regulations and standards maintained by accreditation in simplest and quickest way by hassle free process.</p>
                                </div>
                            </div>
                        </div>
                        <div className="longTerm-benefits1">
                            <div className="longTerm-benefits1-header">
                                <img src={`${longTerm}`} className="longTerm-benefits-img" />
                                <h3>Long Term Benefits</h3>
                            </div>
                            <div className="longBenefits1-list">
                                <div className="allTimeAccess">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${allTimeAccess}`} className="allTimeAccess-img" />
                                    </div>
                                    <h5>24*7 Access</h5>
                                    <p>One can  easily access to the faculty profile by having 24*7 access which enables them to upgrade their profile as well as maintain all important documents and data.</p>
                                </div>
                                <div className="profileRepository">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${profileRepository}`} className="profileRepository-img" />
                                    </div>
                                    <h5>Profile Repository</h5>
                                    <p>One can  easily access to the faculty profile by having 24*7 access which enables them to upgrade their profile as well as maintain all important documents and data.</p>
                                </div>
                                <div className="records">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${records}`} className="records-img" />
                                    </div>
                                    <h5>Records</h5>
                                    <p>Help you in 360<sup>0</sup> format where all important documents and data related to faculty can  be reviewed anywhere and at anytime within few clicks at your finger tips.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container" id="report">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Faculty Cadre Proportion</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Faculty Qualification</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Faculty Retention</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Innovations by the Faculty in Teaching and Learning</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Faculty as participants in Faculty development/training activities/STTPs</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Research and Development-Academic Research</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Research and Development- Sponsored Research</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Research and Development- Development activities</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Consultancy (from Industry)</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Faculty Performance Appraisal and Development System</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Visiting/Adjunct/Emeritus Faculty</p>
                        </div>                        
                    </div>
                </div>
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container1" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                           
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default FacultyProfile;