import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import courseFileView from 'WEBSITE/Components/CourseFile/images/courseFileView.png';
import courseFileFeatures from 'WEBSITE/Components/CourseFile/images/courseFileFeatures.png';
import courseSession from 'WEBSITE/Components/CourseFile/images/courseSession.png';
import courseExam from 'WEBSITE/Components/CourseFile/images/courseExam.png';
import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/LibraryManagement/images/quickBenefits.png';
import quickGenerate from 'WEBSITE/Components/CourseFile/images/quickGenerate.png';
import perfectCustomized from 'WEBSITE/Components/CourseFile/images/perfectCustomized.png';
import conventionalProcess from 'WEBSITE/Components/CourseFile/images/conventionalProcess.png';
import longTerm from 'WEBSITE/Components/LibraryManagement/images/longTerm.png';
import monitorRecords from 'WEBSITE/Components/CourseFile/images/monitorRecords.png';
import onCloud from 'WEBSITE/Components/CourseFile/images/onCloud.png';
import accreditationIntegration from 'WEBSITE/Components/CourseFile/images/accreditationIntegration.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/LibraryManagement/librarymanagement.css';
import 'WEBSITE/Components/CourseFile/coursefile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CourseFile extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Course File - Academic</h1>
                    </div>
                    <div className="main-text">
                        <h4>
                            Complete details of courses will be available as per academic year, stream and groups / Semester on the go. A particular course related all sessions, examinations, assignments, course outcomes, learning outcomes and student list data will be available within a few clicks and in a hassle free process.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report">
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="courseFile-view">
                    <div className="courseFileView">
                        <img src={`${courseFileView}`} className="courseFileView-img" />
                    </div>
                </div>
                <div className="features-container2" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                    </div>
                    <div className="course-session">
                        <div className="course-session-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Course Session</h5>
                            <div className="feature-description">
                                <p>A best in class benefit of getting complete session list of respective courses with details including date, time, syllabus covered & remaining, teaching methodology and attendance of students.</p>
                                {/* <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Export data
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Identify Asset with Unique Stock Inward Number
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Linking of Purchase Order
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="courseSession-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${courseSession}`} className="courseSession-img" />
                        </div>
                    </div>
                    <div className="course-exam">
                        <div className="courseExam-image col-md-8" style={{ paddingLeft: 0 }}>
                            <img src={`${courseExam}`} className="courseExam-img" />
                        </div>
                        <div className="course-exam-text col-md-4" style={{ paddingRight: 0 }}>
                            <h5>Course Exam</h5>
                            <div className="feature-description">
                                <p>This function guides to get total list of examinations conducted  and assignments added to the respective course, with a significant view of performance of students in particular examination or assignment with detailed report.</p>
                                {/* <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Quick Import of existing asset into system.
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Export data for reconciliation of an asset
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Search Assets with simple and easy Tags
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="courseFileFeatures-view">
                    <div className="courseFileFeatures">
                        <div className="d-flex justify-content-center">
                        <img src={`${courseFileFeatures}`} className="courseFileFeatures-img" />
                        </div>
                    </div>
                </div>
                <div className="benefits-container" id="benefits">
                    <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                        <h3>Benefits</h3>
                        {/* <div className="benefits-text">
                            <p>The supreme benefit of Library Management System is to find the educational material at one place and any time with well segragate form.</p>
                        </div> */}
                    </div>
                    {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                        <img src={`${benefitsLink}`} className="benefitLink-img" />
                    </div> */}
                </div>
                <div className="quickBenefits">
                    <div className="quickBenefits-header">
                        <img src={`${quickBenefits}`} className="quickBenefits-img" />
                        <h3>Quick Benefits</h3>
                    </div>
                    <div className="quickBenefits-list">
                        <div className="quickGenerate">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${quickGenerate}`} className="quickGenerate-img" />
                            </div>
                            <h5>Quickly Generate</h5>
                            <p>Generates report easily and smoothly within few clicks with best in class hassle free process.</p>
                        </div>
                        <div className="perfectCustomized">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${perfectCustomized}`} className="perfectCustomized-img" />
                            </div>
                            <h5>Perfectly Customizable</h5>
                            <p>Customize the process according to the respective needs , and helps them to track according to it.</p>
                        </div>
                        <div className="conventionalProcess">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${conventionalProcess}`} className="conventionalProcess-img" />
                            </div>
                            <h5>Conventionalized Process</h5>
                            <p>Best in concept feature of making and creating course file in a simplified manner.</p>
                        </div>
                    </div>
                </div>
                <div className="longTerm-benefits">
                    <div className="longTerm-benefits-header">
                        <img src={`${longTerm}`} className="longTerm-benefits-img" />
                        <h3>Long Term Benefits</h3>
                    </div>
                    <div className='longTerm-benefits-list'>
                        <div className="monitorRecords">
                            <div className="monitorRecords-image">
                                <img src={`${monitorRecords}`} className="monitorRecords-img" />
                            </div>
                            <div className="monitorRecords-text">
                                <h5>Monitor Records</h5>
                                <p>Ease of access to monitor the records from past within few clicks with breezy process.</p>
                            </div>
                        </div>
                        <div className="onCloud">
                            <div className="onCloud-image">
                                <img src={`${onCloud}`} className="onCloud-img" />
                            </div>
                            <div className="onCloud-text">
                                <h5>On the Cloud</h5>
                                <p>One can easily access the documents, records and even can create course files on the go having 24*7 access with vmedulife cloud software.</p>
                            </div>
                        </div>
                        <div className="accreditationIntegration">
                            <div className="accreditationIntegration-image">
                                <img src={`${accreditationIntegration}`} className="accreditationIntegration-img" />
                            </div>
                            <div className="accreditationIntegration-text">
                                <h5>Accreditation Integration</h5>
                                <p>Helps and promotes to add all accreditation points related to course from day first itself to reduce bottleneck process afterwards.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                 <div className="report-container">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Course Outcome Statements</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Desired Course Outcome Mapping</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Proposed Teaching Plan for individual course</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Completion report for the teaching plan against the individual course</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Course wise gap analysis report</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Applied teaching methodologies</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Assessments and Evaluation conducted</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Result analysis along with Outcome Attainment</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Students details enrolled for the course</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Course feedback analysis and actions for improvements</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Course End Surveys analysis and actions for improvements</p>
                        </div>
            
               
                    </div>
                </div> 
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                           
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CourseFile;