import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import lmsView from 'WEBSITE/Components/LearningManagement/images/lmsView.png';
import checked from 'WEBSITE/Components/LibraryManagement/images/checked.png';
import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import featureImage from 'WEBSITE/Components/LibraryManagement/images/featureImage.png';
import courseCreation from 'WEBSITE/Components/LearningManagement/images/courseCreation.png';
import courseCollaboration from 'WEBSITE/Components/LearningManagement/images/courseCollaboration.png';
import courseCertificate from 'WEBSITE/Components/LearningManagement/images/courseCertificate.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/ResultAnalysis/images/quickBenefits.png';
import learningContent from 'WEBSITE/Components/LearningManagement/images/learningContent.png';
import unlimitedAccess from 'WEBSITE/Components/LearningManagement/images/unlimitedAccess.png';
import learnerProgress from 'WEBSITE/Components/LearningManagement/images/learnerProgress.png';
import longTerm from 'WEBSITE/Components/LibraryManagement/images/longTerm.png';
import learningCost from 'WEBSITE/Components/LearningManagement/images/learningCost.png';
import quickLearning from 'WEBSITE/Components/LearningManagement/images/quickLearning.png';
import socialLearning from 'WEBSITE/Components/LearningManagement/images/socialLearning.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/LibraryManagement/librarymanagement.css';
import 'WEBSITE/Components/LearningManagement/learningmanagement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class LearningManagement extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Learning Management System</h1>
                    </div>
                    <div className="main-text">
                        <h4>
                            A learning management system (LMS) is for the administration, documentation, tracking, reporting, automation and delivery of educational courses, training programs, or learning and development programs.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report" onClick={() => { document.getElementById('report').scrollIntoView(); }}>
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="lms-view">
                    <div className="lmsView">
                        <img src={`${lmsView}`} className="lmsView-img" />
                    </div>
                </div>
                <div className="features-container" id="features">
                    <div className="features-list col-md-6">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                        <div className="feature">
                            <div className="checked-image">
                                <img src={`${checked}`} className="checked-img" />
                            </div>
                            <div className="feature-text">
                                <p className='feature-text-primary'>
                                    Managing users, courses, roles, and generating reports
                                </p>
                                <p className='feature-text-secondary'>
                                    This helps uploading courses, assigning roles, and generating various reports.
                                </p>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="checked-image">
                                <img src={`${checked}`} className="checked-img" />
                            </div>
                            <div className="feature-text">
                                <p className='feature-text-primary'>
                                    Making a course calendar
                                </p>
                                <p className='feature-text-secondary'>
                                    This feature helps in managing the course activities.
                                </p>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="checked-image">
                                <img src={`${checked}`} className="checked-img" />
                            </div>
                            <div className="feature-text">
                                <p className='feature-text-primary'>
                                    Tracking and feedback
                                </p>
                                <p className='feature-text-secondary'>
                                    Track course assignments and completions, and know which courses your learners / instructors take most often and which might need improvements based on feedback of their effectiveness.
                                </p>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="checked-image">
                                <img src={`${checked}`} className="checked-img" />
                            </div>
                            <div className="feature-text">
                                <p className='feature-text-primary'>
                                    Badges and Leaderboard
                                </p>
                                <p className='feature-text-secondary'>
                                    Engage and motivate users with achievements, points, and badges, all based on their activity in the system. 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="features-image col-md-6">
                        <img src={`${featureImage}`} className="feature-img" />
                    </div>
                </div>
                <div className="course-creation">
                    <div className="courseCreation-image col-md-8">
                        <img src={`${courseCreation}`} className="courseCreation-img" />
                    </div>
                    <div className="course-creation-text col-md-4">
                        <h5>Course Creation</h5>
                        <div className="feature-description">
                            <p>This helps in creating course and enrolling the learners to it. You can also upload the content of the course in number of multimedia ways.</p>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${checked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Customize Quiz
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${checked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Enroll Specific Learner to Specific Course
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${checked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Manage Access of Learner
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="course-collaboration">
                    <div className="course-collaboration-text col-md-4">
                        <h5>Course Collaboration</h5>
                        <div className="feature-description">
                            <p>This helps in creating course and enrolling the learners to it. You can also upload the content of the course in number of multimedia ways.</p>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${checked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Customize Quiz
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${checked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Enroll Specific Learner to Specific Course
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${checked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Manage Access of Learner
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="courseCollaboration-image col-md-8">
                        <div className="d-flex justify-content-center">
                        <img src={`${courseCollaboration}`} className="courseCollaboration-img" />
                        </div>
                    </div>
                </div>
                <div className="course-certificate">
                    <div className="courseCertificate-image col-md-8">
                        <div className="d-flex justify-content-center">
                        <img src={`${courseCertificate}`} className="courseCertificate-img" />
                        </div>
                    </div>
                    <div className="course-certificate-text col-md-4">
                        <h5>Course Completion Certificate</h5>
                        <div className="feature-description">
                            <p>A Course Completion Certificate which states that, respective course has been completed, this can be generated quickly and easily at your finger tips click in hassle free process. This leads to “GO GREEN” movement which reduces the use of paper and help for greener nation.</p>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${checked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Instant certificate for learner after course completion
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${checked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Access certificate on the go
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${checked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Maintain certificate records
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="benefits-container2" id="benefits">
                    <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                        <h3>Benefits</h3>
                        <div className="benefits-text">
                            <p>The main objective of Learning Management Systems is to enhance the learning process. A Learning Management System not only delivers content, but also handles registering courses, course administration, skill gap analysis, tracking, and reporting.</p>
                        </div>
                    </div>
                    {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                        <img src={`${benefitsLink}`} className="benefitLink-img" />
                    </div> */}
                </div>
                <div className="quickBenefits2">
                    <div className="quickBenefits2-header">
                        <img src={`${quickBenefits}`} className="quickBenefits-img" />
                        <h3>Quick Benefits</h3>
                    </div>
                    <div className="quickBenefits-list">
                        <div className="learningContent">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${learningContent}`} className="learningContent-img" />
                            </div>
                            <h5>Organizes Learning Content in one Location</h5>
                            <p>Instead of having your Learning content all over the place, drives and devices, you can store all of your Learning materials in one location. This reduces the risk of losing important data and makes it easier and simpler to create your Learning course instantly.</p>
                        </div>
                        <div className="unlimitedAccess">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${unlimitedAccess}`} className="unlimitedAccess-img" />
                            </div>
                            <h5>Provides Unlimited Access to Learning Materials</h5>
                            <p>Once you upload your Learning course materials into the LMS and publish them, your learners / instructors has unlimited access to the information they need. Even those who are on the go can login to the platform via their smartphones and tablets, so that they don’t have to wait until their next session.</p>
                        </div>
                        <div className="learnerProgress">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${learnerProgress}`} className="learnerProgress-img" />
                            </div>
                            <h5>Easily Tracks Learner Progress and Performance</h5>
                            <p>Tracking Learner’s progress and performance milestone is the robust and important feature of Learning Management System, for instance learner is not able to complete its course, Learning Management System offer’s you to give supplemental resource to improve the Learner’s performance.</p>
                        </div>
                    </div>
                </div>
                <div className="longTerm-benefits2">
                    <div className="longTerm-benefits2-header">
                        <img src={`${longTerm}`} className="longTerm-benefits-img" />
                        <h3>Long Term Benefits</h3>
                    </div>
                    <div className='longTerm-benefits-list'>
                        <div className="learningCost">
                            <div className="learningCost-image">
                                <img src={`${learningCost}`} className="learningCost-img" />
                            </div>
                            <div className="learningCost-text">
                                <h5>Reduces Learning and Development costs</h5>
                                <p>Learning Management System gives you the power to completely reduce the instructor travel costs, online training site rentals, and printed Learning materials. Your learners can carry out all of their learning online, which means that you can save a great amount of sum on your Learning and Development budget.</p>
                            </div>
                        </div>
                        <div className="quickLearning">
                            <div className="quickLearning-image">
                                <img src={`${quickLearning}`} className="quickLearning-img" />
                            </div>
                            <div className="quickLearning-text">
                                <h5>Quick Learning and Development time</h5>
                                <p>Learning Management System helps reduce online training times, thanks to the fact that it gives learners information they need in a direct and well organized manner. Instead of having to sit through a lengthy online courses, learners can simply click on the online modules they need and absorb the knowledge in a fraction of a time.</p>
                            </div>
                        </div>
                        <div className="socialLearning">
                            <div className="socialLearning-image">
                                <img src={`${socialLearning}`} className="socialLearning-img" />
                            </div>
                            <div className="socialLearning-text">
                                <h5>Integrates social learning experiences</h5>
                                <p>Learning Management System makes it simple and easy to integrate physical learning into online Learning strategy. Since the LMS is already cloud base software, you can include links and online forums that may be beneficial for your learners / instructors.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container" id="report">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Academic yearwise course count report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Individual faculty course report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Global course list report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Courses in collaboration report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Classwise student course completion report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Student and instructor learning list report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Coursewise discussion Q & A report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Individual course Q & A report</p>
                        </div>
                    </div>
                </div>
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                        
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default LearningManagement;