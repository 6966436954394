import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import 'WEBSITE/Components/Events/events.css';

class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventDetails:[
                {
                    id:1,
                    date: 'June 4, 2020',
                    title:'Nation Level Webinar for Use of ICT tools for Outcome Attainment and Teaching Learning',
                    venue: 'Hosted by vmedulife Software'
                },
                {
                    id:2,
                    date: 'May 30, 2020',
                    title:'Webinar- Outcome Based Education- Use of ICT tools for Outcome Attainment',
                    venue: '  Lokmanya Tilak College of Engineering, Navi Mumbai'
                },
                {
                    id:3,
                    date: 'May 28, 2020',
                    title:'Nation Level Webinar for Outcome Based Education- Use of ICT tools for Outcome Attainment',
                    venue: ' Hosted by vmedulife Software for National Level'
                },
                {
                    id:4,
                    date: 'May 14, 2020',
                    title:'National Level Webinar for Outcome Based Education- Use of ICT tools for Outcome Attainment',
                    venue: 'Hosted by vmedulife Software for National Level'
                },
                {
                    id:5,
                    date: 'March 09, 2020',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife digital portal',
                    venue: '  BGS Institute of Technology, B.G Nagara, Bengaluru – Hassan National Highway, Karnataka'
                },
                {
                    id:6,
                    date: 'March 04, 2020',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife digital portal',
                    venue: '  Siddhartha Institute of Technology & Sciences (SITS), Hyderabad, Telangana'
                },
                {
                    id:7,
                    date: 'Feb 22, 2020',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife digital portal',
                    venue: '  Shambhunath Institute of Engineering and Technology, Prayagraj, Uttar Pradesh'
                },
                {
                    id:8,
                    date: 'Feb 15, 2020',
                    title:'Course Outcome and Program Outcome Mapping- Various Aspects and Use of software',
                    venue: '  KSR College of Engineering, Tiruchengode, KSR Kalvi Nagar, Tamil Nadu'
                },
                {
                    id:9,
                    date: 'Feb 14, 2020',
                    title:'Course Outcome and Program Outcome Mapping- Various Aspects and Use of software',
                    venue: '  ISL College of Engineering, Hyderabad, Telangana'
                },
                {
                    id:10,
                    date: 'Feb 08, 2020',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife digital portal',
                    venue: '  Poona College of Pharmacy, Pune'
                },
                {
                    id:11,
                    date: 'Feb 05, 2020',
                    title:'Course Outcome and Program Outcome Mapping- Various Aspects and Use of software',
                    venue: '  Lloyd Institute of Management and Technology (Pharm.), Greater Noida, Uttar Pradesh'
                },
                {
                    id:12,
                    date: 'Feb 04, 2020',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife digital portal',
                    venue: '  R V Northland Institute of Pharmacy, Greater Noida, Uttar Pradesh'
                },
                {
                    id:13,
                    date: 'Feb 02, 2020',
                    title:'Course Outcome and Program Outcome Mapping- Various Aspects and Use of software',
                    venue: '  Vels Institute of Science, Technology & Advanced Studies (VISTAS), Chennai, Tami Nadu'
                },
                {
                    id:14,
                    date: 'Jan 30, 2020',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife digital portal',
                    venue: '  PSCMR College of Engineering, Vijaywada, Andhra Pradesh'
                },
                {
                    id:15,
                    date: 'Jan 29, 2020',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife digital portal',
                    venue: '  Vignan Pharmacy College, Vadlamudi, Guntur, Andhra Pradesh'
                },
                {
                    id:16,
                    date: 'Jan 10, 2020',
                    title:'Course Outcome and Program Outcome Mapping- Various Aspects and Use of software',
                    venue: '  Indian Institute of Plantation Management, Bangalore'
                },
                {
                    id:17,
                    date: '02 - 04 Jan, 2020',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife digital portal	',
                    venue: '  Vignan Institute of Technology and Science, Hyderabad'
                },
                {
                    id:18,
                    date: 'Dec 09, 2019',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife digital portal',
                    venue: '  Ram-eesh Institute of Pharmacy, Noida, Delhi'
                },
                {
                    id:19,
                    date: 'Nov 28, 2019',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife digital portal',
                    venue: '  Sipna College of Engineering, Amravati'
                },
                {
                    id:20,
                    date: 'Sept 23, 2019',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife digital portal',
                    venue: '  Finolex Academy of Management and Technology, Ratnagiri'
                },
                {
                    id:21,
                    date: 'Sept 04, 2019',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife digital portal',
                    venue: '  Pimpri Chinchwad College Of Engineering and Research, Ravet, Pune'
                },
                {
                    id:22,
                    date: 'Aug 27, 2019',
                    title:'Digital Learning Awareness Workshop and Use of vmedulife Learning Management System and Information and Communication Technologies in Teaching Learning',
                    venue: '  S.S.S.K.R. INNANI MAHAVIDYALAYA KARANJA(LAD), Washim'
                },
                {
                    id:23,
                    date: 'Aug 26, 2019',
                    title:'Digital Learning Awareness Workshop and Use of vmedulife Learning Management System and Information and Communication Technologies in Teaching Learning',
                    venue: '  P Wadhwani College of Pharmacy, Yavatmal'
                },
                {
                    id:24,
                    date: 'Aug 23, 2019',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife digital portal',
                    venue: "  MGM's College Of Engineering, Nanded"
                },
                {
                    id:25,
                    date: 'Aug 21, 2019',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife digital portal',
                    venue: "  Mahatma Gandhi Mission's College of Engineering and Technology, Kamothe, Panvel, Mumbai"
                },
                {
                    id:26,
                    date: 'July 19, 2019',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife digital portal',
                    venue: '   Appasaheb Birnale College of Pharmacy, Sangli'
                },
                {
                    id:27,
                    date: 'July 02, 2019',
                    title:'Outcome Based Education Software- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife innovative platform',
                    venue: '  Bharati Vidyapeeth College of Engineering, Kolhapur'
                },
                {
                    id:28,
                    date: 'Apr 08, 2019',
                    title:'Use of LMS/ ICT in teaching learning and mechanism of Grievance Redressal Portal using vmedulife software',
                    venue: '  DY Patil College of Engineering, Aakurdi, Pune'
                },
                {
                    id:29,
                    date: 'Apr 04, 2019',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife digital portal',
                    venue: '  Tatyasaheb Kore College of Pharmacy, Warnanagar, Kolhapur'
                },
                {
                    id:30,
                    date: '18 - 19 Feb, 2019',
                    title:'Digital Learning Awareness Workshop and Use of Learning Management System and Information and Communication Technologies in Teaching Learning',
                    venue: '10th IPA National Student Congress, Rajahmundry, Andhra Pradesh'
                },
                {
                    id:31,
                    date: 'Jan 30, 2019',
                    title:'Outcome Based Education Software- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife innovative platform',
                    venue: '  SVPM’s College of Pharmacy, Malegaon, Baramati'
                },
                {
                    id:32,
                    date: '24 - 25 Sept, 2018',
                    title:'Digital Learning Awareness Workshop and Use of vmedulife Learning Management System and Information and Communication Technologies in Teaching Learning',
                    venue: '  Dayanand College of Pharmacy, Latur'
                },
                {
                    id:33,
                    date: 'Jun 27, 2018',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife innovative platform',
                    venue: '  Bharati Vidyapeeth College of Pharmacy, Kolhapur'
                },
                {
                    id:34,
                    date: 'Apr 30, 2018',
                    title:'Outcome Based Education- Use of ICT tools for Outcome Attainment and Teaching Learning with vmedulife digital portal',
                    venue: '  Sri Indu College of Pharmacy, Hyderabad, Telangana'
                },
                {
                    id:35,
                    date: 'March 21, 2018',
                    title:'Use of LMS and ICT in Outcome Based Education with vmedulife innovative platform',
                    venue: '  Vikas Institute of Pharmaceutical Sciences, Rajahmundry, Andhra Pradesh'
                },
                {
                    id:36,
                    date: 'Feb 09, 2018',
                    title:'Digital Learning Awareness Workshop',
                    venue: '  Rajarshi Shahu College of Pharmacy, Buldhana'
                },
                {
                    id:37,
                    date: 'Feb 08, 2018',
                    title:'Use of Learning Management System and Information and Communication Technologies in Teaching Learning',
                    venue: '  Government College of Pharmacy, Amravati'
                },
                {
                    id:38,
                    date: 'Feb 07, 2018',
                    title:'Digital Learning Awareness Workshop and Use of Learning Management System and Information and Communication Technologies in Teaching Learning',
                    venue: '  Vidyabharti College of Pharmacy, Amravati'
                },
                {
                    id:39,
                    date: 'Jan 18, 2018',
                    title:'Use of Learning Management System and Information and Communication Technologies in Teaching Learning',
                    venue: '  Tapi Valley Education Societys Honble Loksevak Madhukarrao Chaudhari College of Pharmacy, Faizpur, Taluka Yawal, Jalgaon'
                },
                {
                    id:40,
                    date: 'Jan 09, 2018',
                    title:'Digital Learning Awareness Workshop',
                    venue: '  Kalyani Charitable Trust, Ravindra Gambhirrao Sapkal College of Pharmacy, Nashik'
                },
                {
                    id:41,
                    date: 'Jan 08, 2018',
                    title:'Faculty Development Program on "Innovation in Teaching Pedagogy"',
                    venue: '  Matoshri College of Pharmacy, Eklahare,Nashik'
                },
                {
                    id:42,
                    date: 'Jan 05, 2018',
                    title:'Use of Learning Management System and Information and Communication Technologies in Teaching Learning',
                    venue: "  Marathwada Mitra Mandal's College of Pharmacy,Thergaon( Kalewadi) ,Pune"
                },
                {
                    id:43,
                    date: 'Jan 01, 2018',
                    title:'Use of Learning Management System and Information and Communication Technologies in Teaching Learning',
                    venue: "  Progressive Education Society's Modern College of Pharmacy, Moshi, Pune"
                },
                {
                    id:44,
                    date: 'Dec 22, 2017',
                    title:'"TRAINING TOMORROW’S TEACHER" Teaching Through Technology',
                    venue: '  Samarth Education Trust Arvind Gavali college of Pharmacy, Satara'
                },
                {
                    id:45,
                    date: 'Dec 22, 2017',
                    title:'Use of Learning Management System and Information and Communication Technologies in Teaching Learning',
                    venue: '  Kasegaon Education Societys Rajarambapu College of Pharmacy, Kasegaon, Sangli'
                },
                {
                    id:46,
                    date: 'Dec 18, 2017',
                    title:'Digital Learning Awareness Workshop',
                    venue: '  Rajamata Jijau Shikshan Prasark Mandal Institute of Pharmacy, DudulGaon, Pune'
                },
                {
                    id:47,
                    date: 'Oct 28, 2017',
                    title:'Professionalism and Teaching methodologies',
                    venue: '  Rajamata Jijau Shikshan Prasark Mandal Institute of Pharmacy, DudulGaon, Pune'
                },
                {
                    id:48,
                    date: '06 - 07 Oct, 2017',
                    title:'Faculty Development Program on Pedagogical Techniques and Proactive Academic Delivery System',
                    venue: '  Kai Yashodabai Dagadu Saraf Charitable Trusts Institute of Pharmacy, Sakegaon, Jalgaon'
                },
                {
                    id:49,
                    date: '06 - 07 Oct, 2017',
                    title:'Faculty Development Program on Pedagogical Techniques and Proactive Academic Delivery System',
                    venue: '  Institute of Management and Science, Sakegaon, Jalgaon'
                },
                {
                    id:50,
                    date: 'Apr 24, 2017',
                    title:'Teaching Methodologies',
                    venue: "  Marathwada Mitra Mandal's College of Pharmacy,Thergaon( Kalewadi) ,Pune"
                },
                {
                    id:51,
                    date: 'March 27, 2017',
                    title:'Faculty Development Program on Pedagogy',
                    venue: "  Progressive Education Society's Modern College of Pharmacy, Nigdi, Pune"
                },
                {
                    id:52,
                    date: 'March 25, 2017',
                    title:'Teaching Methodologies',
                    venue: '  Dr. D. Y. Patil Vidya Pratishthan Society Dr.D.Y.Patil Instiute of Pharmceutical Science &Research,Pimpri, Pune'
                },
                {
                    id:53,
                    date: 'Feb 18, 2017',
                    title:'Teaching Methodologies',
                    venue: '  Matoshri College of Pharmacy, Eklahare,Nashik'
                },
                {
                    id:54,
                    date: 'Feb 08, 2017',
                    title:'Faculty Development Program on Pedagogy',
                    venue: '  Hon. Shri. Babanrao Pachpute Vichardhara Trust, Group of Institutions (Integrated Campus)-Parikrama, Kashti, Ahmednagar'
                },
                {
                    id:55,
                    date: 'Feb 05, 2017',
                    title:'Teaching Methodologies',
                    venue: '  Rameshwar Mauli Pratishthan Bhalchndra College of Pharmacy, Pune'
                },
                {
                    id:56,
                    date: 'Feb 04, 2017',
                    title:'Faculty Development Programme on Effective Pedagogical Tools in Education',
                    venue: '  Samarth Education Trust Arvind Gavali college of Pharmacy, Satara'
                },
                {
                    id:57,
                    date: 'Jan 27, 2017',
                    title:'Faculty Development Program on "Innovation in Teaching Pedagogy"',
                    venue: "  Shri Chatrapati Sambhaji Shikshan Sanstha's Sitabai Thite College of Pharmacy, Shirur, (Ghodnadi), Pune"
                },
                {
                    id:58,
                    date: 'Jan 05, 2017',
                    title:'Faculty Development Program on Pedagogical Techniques and Proactive Academic Delivery System',
                    venue: '  Dattakala College of Pharmacy, Chincholi,Daund, Pune'
                }
            ],
        };     
    }

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }


    render() {
        return (
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="events-header">
                    <h5>Product Trainings & FDP's</h5>
                </div>
                <div className="events-info">
                    <h1>“Developing Next Generation Teachers”</h1>
                </div>
                <div className="events-info">
                    <h6>Quality of teacher decides the quality of student and thereby next generation</h6>
                </div>
                <div className="eventsList-container">
                {/** 
                    <h5 style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Events Conducted for Faculties</h5>
                    */}
                    <div className="eventList">
                        {
                            this.state.eventDetails.map(events => {
                                return (
                                    <>
                                    <div className="row" key={events.id}>
                                    <div className="col-md-2">
                                       {events.date}
                                    </div>        
                                    <div className="col-md-6">
                                        <h5>{events.title}</h5>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>{events.venue}</h6>
                                    </div>
                                    </div>
                                    <hr></hr>
                                    </>
                                );
                            })
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default Events;