import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import alumniView from 'WEBSITE/Components/AlumniManagement/images/alumniView.png';
import alumniDatabase from 'WEBSITE/Components/AlumniManagement/images/alumniDatabase.png';
import reunions from 'WEBSITE/Components/AlumniManagement/images/reunions.png';
import studentDevelopment from 'WEBSITE/Components/AlumniManagement/images/studentDevelopment.png';
import alumniFunds from 'WEBSITE/Components/AlumniManagement/images/alumniFunds.png';
import employerBranding from 'WEBSITE/Components/AlumniManagement/images/employerBranding.png';
import talentNeeds from 'WEBSITE/Components/AlumniManagement/images/talentNeeds.png';
import regularUpdate from 'WEBSITE/Components/AlumniManagement/images/regularUpdate.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/LibraryManagement/images/quickBenefits.png';
import updatedDatabase from 'WEBSITE/Components/AlumniManagement/images/updatedDatabase.png';
import timeSaving from 'WEBSITE/Components/AlumniManagement/images/timeSaving.png';
import costEfficient from 'WEBSITE/Components/AlumniManagement/images/costEfficient.png'
import longTerm from 'WEBSITE/Components/LibraryManagement/images/longTerm.png';
import access from 'WEBSITE/Components/AlumniManagement/images/access.png';
import alumniProgress from 'WEBSITE/Components/AlumniManagement/images/alumniProgress.png';
import involvement from 'WEBSITE/Components/AlumniManagement/images/involvement.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/LibraryManagement/librarymanagement.css';
import 'WEBSITE/Components/InventoryManagement/inventorymanagement.css';
import 'WEBSITE/Components/TrainingPlacement/trainingplacement.css';
import 'WEBSITE/Components/AlumniManagement/alumnimanagement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AlumniManagement extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Alumni</h1>
                    </div>
                    <div className="main-text">
                        <h4>The Alumni are a strong support to the institution. An active Alumni Association can contribute in academic matters, student support as well as mobilization of resources – both financial and non financial.</h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report" onClick={() => { document.getElementById('report').scrollIntoView(); }}>
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="alumni-view">
                    <div className="alumniView">
                        <img src={`${alumniView}`} className="alumniView-img" />
                    </div>
                </div>
                <div className="features-container3" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="alumniDatabase">
                                    <img src={`${alumniDatabase}`} className="alumniDatabase-img" />
                                    <h4>Build and Manage your Alumni Database</h4>
                                </div>
                                </div>
                            <div className="col-md-6">
                                <div className="reunions">
                                    <img src={`${reunions}`} className="reunions-img mr-4" />
                                    <h4>Plan & Organize Successful Reunions</h4>
                                </div>
                            </div>
                        </div>
                       

                    </div>
                </div>
                <div className="features2-container">
                        <div className="row">
                        <div className="col-md-6">
                        <div className="studentDevelopment">
                        <img src={`${studentDevelopment}`} className="studentDevelopment-img" />
                        <h4>Involve Alumni in Student Development</h4>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="alumniFunds">
                            <img src={`${alumniFunds}`} className="alumniFunds-img mr-4" />
                            <h4>Raise Funds From Alumni</h4>

                        </div>
                    </div>
                    </div>
                </div>
                <div className="features3-container">
                <div className="row">
                <div className="col-md-6">
                    <div className="employerBranding">
                        <img src={`${employerBranding}`} className="employerBranding-img" />
                        <h4>Enhance Your Employer Branding</h4>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="talentNeeds">
                    <img src={`${talentNeeds}`} className="talentNeeds-img" />
                        <h4 >Meet Talent Needs - Seek Referrals</h4>
                    </div>
                    </div>
                    </div>
                </div>
                <div className="features4-container">
                    <div className="regularUpdate">
                        <img src={`${regularUpdate}`} className="regularUpdate-img" />
                        <h4>Send Regular updates to Alumni</h4>
                    </div>
                </div>
                <div className="benefits-container1" id="benefits">
                    <div className="benefits-info">
                        <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                            <h3>Benefits</h3>
                            {/* <div className="benefits-text">
                                <p>The supreme benefit of Library Management System is to find the educational material at one place and any time with well segragate form.</p>
                            </div> */}
                        </div>
                        {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                            <img src={`${benefitsLink}`} className="benefitLink-img" />
                        </div> */}
                    </div>
                    <div className="benefits-data">
                        <div className="quickBenefits1">
                            <div className="quickBenefits1-header">
                                <img src={`${quickBenefits}`} className="quickBenefits-img" />
                                <h3>Quick Benefits</h3>
                            </div>
                            <div className="quickBenefits1-list">
                                <div className="consumption">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${updatedDatabase}`} className="consumption-img" />
                                    </div>
                                    <h5>Updated Database</h5>
                                    <p>Highly secured, safe and updated database where all the alumni related data and documents is stored and maintain.</p>
                                </div>
                                <div className="import">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${timeSaving}`} className="import-img" />
                                    </div>
                                    <h5>Saves Time</h5>
                                    <p>With all amenities and access finding and identifying the alumni database made made very simple and quick which results in lot of time saving and energy saving.</p>
                                </div>
                                <div className="breakage">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${costEfficient}`} className="breakage-img" />
                                    </div>
                                    <h5>Cost Effective</h5>
                                    <p>It is true value for money software  which enables and ensures you to save the important data and documents related to alumni on the cloud where anyone can access  them on the go.</p>
                                </div>
                            </div>
                        </div>
                        <div className="longTerm-benefits1">
                            <div className="longTerm-benefits1-header">
                                <img src={`${longTerm}`} className="longTerm-benefits-img" />
                                <h3>Long Term Benefits</h3>
                            </div>
                            <div className="longBenefits1-list">
                                <div className="valueSaving">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${access}`} className="valueSaving-img" />
                                    </div>
                                    <h5>Access 24*7</h5>
                                    <p>One can easily access to its highly secured and safe database anywhere and anytime within few clicks at your finger tips.</p>
                                </div>
                                <div className="lowStock">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${alumniProgress}`} className="lowStock-img" />
                                    </div>
                                    <h5>Alumni Progress</h5>
                                    <p>Organization now can have full access to its alumni progress and achievements where it helps them to integrate with the existing students for better and extremely important sharing of thoughts, views and experience.</p>
                                </div>
                                <div className="precisePlanning">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${involvement}`} className="precisePlanning-img" />
                                    </div>
                                    <h5>Alumni Involvement</h5>
                                    <p>This helps to nourish the organization culture and work  process where alumni helps  them to grow exponentially better for the existing students work flow.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container" id="report">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Batchwise Alumni List Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Alumni Profile Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Alumni Events Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Funds Raised Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p> Alumni contribution during the last five years</p>
                    </div>
                    </div>
                </div>
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container1" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                       
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AlumniManagement;