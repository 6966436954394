import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import trainingView from 'WEBSITE/Components/TrainingPlacement/images/trainingView.png';
import openings from 'WEBSITE/Components/TrainingPlacement/images/openings.png';
import recruitment from 'WEBSITE/Components/TrainingPlacement/images/recruitment.png';
import placementBrochure from 'WEBSITE/Components/TrainingPlacement/images/placementBrochure.png';
import training from 'WEBSITE/Components/TrainingPlacement/images/training.png';
import trainingPhoto from 'WEBSITE/Components/TrainingPlacement/images/trainingPhoto.png'; 
import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/LibraryManagement/images/quickBenefits.png';
import trainingHub from 'WEBSITE/Components/TrainingPlacement/images/trainingHub.png';
import straightProcess from 'WEBSITE/Components/TrainingPlacement/images/straightProcess.png';
import notifications from 'WEBSITE/Components/TrainingPlacement/images/notifications.png';
import longTerm from 'WEBSITE/Components/LibraryManagement/images/longTerm.png';
import accessRecords from 'WEBSITE/Components/TrainingPlacement/images/accessRecords.png';
import clarifiedProcess from 'WEBSITE/Components/TrainingPlacement/images/clarifiedProcess.png';
import globalConnection from 'WEBSITE/Components/TrainingPlacement/images/globalConnection.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/LibraryManagement/librarymanagement.css';
import 'WEBSITE/Components/InventoryManagement/inventorymanagement.css';
import 'WEBSITE/Components/TrainingPlacement/trainingplacement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class TrainingPlacement extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Training & Placement</h1>
                    </div>
                    <div className="main-text">
                        <h4>
                           
Training & Placement Management Software is catered to meet everyone’s needs from student to colleges to recruiters where each and every detail is covered from student’s point of view, TPO’s point of view, and recruiter point of view.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report" onClick={() => { document.getElementById('report').scrollIntoView(); }}>
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="training-view">
                    <div className="trainingView">
                        <div className="d-flex justify-content-center">
                        <img src={`${trainingView}`} className="trainingView-img" />
                        </div>
                    </div>
                </div>
                <div className="features-container1" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                    </div>
                    <div className="openings-drives">
                        <div className="openings-drives-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Openings & Drives</h5>
                            <div className="feature-description">
                                <p>One can easily add openings with custom categorization and every details of recruiters which is  made available from recruiters itself. Openings can either be of internship type, part time job or full time job etc. according to the requirement of recruiters.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Custom categorization of opening type
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Maintain track & records of openings
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Link openings to drives with each and every minute details
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opening-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${openings}`} className="opening-img" />
                        </div>
                    </div>
                </div>
                <div className="recruitment-process">
                    <div className="recruitment-image">
                    <div className="d-flex justify-content-center">
                        <img src={`${recruitment}`} className="recruitment-img" />
                        </div>
                    </div>
                    {/* <div className="asset-management-text col-md-4" style={{ paddingRight: 0 }}>
                        <h5>Asset Management</h5>
                        <div className="asset-description">
                            <p>Asset Management is the process that guides the asset and it’s daily use. It helps in Maintaining and tracking the records of asset which leads into improvising the inventory of an organization.</p>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Quick Import of existing asset into system.
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Export data for reconciliation of an asset
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Search Assets with simple and easy Tags
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="placement-brochure">
                    <div className="placement-brochure-text col-md-4" style={{ paddingLeft: 0 }}>
                        <h5>Placement Brochure</h5>
                        <div className="feature-description">
                            <p>Student’s which got selected in their placement and drives through organization is displayed here with their reviews and support which made available from organization throughout their journey.</p>
                        </div>
                    </div>
                    <div className="placementBrochure-image col-md-8" style={{ paddingRight: 0 }}>
                        <img src={`${placementBrochure}`} className="placementBrochure-img" />
                    </div>
                </div>
                <div className="training">
                    <div className="training-image col-md-8" style={{ paddingLeft: 0 }}>
                        <img src={`${training}`} className="training-img" />
                    </div>
                    <div className="training-text col-md-4" style={{ paddingRight: 0 }}>
                        <h5>Training Management</h5>
                        <div className="feature-description">
                            <p>Effortlessly and swiftly management of training is carried out where participants can view and apply for specific training. These training is custom categorized  with multiple options such as type of training, organization which is conducting training, time and date etc.</p>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Custom type of training
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Export training data into PDF and Excel format
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="training-photo">
                    <div className="training-photo-text col-md-4" style={{ paddingLeft: 0 }}>
                        <h5>Training Recollections</h5>
                        <div className="feature-description">
                            <p>Training and training details such as images and documents which are important can be view and upload to the software with minimum number of links which will help in further to track and maintain records of it.</p>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Upload photos and documents
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="trainingPhoto-image col-md-8" style={{ paddingRight: 0 }}>
                        <img src={`${trainingPhoto}`} className="trainingPhoto-img" />
                    </div>
                </div>
                <div className="benefits-container" id="benefits">
                    <div className="benefits-header col-md-12" style={{ paddingLeft: 0 }}>
                        <h3>Benefits</h3>
                         <div className="benefits-text">
                            <p>Capture data and reports related to students and employers which can be accessed by administrators with a single click in a safe and secure environment.</p>
                        </div> 
                    </div>
                    {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                        <img src={`${benefitsLink}`} className="benefitLink-img" />
                    </div> */}
                </div>
                <div className="quickBenefits">
                    <div className="quickBenefits-header">
                        <img src={`${quickBenefits}`} className="quickBenefits-img" />
                        <h3>Quick Benefits</h3>
                    </div>
                    <div className="quickBenefits-list">
                        <div className="stateArt">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${trainingHub}`} className="stateArt-img" />
                            </div>
                            <h5>Training & Placement Hub</h5>
                            <p>It is a hub where  all important documents and data related to training and placement of students is stored and maintained at high end security and safety.</p>
                        </div>
                        <div className="personalized">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${straightProcess}`} className="personalized-img" />
                            </div>
                            <h5>Straightforward Process</h5>
                            <p>Offers hassle free process of planning & training activities and its execution very smoothly and effortlessly within few clicks at your finger tips.</p>
                        </div>
                        <div className="documentation">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${notifications}`} className="documentation-img" />
                            </div>
                            <h5>Instant Notifications</h5>
                            <p>Allows student to keeo update regarding its training & placement activities instantly by notifying them time to time and on the go.</p>
                        </div>
                    </div>
                </div>
                <div className="longTerm-benefits">
                    <div className="longTerm-benefits-header">
                        <img src={`${longTerm}`} className="longTerm-benefits-img" />
                        <h3>Long Term Benefits</h3>
                    </div>
                    <div className='longTerm-benefits-list'>
                        <div className="value-efficient">
                            <div className="valueEfficient-image">
                                <img src={`${accessRecords}`} className="valueEfficient-img" />
                            </div>
                            <div className="valueEfficient-text">
                                <h5>Access Records</h5>
                                <p>Helps user to find and identify the previous batch records of training and placement of students very easily and quickly.</p>
                            </div>
                        </div>
                        <div className="secure">
                            <div className="secure-image">
                                <img src={`${clarifiedProcess}`} className="secure-img" />
                            </div>
                            <div className="secure-text">
                                <h5>Clarified and Modernize Process</h5>
                                <p>Observe strategic growth in the placements due to clarity on every minute work performed for training and placement activities.</p>
                            </div>
                        </div>
                        <div className="secure">
                            <div className="secure-image">
                                <img src={`${globalConnection}`} className="secure-img" />
                            </div>
                            <div className="secure-text">
                                <h5>Connected Globally</h5>
                                <p>Get job applicants from other institutes using public sharable links for placement registration.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container" id="report">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>No. of students placed in companies or Government Sector</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>No. of students admitted to higher studies with valid qualifying scores</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>No. of students turned entrepreneur in engineering/technology</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Placement Index</p>
                        </div>
                      
                    </div>
                </div>
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container1" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                          
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                            <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default TrainingPlacement;