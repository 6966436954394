import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import feesView from 'WEBSITE/Components/Fees/images/feesView.png';
import quickCollect from 'WEBSITE/Components/Fees/images/quickCollect.png';
import feesCollect from 'WEBSITE/Components/Fees/images/feesCollect.png';
import feeChallan from 'WEBSITE/Components/Fees/images/feeChallan.png';
import feeReceipt from 'WEBSITE/Components/Fees/images/feeReceipt.png';
import paymentGateway from 'WEBSITE/Components/Fees/images/paymentGateway.png';
import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/LibraryManagement/images/quickBenefits.png';
import feeCollection from 'WEBSITE/Components/Fees/images/feeCollection.png';
import multipleFees from 'WEBSITE/Components/Fees/images/multipleFees.png';
import gatewayPayment from 'WEBSITE/Components/Fees/images/gatewayPayment.png';
import longTerm from 'WEBSITE/Components/LibraryManagement/images/longTerm.png';
// import depthAnalysis from 'WEBSITE/Components/Fees/images/depthAnalysis.png';
// import automateUpdate from 'WEBSITE/Components/Fees/images/automateUpdate.png';
// import dataLoss from 'WEBSITE/Components/Fees/images/dataLoss.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/Fees/fees.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Fees extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Fees Management Software</h1>
                    </div>
                    <div className="main-text">
                        <h4>
                            “vmedulife” Fees Management is well designed and executed software for everyone with supreme features and latest integration of payment gateways mode which will help you to collect fees instantly in hassle free process and with multiple reports just a click away.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report" onClick={() => { document.getElementById('report').scrollIntoView(); }}>
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="fees-view">
                    <div className="feesView">
                        <img src={`${feesView}`} className="feesView-img" />
                    </div>
                </div>
                <div className="features-container2" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                    </div>
                    <div className="quick-collect">
                        <div className="quick-collect-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Quick Collect</h5>
                            <div className="feature-description">
                                <p>Quick Collect empower you to identify the student’s fees detail thoroughly where it helps to maintain records and to create a challan quickly and easily.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Customize fee structure respective to individual student
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Search Student details with multiple options such as name, email ID, admission number etc.
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Can use single fee head for multiple time
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="quickCollect-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${quickCollect}`} className="quickCollect-img" />
                        </div>
                    </div>
                    <div className="fees-collect">
                        <div className="feesCollect-image col-md-8" style={{ paddingLeft: 0 }}>
                            <img src={`${feesCollect}`} className="feesCollect-img" />
                        </div>
                        <div className="fees-collect-text col-md-4" style={{ paddingRight: 0 }}>
                            <h5>Fees Collect</h5>
                            <div className="feature-description">
                                <p>Fees Collect assist in, to view the student total fees collection at a glance where it offers to generate fees receipt and view the history of student as well as differentiate group of student by academic yearwise, and their respective streamwise.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Collect fees of student
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Make fee payment and view individual student payment history
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Refund management easily and quickly
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fees-challan">
                        <div className="fees-challan-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Fees Challan</h5>
                            <div className="feature-description">
                                <p>Fees Challan helps to generate fee challan with unique identification of alphanumeric number which aids in identify individual challan assigned to individual student. Also it gives an option of importing the existing fees challan and rejecting challan.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Generate fees challan
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Collect the generated fees challan
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Filter out the challan by its status and date of generation
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Import challan into the system in hassle free process
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="feesChallan-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${feeChallan}`} className="feesChallan-img" />
                        </div>
                    </div>
                    <div className="fee-receipt">
                        <div className="feeReceipt-image col-md-8" style={{ paddingLeft: 0 }}>
                            <img src={`${feeReceipt}`} className="feeReceipt-img" />
                        </div>
                        <div className="fee-receipt-text col-md-4" style={{ paddingRight: 0 }}>
                            <h5>Fee Receipt</h5>
                            <div className="feature-description">
                                <p>It is a customized configuration of receipt where one can design the receipt of either challan or of fees collection receipt according to its convenience. </p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Customization of receipts
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="payment-gateway">
                        <div className="payment-gateway-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Payment Gateway</h5>
                            <div className="feature-description">
                                <p>Enables to pay the fees with multiple options in highly safe and secure way on the go.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Can pay fees by card payment 
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Can pay fees by using UPI / QR Code
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Can pay fees by using Netbanking 
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Can pay fees on EMI basis
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="paymentGateway-image col-md-8" style={{ paddingRight: 0 }}>
                        <div className="d-flex justify-content-center">

                            <img src={`${paymentGateway}`} className="paymentGateway-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="benefits-container" id="benefits">
                    <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                        <h3>Benefits</h3>
                        <div className="benefits-text">
                            <p>Offers the industry leading technology to collect fees in well systematic way, where it reduces the long queues of students, valuable time of both student & staff and paperless process which leads in “Go Green” movement.</p>
                        </div>
                    </div>
                    {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                        <img src={`${benefitsLink}`} className="benefitLink-img" />
                    </div> */}
                </div>
                <div className="quickBenefits">
                    <div className="quickBenefits-header">
                        <img src={`${quickBenefits}`} className="quickBenefits-img" />
                        <h3>Quick Benefits</h3>
                    </div>
                    <div className="quickBenefits-list">
                        <div className="feeCollection">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${feeCollection}`} className="feeCollection-img" />
                            </div>
                            <h5>Simplify Fee Collection and Maintaining Record</h5>
                            <p>Providing a hassle free process where fees from student’s are collected easily and their respective data is generated instantly.</p>
                        </div>
                        <div className="multipleFees">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${multipleFees}`} className="multipleFees-img" />
                            </div>
                            <h5>Assigning Multiple Fees to Single Student and Vice versa</h5>
                            <p>Equally distributing of fees in the form of its type where each and every student get assigned its fee structure according to their respective category.</p>
                        </div>
                        <div className="gatewayPayment">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${gatewayPayment}`} className="gatewayPayment-img" />
                            </div>
                            <h5>Payment Gateway</h5>
                            <p>Made paying fees simple and trouble free with it’s best in class payment gateway, where one can pay fees on the go.</p>
                        </div>
                    </div>
                </div>
                 <div className="longTerm-benefits">
                    <div className="longTerm-benefits-header">
                        <img src={`${longTerm}`} className="longTerm-benefits-img" />
                        <h3>Long Term Benefits</h3>
                    </div>
                    <div className='longTerm-benefits-list'>
                        <div className="depth-analysis">
                            <div className="depth-analysis-image">
                              {/**  <img src={`${depthAnalysis}`} className="depth-analysis-img" />*/} 
                            </div>
                            <div className="depth-analysis-text">
                                <h5>Student wise payment lifecycle</h5>
                                <p>Get the complete payment history for any individual student since the date of their admission in the organization. vmedulife Software will enable the organization for fetching the Individual Student's Fee Summary Report within few clicks only.</p>
                            </div>
                        </div>
                        <div className="automateUpdate">
                            <div className="automateUpdate-image">
                              {/**   <img src={`${automateUpdate}`} className="automateUpdate-img" />*/}
                            </div>
                            <div className="automateUpdate-text">
                                <h5>Centralized Management of the fees data</h5>
                                <p>vmedulife supports a multi-campus structure that enables trusts & educational societies to have centralised database & streamlined operations across unitary institutions, schools/colleges, while maintaining their individual autonomy.</p>
                            </div>
                        </div>
                        <div className="dataLoss">
                            <div className="dataLoss-image">
                               {/**  <img src={`${dataLoss}`} className="dataLoss-img" />*/}
                            </div>
                            <div className="dataLoss-text">
                                <h5>Compliance to Accreditation Requirements</h5>
                                <p>Compliance to Accreditation Requirements
                                Get the easy access for multiple reports which are usually required for the past few academic years and be always ready for  accreditations from agencies like NBA/NAAC by managing institutional data, maintaining necessary documents & proofs and generating error-free reports.</p>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container" id="report">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Daily Collect Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Students benefit by Government Scholarship & Freeship</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Yearwise Student Previous & Current Balance Fee Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Academic Yearwise Fee Analysis</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Number of Students benefit by Institution Scholarship & Freeship</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Students benefit by Institution Scholarship & Freeship</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Number of Students benefit by Government Scholarship & Freeship</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Individual Student Fee Summary Report</p>
                        </div>
                    </div>
                </div>
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                           
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Fees;