import React from 'react';
import modernize from 'WEBSITE/Components/AboutCompany/images/modernize.png';
import coreValues from 'WEBSITE/Components/AboutCompany/images/coreValues.png';
import 'WEBSITE/Components/AboutCompany/aboutcompany.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AboutCompany extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="company-header">
                    <h5>About vmedulife</h5>
                </div>
                <div className="company-message">
                    <div className="company-text col-md-5">
                        <h1>"Modernizing and Adding Values in Education"</h1>
                    </div>
                    <div className="modernize-image col-md-7">
                    <div className='d-flex justify-content-center'>
                        <img src={`${modernize}`} className="modernize-img" />
                        </div>
                    </div>
                </div>
                <div className="companyInfo-container">
                    <div >
                        <h3 className="pl-3 pt-2">Why vmedulife ?</h3>
                        <h6 className="pl-3 pr-3 text-justify">vmedulife is a cloud-based learning platform provider for various educational institutions. vmedulife was established in 2013, we have developed an Academic Management System that helps the students, faculties, and the managerial board. You can keep a track of anything and everything related to the academic setup with our user-friendly services.</h6>
                        <h6 className="pl-3 pr-3 text-justify">We have successfully demonstrated our unique teaching-learning platform to many of the NAAC/NBA committee members in the past few years.</h6>
                        <h6 className="pl-3 pr-3 text-justify">Online platform access for all stakeholders.</h6>
                        <h6 className="pl-3 pr-3 text-justify">Our Smart Outcome Based Education module has a built-in digital repository and a decision-making tool.</h6>
                        <h6 className="pl-3 pr-3 text-justify">Reliable and transparent brand providing academic software solutions. Innovative tools help the Management, Principal, and HODs to generate reports, graphs, and charts, with ease and in the fewest of the clicks. This helps you to make well-informed decisions with all the necessary information.</h6>
                        <h6 className="pl-3 pr-3 text-justify">vmedulife Platform is implemented across India in more than 500+ institutions and helping more than 1 million users for better education.</h6>
                    </div>
                    <div className="core-values">
                        <div className="coreValues-list col-md-6">
                            <h3 style={{ marginBottom: 30 }}>Our Core Values</h3>
                            <div>
                                <h4 className="font-weight-bold">Transparency</h4>
                            </div>
                            <br></br>
                            <div>
                                <h4 className="font-weight-bold">Integrity</h4>
                            </div>
                            <br></br>

                            <div>
                                <h4 className="font-weight-bold">Honesty</h4>
                            </div>
                        </div>
                        <div className="coreValues-image col-md-6">
                            <img src={`${coreValues}`} className="coreValues-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AboutCompany;