import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import resultAnalysisView from 'WEBSITE/Components/ResultAnalysis/images/resultAnalysisView.png';
import scheduleExam from 'WEBSITE/Components/ResultAnalysis/images/scheduleExam.png';
import questionPaper from 'WEBSITE/Components/ResultAnalysis/images/questionPaper.png';
import performance from 'WEBSITE/Components/ResultAnalysis/images/performance.png';
import result from 'WEBSITE/Components/ResultAnalysis/images/result.png';
import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/ResultAnalysis/images/quickBenefits.png';
import depthAnalysis from 'WEBSITE/Components/ResultAnalysis/images/depthAnalysis.png';
import studentPerformance from 'WEBSITE/Components/ResultAnalysis/images/studentPerformance.png';
import difficultyAnalysis from 'WEBSITE/Components/ResultAnalysis/images/difficultyAnalysis.png';
import longTerm from 'WEBSITE/Components/ResultAnalysis/images/longTerm.png';

// import longTerm from 'WEBSITE/Components/ResultAnalysis/images/longTerm.png';
// import accessRecords from 'WEBSITE/Components/TrainingPlacement/images/accessRecords.png';
// import clarifiedProcess from 'WEBSITE/Components/TrainingPlacement/images/clarifiedProcess.png';
// import globalConnection from 'WEBSITE/Components/TrainingPlacement/images/globalConnection.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/LibraryManagement/librarymanagement.css';
import 'WEBSITE/Components/InventoryManagement/inventorymanagement.css';
import 'WEBSITE/Components/TrainingPlacement/trainingplacement.css';
import 'WEBSITE/Components/ResultAnalysis/resultanalysis.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ResultAnalysis extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Result Analysis</h1>
                    </div>
                    <div className="main-text">
                        <h4>
                            Conducting and managing examination data for offline exam is now effortless with the "vmedulife" Result Analysis module. Starting from examination scheduling, question paper generation, student enrollment, and student performance can be easily managed in the system.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>

                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report">
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="resultView-view">
                    <div className="resultView">
                    <div className="d-flex justify-content-center">
                        <img src={`${resultAnalysisView}`} className="resultView-img" />
                        </div>
                    </div>
                </div>
                <div className="features-container1" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                    </div>
                    <div className="schedule-examination">
                        <div className="schedule-examination-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Schedule Examination</h5>
                            <div className="feature-description">
                                <p>Being the first step in the examination, planning the examination using cloud software will be hassle free. Include the examination for multiple semesters, departments at a time and generate the timetable within clicks.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Plan the examination for multiple departments
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Conduct examination offline
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Facilitate multiple SET for one subject
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Access based system for scheduling exam
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="scheduleExamination-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${scheduleExam}`} className="scheduleExamination-img" />
                        </div>
                    </div>
                </div>
                <div className="question-paper">
                    <div className="questionPaper-image col-md-8" style={{ paddingLeft: 0 }}>
                        <img src={`${questionPaper}`} className="questionPaper-img" />
                    </div>
                    <div className="question-paper-text col-md-4" style={{ paddingRight: 0 }}>
                        <h5>Generate Question Paper</h5>
                        <div className="feature-description">
                            <p>Generate question paper for the examination in easy and simple way. Demand multiple SET for one question paper. Include questions from the question bank and various types of questions.</p>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Various types of questions i.e., objective, one word and descriptive to design question paper
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Download or print question paper
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Get the quality of the question paper report
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="performance">
                    <div className="performance-text col-md-4" style={{ paddingLeft: 0 }}>
                        <h5>Student Performance</h5>
                        <div className="feature-description">
                            <p>Maintain track and record of questionwise analysis of every student. Quick view of student performance. If required display performance to students. Export the complete report and print if required. Get Outcome Attainment reports quickly after marks are updated.</p>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Analyses each and every student performance
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Outcome attainment report of every student as well as for complete class
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Export performance report
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="performance-image col-md-8" style={{ paddingRight: 0 }}>
                        <img src={`${performance}`} className="performance-img" />
                    </div>
                </div>
                <div className="result">
                    <div className="result-image col-md-8" style={{ paddingLeft: 0 }}>
                        <img src={`${result}`} className="result-img" />
                    </div>
                    <div className="resultData-text col-md-4" style={{ paddingRight: 0 }}>
                        <h5>Result</h5>
                        <div className="feature-description">
                            <p>Generate the result of examination of all subjects within clicks. Combine the subjects as well as regular and backlog students in the reports.</p>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Fully secured and confidential
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Store students for as long as you need for many years
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Locking system for completed examination
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="benefits-container2" id="benefits">
                    <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                        <h3>Benefits</h3>
                        {/* <div className="benefits-text">
                            <p>The supreme benefit of Library Management System is to find the educational material at one place and any time with well segragate form.</p>
                        </div> */}
                    </div>
                    {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                        <img src={`${benefitsLink}`} className="benefitLink-img" />
                    </div> */}
                </div>
                <div className="quickBenefits4">
                    <div className="quickBenefits4-header">
                        <img src={`${quickBenefits}`} className="quickBenefits-img" />
                        <h3>Quick Benefits</h3>
                    </div>
                    <div className="quickBenefits-list">
                        <div className="depthAnalysis">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${depthAnalysis}`} className="depthAnalysis-img" />
                            </div>
                            <h5>In-depth Analysis</h5>
                            <p>Enhance the facility of analyzing the result with complete consideration of each and every factor with in-depth knowledge which gives accurate and precise result.</p>
                        </div>
                        <div className="studentPerformance">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${studentPerformance}`} className="studentPerformance-img" />
                            </div>
                            <h5>Student Performance</h5>
                            <p>Helps to evaluate student performance in all aspect  with respect to it’s intellectual skills, understanding skill, learning skill, communication skill etc. which aids in study the overall performance of student in all trends.</p>
                        </div>
                        <div className="difficultyAnalysis">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${difficultyAnalysis}`} className="difficultyAnalysis-img" />
                            </div>
                            <h5>Difficulty Analysis</h5>
                            <p>Easy to analyze the difficulty level of course with respect to its  unit,  also can edit the difficulty level for each  and every unit.</p>
                        </div>
                    </div>
                </div>
                <div className="longTerm-benefits2">
                <div className="longTerm-benefits2-header">
                  <img src={`${longTerm}`} className="longTerm-benefits-img" /> 
                    <h3>Long Term Benefits</h3>
                </div>
                <div className='longTerm-benefits-list'>
                    <div className="accessEmployee">
                        <div className="accessEmployee-image">
                          {/**  <img src={`${accessEmployee}`} className="accessEmployee-img" />*/}
                        </div>
                        <div className="accessEmployee-text">
                            <h5>Easy access to the examinations reports for the last five years</h5>
                            <p>Get one click access for all types of examinations, assessment reports for the last five academic years. Streamline the documentation for NAAC and NBA Accreditation with the required standards.</p>
                        </div>
                    </div>
                    <div className="accessEmployee">
                    <div className="accessEmployee-image">
                      {/**  <img src={`${accessEmployee}`} className="accessEmployee-img" />*/}
                    </div>
                    <div className="accessEmployee-text">
                        <h5>Track student learning levels progression over the years</h5>
                        <p>Track the learning levels of the students along with identification of advanced learners and slow learners.</p>
                    </div>
                </div>
                <div className="accessEmployee">
                    <div className="accessEmployee-image">
                    {/**  <img src={`${accessEmployee}`} className="accessEmployee-img" />*/}
                    </div>
                    <div className="accessEmployee-text">
                        <h5>Readiness for Outcome Attainment and OBE Parameters</h5>
                        <p>Get yourself ready with not only the result analysis but to achieve the ultimate targets of Course Outcome and Program Outcome Mapping. Innovative tools help the Management, Principal, and HODs to generate reports, graphs, and charts, with ease and in the fewest of the clicks.</p>
                    </div>
                </div>
                </div>
            </div>
                {/* <div className="longTerm-benefits3">
                    <div className="longTerm-benefits3-header">
                        <img src={`${longTerm}`} className="longTerm-benefits-img" />
                        <h3>Long Term Benefits</h3>
                    </div>
                    <div className='longTerm-benefits-list'>
                        <div className="value-efficient">
                            <div className="valueEfficient-image">
                                <img src={`${accessRecords}`} className="valueEfficient-img" />
                            </div>
                            <div className="valueEfficient-text">
                                <h5>Access Records</h5>
                                <p>Helps user to find and identify the previous batch records of training and placement of students very easily and quickly.</p>
                            </div>
                        </div>
                        <div className="secure">
                            <div className="secure-image">
                                <img src={`${clarifiedProcess}`} className="secure-img" />
                            </div>
                            <div className="secure-text">
                                <h5>Clarified and Modernize Process</h5>
                                <p>Observe strategic growth in the placements due to clarity on every minute work performed for training and placement activities.</p>
                            </div>
                        </div>
                        <div className="secure">
                            <div className="secure-image">
                                <img src={`${globalConnection}`} className="secure-img" />
                            </div>
                            <div className="secure-text">
                                <h5>Connected Globally</h5>
                                <p>Get job applicants from other institutes using public sharable links for placement registration.</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" >
                            <div className="d-flex justify-content-center">
                            <img className="text-center" src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="report-container">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Academic Yearwise Student Placement List</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Individual Student Training Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Individual Training Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Training Calendar</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Semester / Groupwise Student Training Summary Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Recruiters Report</p>
                        </div>
                    </div>
                </div> */}
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="report-container" id="report">
                <div className="report-header">
                    <h3>Reports</h3>
                </div>
                <div className="report-list">
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Question wise Analysis</p>
                    </div>
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Question wise CO Attainment</p>
                    </div>
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Student wise CO Attainment</p>
                    </div>
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Total Course Outcome Attainment</p>
                    </div>
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Quality of question paper based on-Linked CO’s</p>
                    </div>
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Quality of question paper based on-Linked Bloom’s Levels</p>
                    </div> 
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Quality of question paper based on-Involved PI’s</p>
                    </div> 
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Customised reports for Average of multiple examinations</p>
                    </div>
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Course Outcome Attainment for Internal (CIE) and University Examinations (SEE)</p>
                    </div>
                </div>
            </div>

                <div className="support-container1" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                       
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                            <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ResultAnalysis;