import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import privacy from 'WEBSITE/Components/PrivacyPolicy/images/privacy.png';
import storeData from 'WEBSITE/Components/PrivacyPolicy/images/storeData.png';
import thirdParty from 'WEBSITE/Components/PrivacyPolicy/images/thirdParty.png';
import dataRetention from 'WEBSITE/Components/PrivacyPolicy/images/dataRetention.png';
import disclosure from 'WEBSITE/Components/PrivacyPolicy/images/disclosure.png';
import changes from 'WEBSITE/Components/PrivacyPolicy/images/changes.png';
import questions from 'WEBSITE/Components/PrivacyPolicy/images/questions.png';
import 'WEBSITE/Components/PrivacyPolicy/privacypolicy.css';

class PrivacyPolicy extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="privacy-header">
                    <h5>Privacy Policy</h5>
                </div>
                <div className="privacy-info">
                    <h1>“Your data is Safe and Secure”</h1>
                </div>
                <div className="privacy-text">
                    <h5>vmedulife Portal Privacy Policy</h5>
                </div>
                <div className="privacy-policyList">
                    <div className="privacyList col-md-6" style={{ paddingLeft: 0 }}>
                        <h4>How vmedulife Uses the Information We Collect</h4>
                        <div className="policyList">
                            <div className="policy">
                                <FontAwesomeIcon icon={['fas','circle']} size="sm" style={{ marginRight: 10 }} />
                                <h6>We collect your personal information and aggregate information about the use of our Website and Services to better understand your needs and to provide you with a better Website experience. Specifically, we may use your personal information for any of the following reasons:</h6>
                            </div>
                            <div className="policy">
                                <FontAwesomeIcon icon={['fas','circle']} size="sm" style={{ marginRight: 10 }} />
                                <h6>To provide our Services to you, including registering you for our Services, verifying your identity and authority to use our Services, and to otherwise enable you to use our Website and our Services;</h6>
                            </div>
                            <div className="policy">
                                <FontAwesomeIcon icon={['fas','circle']} size="sm" style={{ marginRight: 10 }} />
                                <h6>For customer support and to respond to your inquiries;</h6>
                            </div>
                            <div className="policy">
                                <FontAwesomeIcon icon={['fas','circle']} size="sm" style={{ marginRight: 10 }} />
                                <h6>For internal record-keeping purposes;</h6>
                            </div>
                            <div className="policy">
                                <FontAwesomeIcon icon={['fas','circle']} size="sm" style={{ marginRight: 10 }} />
                                <h6>To process billing and payment, including sharing with third party payment gateways in connection with Website and/or vmedulife's products and Services;</h6>
                            </div>
                            <div className="policy">
                                <FontAwesomeIcon icon={['fas','circle']} size="sm" style={{ marginRight: 10 }} />
                                <h6>To improve and maintain our Website and our Services;</h6>
                            </div>
                            <div className="policy">
                                <FontAwesomeIcon icon={['fas','circle']} size="sm" style={{ marginRight: 10 }} />
                                <h6>To periodically send promotional emails to the email address you provide regarding new products/facilities from vmedulife;</h6>
                            </div>
                            <div className="policy">
                                <FontAwesomeIcon icon={['fas','circle']} size="sm" style={{ marginRight: 10 }} />
                                <h6>To privacy-content you via email, telephone, facsimile or mail, or, where requested, by text message, to deliver certain services or information you have requested;</h6>
                            </div>
                        </div>
                    </div>
                    <div className="privacy-image col-md-6" style={{ paddingRight: 0 }}>
                        <img src={`${privacy}`} className="privacy-img" />
                    </div>
                </div>
                <div className="policyList-policy col-md-10">
                    <div className="policy">
                        <FontAwesomeIcon icon={['fas','circle']} size="sm" style={{ marginRight: 10 }} />
                        <h6>We may use your demographic information (i.e., age, postal code, residential and commercial addresses, and other various data) to more effectively facilitate the promotion of goods and services to appropriate target audiences and for other research and analytical purposes;</h6>
                    </div>
                    <div className="policy">
                        <FontAwesomeIcon icon={['fas','circle']} size="sm" style={{ marginRight: 10 }} />
                        <h6>To resolve disputes, to protect ourselves and other users of our Website and Services, and to enforce our Terms of Use;</h6>
                    </div>
                    <div className="policy">
                        <FontAwesomeIcon icon={['fas','circle']} size="sm" style={{ marginRight: 10 }} />
                        <h6>We also may compare personal information collected through the Website and Services to verify its accuracy with personal information collected from third parties; and</h6>
                    </div>
                    <div className="policy">
                        <FontAwesomeIcon icon={['fas','circle']} size="sm" style={{ marginRight: 10 }} />
                        <h6>We may combine aggregate data with the personal information we collect about you.</h6>
                    </div>
                    <div className="policy">
                        <FontAwesomeIcon icon={['fas','circle']} size="sm" style={{ marginRight: 10 }} />
                        <h6>From time to time, vmedulife may use personal information for new and unanticipated uses not previously disclosed in our Privacy Policy. If our information practices change regarding information previously collected, vmedulife shall make reasonable efforts to provide notice and obtain consent of any such uses as may be required by law.</h6>
                    </div>
                </div>
                <div className="privacy-benefits">
                    <div className="storeData">
                        <div className="storeData-image">
                            <img src={`${storeData}`} className="storeData-img" />
                        </div>
                        <div className="storeData-text">
                            <h5>Data Storage</h5>
                            <p>vmedulife uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run vmedulife. Although vmedulife owns the code, databases, and all rights to the vmedulife application, you retain all rights to your data.</p>
                        </div>
                    </div>
                    <div className="thirdParty">
                        <div className="thirdParty-image">
                            <img src={`${thirdParty}`} className="thirdParty-img" />
                        </div>
                        <div className="thirdParty-text">
                            <h5>Third Parties With Whom We Share Information</h5>
                            <p>We may share your information with third parties who assist with delivery of our products and services. From time to time, we may share your information with the following third parties and to the extent possible, we encourage you to review their privacy policies and terms of use to see how they treat the information we share with them: “Google (Google Privacy Policy)” and “Zoom (Zoom Privacy Policy)”.</p>
                        </div>
                    </div>
                    <div className="dataRetention">
                        <div className="dataRetention-image">
                            <img src={`${dataRetention}`} className="dataRetention-img" />
                        </div>
                        <div className="dataRetention-text">
                            <h5>Data Retention</h5>
                            <p>We will retain your Personal Information for the period necessary to fulfill the purposes outlined in this Privacy Notice unless a longer retention period is required or permitted by law.</p>
                        </div>
                    </div>
                    <div className="disclosure">
                        <div className="disclosure-image">
                            <img src={`${disclosure}`} className="disclosure-img" />
                        </div>
                        <div className="disclosure-text">
                            <h5>Disclosure</h5>
                            <p>vmedulife may disclose personally identifiable information under special circumstances, such as to comply with subpoenas or when your actions violate the <Link to="/terms-of-service"><span style={{ fontWeight: 'bold', textDecoration:'underline', color: '#FFFFFF'  }}>Terms of Service.</span></Link></p>
                        </div>
                    </div>
                    <div className="changes">
                        <div className="changes-image">
                            <img src={`${changes}`} className="changes-img" />
                        </div>
                        <div className="changes-text">
                            <h5>Changes</h5>
                            <p>vmedulife may periodically update this policy. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your vmedulife primary account holder account or by placing a prominent notice on our site.</p>
                        </div>
                    </div>
                    <div className="questions">
                        <div className="questions-image">
                            <img src={`${questions}`} className="questions-img" />
                        </div>
                        <div className="questions-text">
                            <h5>Questions</h5>
                            <p>Any questions about this Privacy Policy should be addressed to <span style={{ fontWeight: 'bold', textDecoration:'underline' }}>info@vmedulife.com</span></p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PrivacyPolicy;