import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import certificateView from 'WEBSITE/Components/Certificate/images/certificateView.png';
import customCertificate from 'WEBSITE/Components/Certificate/images/customCertificate.png';
import certificateRequest from 'WEBSITE/Components/Certificate/images/certificateRequest.png';
import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/LibraryManagement/images/quickBenefits.png';
import predefinedTemplate from 'WEBSITE/Components/Certificate/images/predefinedTemplate.png';
import transparentProcess from 'WEBSITE/Components/Certificate/images/transparentProcess.png';
import approvedWorkflow from 'WEBSITE/Components/Certificate/images/approvedWorkflow.png';
import longTerm from 'WEBSITE/Components/LibraryManagement/images/longTerm.png';
import processOptimization from 'WEBSITE/Components/Certificate/images/processOptimization.png';
import recordOnGo from 'WEBSITE/Components/Certificate/images/recordOnGo.png';
import percentDigital from 'WEBSITE/Components/Certificate/images/percentDigital.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/Certificate/certificate.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Certificate extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Certificates</h1>
                    </div>
                    <div className="main-text">
                        <h4>
                            Now manage the institute certificate in an easy and simple way with our vmedulife certificate portal. Add multiple designs to a certificate and customize it as per your need. Keep track and records of certificates requested by students. A certificate like Bonafide Certificate, Leaving Certificate, and Transcript Certificate will be generated in hassle-free manner.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report" onClick={() => { document.getElementById('report').scrollIntoView(); }}>
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="certificate-view">
                    <div className="certificateView">
                        <img src={`${certificateView}`} className="certificateView-img" />
                    </div>
                </div>
                <div className="features-container2" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                    </div>
                    <div className="custom-certificate">
                        <div className="custom-certificate-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Customize Certificate</h5>
                            <div className="feature-description">
                                <p>Add a certificate and create multiple templates in it. Customize the design of certificate as per your need. Add requirements or information required from student while requesting certificate.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Access based - only authorized person will create certificate
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Add multiple designs to a single certificate
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Fully customize certificate
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="customCertificate-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${customCertificate}`} className="customCertificate-img" />
                        </div>
                    </div>
                    <div className="certificate-request">
                        <div className="certificateRequest-image col-md-8" style={{ paddingLeft: 0 }}>
                            <img src={`${certificateRequest}`} className="certificateRequest-img" />
                        </div>
                        <div className="certificate-request-text col-md-4" style={{ paddingRight: 0 }}>
                            <h5>Certificate Request</h5>
                            <div className="feature-description">
                                <p>Manage the certificate request in a simple way. Approval of certificate request. Students can easily download and print an approved certificate any time. Maintain records of certificate requested as per its status till date.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Access based - only authorized person will handle certificate request
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Make a change in certificate design while approving certificate request
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Print generated certificate option on the authorized person side
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="benefits-container1" id="benefits">
                    <div className="benefits-info">
                        <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                            <h3>Benefits</h3>
                            {/* <div className="benefits-text">
                                <p>Perhaps the most important benefit of inventory is saving organization money. Enhanced the efficiency & productivity in operations. Gives transparency, decreases inaccuracies and avoid redundancy.</p>
                            </div> */}
                        </div>
                        {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                            <img src={`${benefitsLink}`} className="benefitLink-img" />
                        </div> */}
                    </div>
                    <div className="benefits-data">
                        <div className="quickBenefits1">
                            <div className="quickBenefits1-header">
                                <img src={`${quickBenefits}`} className="quickBenefits-img" />
                                <h3>Quick Benefits</h3>
                            </div>
                            <div className="quickBenefits1-list">
                                <div className="predefinedTemplate">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${predefinedTemplate}`} className="predefinedTemplate-img" />
                                    </div>
                                    <h5>Predefined Template</h5>
                                    <p>Ensures that it uses less paper by having predefined template which is easily accessible.</p>
                                </div>
                                <div className="transparent-process">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${transparentProcess}`} className="transparent-process-img" />
                                    </div>
                                    <h5>Transparent Process</h5>
                                    <p>Process which is quick  and simple have nothing to hide and have crystal clear transparency from end to end.</p>
                                </div>
                                <div className="approvedWorkflow">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${approvedWorkflow}`} className="approvedWorkflow-img" />
                                    </div>
                                    <h5>Approval Workflow</h5>
                                    <p>Once certificate request take place from student, it gets approved by respective multiple levels according to the organization.</p>
                                </div>
                            </div>
                        </div>
                        <div className="longTerm-benefits1">
                            <div className="longTerm-benefits1-header">
                                <img src={`${longTerm}`} className="longTerm-benefits-img" />
                                <h3>Long Term Benefits</h3>
                            </div>
                            <div className="longBenefits1-list">
                                <div className="processOptimization">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${processOptimization}`} className="processOptimization-img" />
                                    </div>
                                    <h5>Process Optimization</h5>
                                    <p>Well versed and easy to use process which enables user to issue the certificate within  few clicks at your finger tips.</p>
                                </div>
                                <div className="recordsOnGo">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${recordOnGo}`} className="recordsOnGo-img" />
                                    </div>
                                    <h5>Records on the go</h5>
                                    <p>Access to the past records as well on to the existing record made easy with 24*7 access  to records and on the go.</p>
                                </div>
                                <div className="percentDigital">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${percentDigital}`} className="percentDigital-img" />
                                    </div>
                                    <h5>100% Digitalization</h5>
                                    <p>Enables organization to 100% digitalization using certificate software and also by reducing the usage of paper in huge amount which eventually leads to GO GREEN Movement.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container" id="report">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Issued Certificates Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Datewise Certificate Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Pending Certificates Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Certificate Types Report</p>
                        </div>
                    </div>
                </div>
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                         
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Certificate;