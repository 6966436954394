import React from 'react';
import educationWork from 'WEBSITE/Components/CaseStudy/images/educationWork.png';
import help from 'WEBSITE/Components/CaseStudy/images/help.png';
import 'WEBSITE/Components/CaseStudy/casestudy.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CaseStudy extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="caseStudy-header">
                    <h5>Case Studies</h5>
                </div>
                <div className="educationWork">
                    <div className="educationWork-text col-md-7" style={{ paddingLeft: 0 }}>
                        <h1>How we work for adding values in education and making it simple?</h1>
                    </div>
                    <div className="educationWork-image col-md-5" style={{ paddingRight: 0 }}>
                        <img src={`${educationWork}`} className="educationWork-img" />
                    </div>
                </div>
                <div className="help">
                    <div className="help-image col-md-5" style={{ paddingLeft: 0 }}>
                        <img src={`${help}`} className="help-img" />
                    </div>
                    <div className="help-text col-md-7" style={{ paddingRight: 0 }}>
                        <h1>How vmedulife platform will enhance the overall quality of the institute?</h1>
                    </div>
                </div>
                <div className="caseStudy-Info">
                    <h5>What do you understand by case study ? Is it client case study or individual person study ? Well, we have covered all your queries and curiosity which will clear your thoughts.</h5>
                </div>
                <div className="caseStudy-details">
                    <h5>“vmedulife” offers you the best in concept feature of its module used by many and have successfully crossed their ways all above up with energetic and instant support.</h5>
                    <h5>&gt; Outcome Based Education Software (Accreditation)</h5>
                    <h5>&gt; Academic Management Software</h5>
                    <h5>&gt; Office Automation (ERP)</h5>
                 
                </div>
                <div className="enquiry-message">
                    <h5>For information contact us by filling form</h5>
                </div>
                <div className="caseStudy-enquiry-container">
                    <div className="caseStudy-enquiry-form">
                        <iframe height="700px" className="sales-enquiry-form" src='https://crm.zoho.com/crm/WebFormServeServlet?rid=20c5ee73b3c6c2352cdc8b4cb384297b2e952982d6cb96147cccbfef7c7f441agid412e4dcdb9aa879f2c10c0102d6c6fbf9e6d8b57fbd544c7342c0bbd73d8a809'></iframe>
                    </div>
                </div>
                <div className="teamMessage">
                    <h5>“vmedulife takes the charge in delivering tailor-picked products for your ease of operation”</h5>
                </div>
            </>
        );
    }
}

export default CaseStudy;