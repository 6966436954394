import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import mentoringView from 'WEBSITE/Components/Mentoring/images/mentoringView.png';
import myMentees from 'WEBSITE/Components/Mentoring/images/myMentees.png';
import meetings from 'WEBSITE/Components/Mentoring/images/meetings.png';
import menteesDiscussion from 'WEBSITE/Components/Mentoring/images/menteesDiscussion.png';
import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/ResultAnalysis/images/quickBenefits.png';
import practicalAdvice from 'WEBSITE/Components/Mentoring/images/practicalAdvice.png';
import developCommunication from 'WEBSITE/Components/Mentoring/images/developCommunication.png';
import strategyDevelopment from 'WEBSITE/Components/Mentoring/images/strategyDevelopment.png';
// import longTerm from 'WEBSITE/Components/ResultAnalysis/images/longTerm.png';
// import paperless from 'WEBSITE/Components/Mentoring/images/paperless.png';
// import transparent from 'WEBSITE/Components/Mentoring/images/transparent.png';
// import fourAccess from 'WEBSITE/Components/Mentoring/images/24Access.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/Mentoring/mentoring.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Mentoring extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Mentoring</h1>
                    </div>
                    <div className="main-text">
                        <h4>
                            Guide and Train your student with our vmedulife mentoring portal. Maintain customize mentoring rule and guideline for different department. Keep track and record of mentors, their mentee and mentoring session conducted till date.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report" onClick={() => { document.getElementById('report').scrollIntoView(); }}>
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="mentoring-view">
                    <div className="mentoringView">
                        <img src={`${mentoringView}`} className="mentoringView-img" />
                    </div>
                </div>
                <div className="features-container2" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                    </div>
                    <div className="my-mentees">
                        <div className="my-mentees-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>My Mentees</h5>
                            <div className="feature-description">
                                <p>Mentees details and their meetings or session can be handle in a hassle free process where a summarized view is seen also mentees can be transfer from one mentor to another.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Easy and simple management of mentees details
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Transfer of mentees
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="myMentees-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${myMentees}`} className="myMentees-img" />
                        </div>
                    </div>
                    <div className="meetings">
                        <div className="meetings-image col-md-8" style={{ paddingLeft: 0 }}>
                            <img src={`${meetings}`} className="meetings-img" />
                        </div>
                        <div className="meetings-text col-md-4" style={{ paddingRight: 0 }}>
                            <h5>Meetings</h5>
                            <div className="feature-description">
                                <p>Meetings, its agenda, date of meeting, remark and count of mentees attended can be maintain and keep record of it. These meetings are used to develop communication skill within the mentees where respective mentors arrange such sessions.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Create meeting with agenda and date & time
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Assign meeting to mentees
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Keep record of attendance of mentees
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mentees-discussion">
                        <div className="mentees-discussion-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Mentees Discussion</h5>
                            <div className="feature-description">
                                <p>Meetings or sessions conducted for mentees can be backup by taking feedbacks from mentees regarding the process, also mentor can share their reviews.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Feedback from mentees regarding meeting or session
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Mentors can share their feedback
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menteesDiscussion-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${menteesDiscussion}`} className="menteesDiscussion-img" />
                        </div>
                    </div>
                </div>
                <div className="benefits-container2" id="benefits">
                    <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                        <h3>Benefits</h3>
                        {/* <div className="benefits-text">
                            <p>The supreme benefit of Library Management System is to find the educational material at one place and any time with well segragate form.</p>
                        </div> */}
                    </div>
                    {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                        <img src={`${benefitsLink}`} className="benefitLink-img" />
                    </div> */}
                </div>
                <div className="quickBenefits2">
                    <div className="quickBenefits2-header">
                        <img src={`${quickBenefits}`} className="quickBenefits-img" />
                        <h3>Quick Benefits</h3>
                    </div>
                    <div className="quickBenefits-list">
                        <div className="practicalAdvice">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${practicalAdvice}`} className="practicalAdvice-img" />
                            </div>
                            <h5>Gain Practical Advice</h5>
                            <p>Helps student to get practical knowledge and advice from well versed and experienced faculty which guide students in genuine and organized way.</p>
                        </div>
                        <div className="developCommunication">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${developCommunication}`} className="developCommunication-img" />
                            </div>
                            <h5>Develop Communication</h5>
                            <p>Students get impeccable pedagogy from  high level involvement of faculty to face the upcoming challenges in future, by which students can easily communicate with it.</p>
                        </div>
                        <div className="strategyDevelopment">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${strategyDevelopment}`} className="strategyDevelopment-img" />
                            </div>
                            <h5>Strategy Development</h5>
                            <p>Students get well prepared for their any challenge in future with excellent strategies learned by their experienced faculties.</p>
                        </div>
                    </div>
                </div>
                {/* <div className="longTerm-benefits2">
                    <div className="longTerm-benefits2-header">
                        <img src={`${longTerm}`} className="longTerm-benefits-img" />
                        <h3>Long Term Benefits</h3>
                    </div>
                    <div className='longTerm-benefits-list'>
                        <div className="paperless">
                            <div className="keepTrack-image">
                                <img src={`${paperless}`} className="paperless-img" />
                            </div>
                            <div className="paperless-text">
                                <h5>Paperless Process</h5>
                                <p>Having online feedback software in an organization make sure that use of paper is completely eliminated and support for Greener and Cleaner nation.</p>
                            </div>
                        </div>
                        <div className="transparent">
                            <div className="transparent-image">
                                <img src={`${transparent}`} className="transparent-img" />
                            </div>
                            <div className="transparent-text">
                                <h5>Transparency</h5>
                                <p>Allows organization as well as stakeholders to have transparency with respect to their concerns which enables to work as a team to grow and improvise the process with lean belief.</p>
                            </div>
                        </div>
                        <div className="fourAccess">
                            <div className="fourAccess-image">
                                <img src={`${fourAccess}`} className="fourAccess-img" />
                            </div>
                            <div className="fourAccess-text">
                                <h5>24*7 Access</h5>
                                <p>Employees  of organization and stakeholders have access 24*7 on the go.</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container" id="report">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Mentor Mentee Count Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Mentor Mentee Detail Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Mentoring Meeting Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Individual Mentor Meeting Report</p>
                        </div>
                    </div>
                </div>
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                          
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Mentoring;