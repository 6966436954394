import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import iqacView from 'WEBSITE/Components/IqacReports/images/iqacView.png';
import trackActivity from 'WEBSITE/Components/IqacReports/images/trackActivity.png';
import customReport from 'WEBSITE/Components/IqacReports/images/customReport.png';
import completeAnalysis from 'WEBSITE/Components/IqacReports/images/completeAnalysis.png';
import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/ResultAnalysis/images/quickBenefits.png';
import transparency from 'WEBSITE/Components/IqacReports/images/transparency.png';
import simpleIntegration from 'WEBSITE/Components/IqacReports/images/simpleIntegration.png';
import easyOperation from 'WEBSITE/Components/IqacReports/images/easyOperation.png';
import longTerm from 'WEBSITE/Components/ResultAnalysis/images/longTerm.png';
import modernization from 'WEBSITE/Components/IqacReports/images/modernization.png';
import preparation from 'WEBSITE/Components/IqacReports/images/preparation.png';
import deployment from 'WEBSITE/Components/IqacReports/images/deployment.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import iqacReports from 'WEBSITE/Components/IqacReports/images/iqacReports.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/LibraryManagement/librarymanagement.css';
import 'WEBSITE/Components/InventoryManagement/inventorymanagement.css';
import 'WEBSITE/Components/TrainingPlacement/trainingplacement.css';
import 'WEBSITE/Components/IqacReports/iqacreport.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class IqacReports extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Internal Quality Assurance Cell (IQAC Reports)</h1>
                    </div>
                    <div className="main-text">
                        <h4>
                            As quality enhancement is a continuous process, the IQAC becomes a part of the institution's system and works towards realization of the goals of quality enhancement and sustenance. This IQAC module will play key important role for conscious, consistent and catalytic improvement in the overall performance of institutions.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report" onClick={() => { document.getElementById('report').scrollIntoView(); }}>
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="iqac-view">
                    <div className="iqacView">
                        <img src={`${iqacView}`} className="iqacView-img" />
                    </div>
                </div>
                <div className="features-container1" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                    </div>
                    <div className="track-activity">
                        <div className="track-activity-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Track and analyse all the activities of the institute</h5>
                            <div className="feature-description">
                                <p>From students admission to exit as well as routine activities of all the departments of the institute can be monitored within a few clicks. Keep track of work of accreditation as well as regulatory bodies of the institute.</p>
                                {/* <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Plan the examination for multiple departments
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Conduct examination offline
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Facilitate multiple SET for one subject
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Access based system for scheduling exam
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="trackActivity-image col-md-8" style={{ paddingRight: 0 }}>
                                <div className="d-flex justify-content-center">
                                    <img src={`${trackActivity}`} className="trackActivity-img" />
                                </div>
                        </div>
                    </div>
                </div>
                <div className="custom-report">
                    <div className="customReport-image col-md-8" style={{ paddingLeft: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${customReport}`} className="customReport-img" />
                        </div>
                    </div>
                    <div className="custom-report-text col-md-4" style={{ paddingRight: 0 }}>
                        <h5>Custom Reports</h5>
                        <div className="feature-description">
                            <p>All reports are access based and will be accessible to respective authorities only. Customize the reports fields as per your IQAC standards. Additionally get direct access for the Self Assessment Report (SAR) and Self Study Reports (SSR).</p>
                            {/* <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Various types of questions i.e., objective, one word and descriptive to design question paper
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Download or print question paper
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Get the quality of the question paper report
                                    </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="complete-analysis">
                    <div className="complete-analysis-text col-md-4" style={{ paddingLeft: 0 }}>
                        <h5>360<sup>o</sup> Analysis</h5>
                        <div className="text-justify">
                        IQAC in any institution is a significant administrative body responsible for monitoring of all quality matters. It is the prime responsibility of IQAC to initiate, plan and supervise various activities which are necessary to increase the quality of the education imparted in institutions and colleges. vmedulife has developed the most comprehensive IQAC panel which assures about the smooth functioning of all the curricular, co-curricular and extra curricular activities. It is nothing but the 360<sup>o</sup> view analysis and that too in few clicks.
                        </div>
                        {/* <div className="performance-description">
                            <p>Maintain track and record of question wise analysis of every student. Quick view of student performance. If required display performance to students. Export the complete report and print if required. Get Outcome Attainment reports quickly after marks are updated.</p>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Analyses each and every student performance
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Outcome attainment report of every student as well as for complete class
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Export performance report
                                    </p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="performance-image col-md-8" style={{ paddingRight: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${completeAnalysis}`} className="performance-img" />
                        </div>
                    </div>
                </div>
                <div className="benefits-container2" id="benefits">
                    <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                        <h3>Benefits</h3>
                        {/* <div className="benefits-text">
                            <p>The supreme benefit of Library Management System is to find the educational material at one place and any time with well segragate form.</p>
                        </div> */}
                    </div>
                    {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                        <img src={`${benefitsLink}`} className="benefitLink-img" />
                    </div> */}
                </div>
                <div className="quickBenefits2">
                    <div className="quickBenefits2-header">
                        <img src={`${quickBenefits}`} className="quickBenefits-img" />
                        <h3>Quick Benefits</h3>
                    </div>
                    <div className="quickBenefits-list">
                        <div className="transparencyProcess">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${transparency}`} className="transparencyProcess-img" />
                            </div>
                            <h5>In-depth Analysis</h5>
                            <p>Enhance the facility of analyzing the result with complete consideration of each and every factor with in-depth knowledge which gives accurate and precise result.</p>
                        </div>
                        <div className="simpleIntegration">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${simpleIntegration}`} className="simpleIntegration-img" />
                            </div>
                            <h5>Student Performance</h5>
                            <p>Helps to evaluate student performance in all aspect  with respect to it’s intellectual skills, understanding skill, learning skill, communication skill etc. which aids in study the overall performance of student in all trends.</p>
                        </div>
                        <div className="easyOperation">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${easyOperation}`} className="easyOperation-img" />
                            </div>
                            <h5>Difficulty Analysis</h5>
                            <p>Easy to analyze the difficulty level of course with respect to its  unit,  also can edit the difficulty level for each  and every unit.</p>
                        </div>
                    </div>
                </div>
                <div className="longTerm-benefits2">
                    <div className="longTerm-benefits2-header">
                        <img src={`${longTerm}`} className="longTerm-benefits-img" />
                        <h3>Long Term Benefits</h3>
                    </div>
                    <div className='longTerm-benefits-list'>
                        <div className="modernization">
                            <div className="modernization-image">
                                <img src={`${modernization}`} className="modernization-img" />
                            </div>
                            <div className="modernization-text">
                                <h5>Non Verbal Communications</h5>
                                <p>Implement the process of nonverbal communications and get the instant access to the individual performance.</p>
                            </div>
                        </div>
                        <div className="preparation">
                            <div className="preparation-image">
                                <img src={`${preparation}`} className="preparation-img" />
                            </div>
                            <div className="preparation-text">
                                <h5>Clarified and Modernize Process</h5>
                                <p>Observe strategic growth in the placements due to clarity on every minute work performed for training and placement activities.</p>
                            </div>
                        </div>
                        <div className="deployment">
                            <div className="deployment-image">
                                <img src={`${deployment}`} className="deployment-img" />
                            </div>
                            <div className="deployment-text">
                                <h5>Connected Globally</h5>
                                <p>Get job applicants from other institutes using public sharable links for placement registration.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container" id='report'>
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-image">
                        <div className="d-flex justify-content-center">
                        <img src={`${iqacReports}`} className="report-img" />
                        </div>
                    </div>

                    <div className="report-list">
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Curricular Aspects</p>
                    </div>
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Teaching-Learning and Evaluation</p>
                    </div>
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Research, Innovation and Extension</p>
                    </div>
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Infrastructure and Learning Resources</p>
                    </div>
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Student Support and Progression</p>
                    </div>
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Governance, Leadership and Management</p>
                    </div> 
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Institution Values and Best Practices</p>
                    </div> 
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Vision, Mission and Program Educational Objectives</p>
                    </div>
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Program Curriculum and Teaching - Learning Processes</p>
                    </div>
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Course Outcomes and Program Outcomes</p>
                    </div>
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Student's Performance</p>
                    </div>
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Faculty Information and Contributions</p>
                    </div>
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Facilities and Technical Support</p>
                    </div>  
                    <div className="reportName col-md-4" style={{ padding: 0 }}>
                        <img src={`${reportLine}`} className="reportLine-img" />
                        <p>Continuous Improvement</p>
                    </div>                     
                </div>
                </div>
          
               
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                           
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default IqacReports;