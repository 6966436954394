import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import accountingView from 'WEBSITE/Components/Accounting/images/accountingView.png';
import manageVendor from 'WEBSITE/Components/Accounting/images/manageVendor.png';
import managePR from 'WEBSITE/Components/Accounting/images/managePR.png';
import managePO from 'WEBSITE/Components/Accounting/images/managePO.png';
import expense from 'WEBSITE/Components/Accounting/images/expense.png';
import makePayment from 'WEBSITE/Components/Accounting/images/makePayment.png';
import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/LibraryManagement/images/quickBenefits.png';
import falseReport from 'WEBSITE/Components/Accounting/images/falseReport.png';
import dataManipulation from 'WEBSITE/Components/Accounting/images/dataManipulation.png';
import individualAudit from 'WEBSITE/Components/Accounting/images/individualAudit.png';
import longTerm from 'WEBSITE/Components/LibraryManagement/images/longTerm.png';
import depthAnalysis from 'WEBSITE/Components/Accounting/images/depthAnalysis.png';
import automateUpdate from 'WEBSITE/Components/Accounting/images/automateUpdate.png';
import dataLoss from 'WEBSITE/Components/Accounting/images/dataLoss.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/Accounting/accounting.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Accounting extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Accounting</h1>
                    </div>
                    <div className="main-text">
                        <h4>
                        Industry-leading and prominent management of accounting is done by its dominant features of billing, invoicing, and reporting; where daily general expenses can be sync balance your sheet to make the taxation and audit process seamless.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report" onClick={() => { document.getElementById('report').scrollIntoView(); }}>
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="accounting-view">
                    <div className="accountingView">
                        <img src={`${accountingView}`} className="accountingView-img" />
                    </div>
                </div>
                <div className="features-container2" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                    </div>
                    <div className="manage-vendor">
                        <div className="manage-vendor-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Vendor Management</h5>
                            <div className="feature-description">
                                <p>A dominant tool where each and every microscopic detail of vendor / supplier / distributor is maintained and track its history of purchase requests, purchase orders and payments on daily basis.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Vendor details are stored and maintain
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            GST details of vendor can be added
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Registered vendor in an organization as GST vendor or Non GST vendor
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="manageVendor-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${manageVendor}`} className="manageVendor-img" />
                        </div>
                    </div>
                    <div className="manage-pr">
                        <div className="managePR-image col-md-8" style={{ paddingLeft: 0 }}>
                            <img src={`${managePR}`} className="managePR-img" />
                        </div>
                        <div className="manage-pr-text col-md-4" style={{ paddingRight: 0 }}>
                            <h5>Manage Purchase Request</h5>
                            <div className="feature-description">
                                <p>Purchase Request can be manage effortlessly and swiftly within few clicks of its generation, where one can share the purchase request to multiple respective vendor instantly and ask them for quotation to compare.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Generate Purchase Request at your finger tips
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Share Purchase Request to multiple respective vendors
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Ask for quotation and do quickly comparison of quotation briskly
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="manage-po">
                        <div className="manage-po-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Purchase Order</h5>
                            <div className="feature-description">
                                <p>Once purchase Request is finalized it can be directly converted into Purchase Order with specific identification number where all required details are found in a glance. Also it has an important feature of assigning the approver of purchase order for safe and secure transaction of payments and assets.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Convert Purchase Request into Purchase Order
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Track Purchase Order details and its payment
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Assign Multiple / Single approver
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="managePO-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${managePO}`} className="managePO-img" />
                        </div>
                    </div>
                    <div className="manage-expense">
                        <div className="manageExpense-image col-md-8" style={{ paddingLeft: 0 }}>
                            <img src={`${expense}`} className="manageExpense-img" />
                        </div>
                        <div className="manage-expense-text col-md-4" style={{ paddingRight: 0 }}>
                            <h5>Manage Expense</h5>
                            <div className="feature-description">
                                <p>This feature comes with best in class benefit of managing daily general expenses as well as regular expenses in a click. It also enables to add bill & invoice of respective individual vendor and regularate its payment date, payment mode and payment account very easily and in a hassle free process.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Can add daily general expenses
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Quick view of expenses and its payment history
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Add bill or invoice against respective individual vendor
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="make-payment">
                        <div className="make-payment-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Make Instant Payment</h5>
                            <div className="feature-description">
                                <p>One can make payment against any invoice, bill or purchase order effortlessly and swiftly either in installments or direct one time payment.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Make instant payment 
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Maintain records of payments
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="makePayment-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${makePayment}`} className="makePayment-img" />
                        </div>
                    </div>
                </div>
                <div className="benefits-container" id="benefits">
                    <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                        <h3>Benefits</h3>
                        {/* <div className="benefits-text">
                            <p>The supreme benefit of Library Management System is to find the educational material at one place and any time with well segragate form.</p>
                        </div> */}
                    </div>
                    {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                        <img src={`${benefitsLink}`} className="benefitLink-img" />
                    </div> */}
                </div>
                <div className="quickBenefits">
                    <div className="quickBenefits-header">
                        <img src={`${quickBenefits}`} className="quickBenefits-img" />
                        <h3>Quick Benefits</h3>
                    </div>
                    <div className="quickBenefits-list">
                        <div className="falseReport">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${falseReport}`} className="falseReport-img" />
                            </div>
                            <h5>Avoid False Reports</h5>
                            <p>Helps to avoid errors and mishaps in the accounting and makes it a streamline process which gives  accurate reports on the go.</p>
                        </div>
                        <div className="dataManipulation">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${dataManipulation}`} className="dataManipulation-img" />
                            </div>
                            <h5>Avoid Data Manipulation</h5>
                            <p>Strictly disables the data manipulation in the work which eventually leads to clean and transparency in the work.</p>
                        </div>
                        <div className="individualAudit">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${individualAudit}`} className="individualAudit-img" />
                            </div>
                            <h5>Individual Audit</h5>
                            <p>Allows to carry out the audit process effortlessly and smoothly without any intervention of third party and personnel on the go.</p>
                        </div>
                    </div>
                </div>
                <div className="longTerm-benefits">
                    <div className="longTerm-benefits-header">
                        <img src={`${longTerm}`} className="longTerm-benefits-img" />
                        <h3>Long Term Benefits</h3>
                    </div>
                    <div className='longTerm-benefits-list'>
                        <div className="depth-analysis">
                            <div className="depth-analysis-image">
                                <img src={`${depthAnalysis}`} className="depth-analysis-img" />
                            </div>
                            <div className="depth-analysis-text">
                                <h5>In-depth Analysis</h5>
                                <p>Ensures to have a close look on each and every documents and data with precision  and accuracy in the process which enables to analyze in-depth.</p>
                            </div>
                        </div>
                        <div className="automateUpdate">
                            <div className="automateUpdate-image">
                                <img src={`${automateUpdate}`} className="automateUpdate-img" />
                            </div>
                            <div className="automateUpdate-text">
                                <h5>Auto Upgradation</h5>
                                <p>Provide the best in class feature of auto upgradtion of laws and taxes quickly to make a hassle free operation  and to perform the activities without disturbing.</p>
                            </div>
                        </div>
                        <div className="dataLoss">
                            <div className="dataLoss-image">
                                <img src={`${dataLoss}`} className="dataLoss-img" />
                            </div>
                            <div className="dataLoss-text">
                                <h5>Prevents Loss of Data</h5>
                                <p>Even if it’s a natural calamity or financial crisis your  data is safe and secure in highly secure way.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                        <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container" id="report">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Daily Expense Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Monthly Expense Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Purchase Order Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Purchase Request Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Vendors Report</p>
                        </div>
                    </div>
                </div>
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                                <p>We have dedicated team for your support and query which will help you to achieve your goal with the help of inbuilt support portal, from where you or your co-members of your organization can raise the respective issues. Admin from organization can also check its logs of support and query for maintaining the track and records.</p>
                            </div>
                        
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Accounting;