import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import ILMS from 'WEBSITE/Components/LibraryManagement/images/ILMS.png';
import libraryCard from 'WEBSITE/Components/LibraryManagement/images/librarycard.png';
import checked from 'WEBSITE/Components/LibraryManagement/images/checked.png';
import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import fineChecked from 'WEBSITE/Components/LibraryManagement/images/fineChecked.png';
import featureImage from 'WEBSITE/Components/LibraryManagement/images/featureImage.png';
import libraryResource from 'WEBSITE/Components/LibraryManagement/images/libraryResource.png';
import quickGlance from 'WEBSITE/Components/LibraryManagement/images/quickGlance.png';
import resourceTag from 'WEBSITE/Components/LibraryManagement/images/resourceTag.png';
import resourceTagging from 'WEBSITE/Components/LibraryManagement/images/resourceTagging.png';
import search from 'WEBSITE/Components/LibraryManagement/images/search.png';
import arrow from 'WEBSITE/Components/LibraryManagement/images/arrow.png';
import fineManagement from 'WEBSITE/Components/LibraryManagement/images/fineManagement.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/LibraryManagement/images/quickBenefits.png';
import stateArt from 'WEBSITE/Components/LibraryManagement/images/stateArt.png';
import personalized from 'WEBSITE/Components/LibraryManagement/images/personalized.png';
import documentation from 'WEBSITE/Components/LibraryManagement/images/documentation.png';
import longTerm from 'WEBSITE/Components/LibraryManagement/images/longTerm.png';
import valueEfficient from 'WEBSITE/Components/LibraryManagement/images/valueEfficient.png';
import secure from 'WEBSITE/Components/LibraryManagement/images/secure.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/LibraryManagement/librarymanagement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class LibraryManagement extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Integrated Library Management System</h1>
                    </div>
                    <div className="main-text">
                        <h4>
                        Integrated Library Management System also known as ILMS, helps to convert your library into a computerized library. ILMS easily handles catalog books, tracking and maintaining records of books issued, returned and overdue by users. The books can also be stored category-wise which enhances to find the book easily and quickly by the user as well as by the librarian.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report" onClick={() => { document.getElementById('report').scrollIntoView(); }}>
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="ILMS-libraryCard">
                    <div className="ILMS col-md-8">
                        <div className="d-flex justify-content-center">
                        <img src={`${ILMS}`} className="ILMS-img" />
                        </div>
                    </div>
                    <div className="libraryCard col-md-4">
                        <div className="d-flex justify-content-center">
                        <img src={`${libraryCard}`} className="libraryCard-img" />
                        </div>
                    </div>
                </div>
                <div className="features-container" id="features">
                    <div className="features-list col-md-6">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                        <div className="feature">
                            <div className="checked-image">
                                <img src={`${checked}`} className="checked-img" />
                            </div>
                            <div className="feature-text">
                                <p className='feature-text-primary'>
                                    Individual Login for Learner and Instructor
                                </p>
                                <p className='feature-text-secondary'>
                                    Enables to login separetely for issuing the books which results in hassle free operation
                                </p>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="checked-image">
                                <img src={`${checked}`} className="checked-img" />
                            </div>
                            <div className="feature-text">
                                <p className='feature-text-primary'>
                                    Management of Books
                                </p>
                                <p className='feature-text-secondary'>
                                    Manage process of book Issue, Renew and Return in the easiest and simplest way at your finger tips
                                </p>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="checked-image">
                                <img src={`${checked}`} className="checked-img" />
                            </div>
                            <div className="feature-text">
                                <p className='feature-text-primary'>
                                    Management of Library Card
                                </p>
                                <p className='feature-text-secondary'>
                                    Easy accessible and automatic generation of Library card for learner and instructor
                                </p>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="checked-image">
                                <img src={`${checked}`} className="checked-img" />
                            </div>
                            <div className="feature-text">
                                <p className='feature-text-primary'>
                                    e-Resource facility
                                </p>
                                <p className='feature-text-secondary'>
                                    Simplest and quickest way to access library on the go
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="features-image col-md-6">
                        <img src={`${featureImage}`} className="feature-img" />
                    </div>
                </div>
                <div className="library-resources">
                    <div className="libraryResource-image col-md-8">
                        <img src={`${libraryResource}`} className="libraryResource-img" />
                    </div>
                    <div className="library-resource-text col-md-4">
                        <h5>Library Resource</h5>
                        <div className="feature-description">
                            <p>One can easily add, edit and delete the resource in hassle free process, also the important feature is to generate barcode for every single copy of books for maintaining and tracking the records. This enables to less paper work and eventually leads to ‘GO GREEN” movement.</p>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Import the existing data into the system
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Verify the stock weekly, monthly, quarterly, half yearly and yearly
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${resourceChecked}`} className="resource-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Maintaining records of donated books
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="quick-glance">
                    <div className="quickGlance-header">
                        <h3>Quick Glance</h3>
                    </div>
                    <div className="quickGlance-image">
                        <div className="d-flex justify-content-center">
                        <img src={`${quickGlance}`} className="quickGlance-img" />
                        </div>
                    </div>
                </div>
                <div className="resource-tagging">
                    <div className="resource-tag col-md-6">
                        <div className="resourceTag-header">
                            <img src={`${resourceTag}`} className="resourceTag-img" />
                            <h3>Resource Tagging</h3>
                        </div>
                        <div className="resource-tag-text">
                            <p>Make your search easy, by “Tagging” the resource.</p>
                        </div>
                    </div>
                    <div className="resource-tag-image col-md-6">
                        <img src={`${resourceTagging}`} className="resource-tagging-img" />
                    </div>
                </div>
                <div className="quick-search">
                    <div className="quickSearch-header">
                        <h3>Quick Search</h3>
                    </div>
                    <div className="quick-search-text">
                        <div className="quickSearch-image col-md-6" style={{ paddingLeft: 0 }}>
                            <div className="quickSearch-container">
                                <img src={`${search}`} className="quickSearch-img" />
                            </div>
                        </div>
                        <div className="quickSearch-list col-md-6" style={{ paddingRight: 0 }}>
                            <p className="quickSearch-list-header">Search your resource in number of ways, </p>
                            <p className="quickSearch-list-item">
                                <img src={`${arrow}`} className="arrow-img" />
                                Title
                            </p>
                            <p className="quickSearch-list-item">
                                <img src={`${arrow}`} className="arrow-img" />
                                Author
                            </p>
                            <p className="quickSearch-list-item">
                                <img src={`${arrow}`} className="arrow-img" />
                                ISBN Number
                            </p>
                            <p className="quickSearch-list-item">
                                <img src={`${arrow}`} className="arrow-img" />
                                Tag
                            </p>
                            <p className="quickSearch-list-item">
                                <img src={`${arrow}`} className="arrow-img" />
                                Resource Medium
                            </p>
                            <p className="quickSearch-list-item">
                                <img src={`${arrow}`} className="arrow-img" />
                                Department
                            </p>
                            <p className="quickSearch-list-item">
                                <img src={`${arrow}`} className="arrow-img" />
                                Resource Category
                            </p>
                            <p className="quickSearch-list-item">
                                <img src={`${arrow}`} className="arrow-img" />
                                Location
                            </p>
                        </div>
                    </div>
                </div>
                <div className="fine-management">
                    <div className="fine-management-text col-md-4">
                        <h5>Fine Management</h5>
                        <div className="feature-description">
                            <p>Effortless management of fine and its collection  from learner’s as well as instructor’s. This collected fine can also be linked with fees in case of learner’s and to payroll in case of instructor’s.</p>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${fineChecked}`} className="fine-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Issue fine policy separetely to learner’s and instructor’s
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${fineChecked}`} className="fine-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Linking of fine to Fees module and Payroll Module
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${fineChecked}`} className="fine-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Transparent transaction of fine management
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fineManagement-image col-md-8">
                        <img src={`${fineManagement}`} className="fineManagement-img" />
                    </div>
                </div>
                <div className="benefits-container" id="benefits">
                    <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                        <h3>Benefits</h3>
                        <div className="benefits-text">
                            <p>The supreme benefit of Library Management System is to find the educational material at one place and any time with well segragate form.</p>
                        </div>
                    </div>
                    {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                        <img src={`${benefitsLink}`} className="benefitLink-img" />
                    </div> */}
                </div>
                <div className="quickBenefits">
                    <div className="quickBenefits-header">
                        <img src={`${quickBenefits}`} className="quickBenefits-img" />
                        <h3>Quick Benefits</h3>
                    </div>
                    <div className="quickBenefits-list">
                        <div className="stateArt">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${stateArt}`} className="stateArt-img" />
                            </div>
                            <h5>State of the art</h5>
                            <p>State of the art management of resources, periodicals and magazines or other material available in the library with minimum efforts and in a click</p>
                        </div>
                        <div className="personalized">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${personalized}`} className="personalized-img" />
                            </div>
                            <h5>Personalized Library</h5>
                            <p>Integrated Library Management System is completely personalized and adaptable according to the need of user for providing best in class facility</p>
                        </div>
                        <div className="documentation">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${documentation}`} className="documentation-img" />
                            </div>
                            <h5>Documentation</h5>
                            <p>State of the art management of resources, periodicals and magazines or other material available in the library with minimum efforts and in a click</p>
                        </div>
                    </div>
                </div>
                <div className="longTerm-benefits">
                    <div className="longTerm-benefits-header">
                        <img src={`${longTerm}`} className="longTerm-benefits-img" />
                        <h3>Long Term Benefits</h3>
                    </div>
                    <div className='longTerm-benefits-list'>
                        <div className="value-efficient">
                            <div className="valueEfficient-image">
                                <img src={`${valueEfficient}`} className="valueEfficient-img" />
                            </div>
                            <div className="valueEfficient-text">
                                <h5>Value Efficient</h5>
                                <p>Enhancing the use of Integrated Library Management System leads to low cost-effective to the organization by eliminating the use of paper and also  helps to the “GO GREEN” movement.</p>
                            </div>
                        </div>
                        <div className="secure">
                            <div className="secure-image">
                                <img src={`${secure}`} className="secure-img" />
                            </div>
                            <div className="secure-text">
                                <h5>Safe and Secure</h5>
                                <p>As Integrated Library Management System is cloud based, the data of users and resources record is maintained under high security with optimum safety measures.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container" id='report'>
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Resource List As Per Accession Series Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Issued Resource Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Returned Resource Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Yearly Purchase Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>List of Missing Resources</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>List of Discarded Resources</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Categorywise Yearly Purchase With Cost Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>List of Donated Resources</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Userwise Issued Return Resources</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Books In and Out Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Resource Requisition Report</p>
                        </div>
                    </div>
                </div>
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container" id="support">
                    <div className="support-data col-md-6" style={{ paddingLeft: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                     
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ paddingRight: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default LibraryManagement;