import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import 'WEBSITE/Components/ServiceTerms/serviceterms.css';

class ServiceTerms extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="service-header">
                    <h5>Terms of Service</h5>
                </div>
                <div className="service-info">
                    <p>This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signature.</p>
                    <p>This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and terms of use for access or usage of the www.vmedulife.com website ("Website") and the services of vmedulife ("vmedulife").</p>
                    <p>The Website is owned and operated by VM EDULIFE Pvt. Ltd. (“Company”) a private company, incorporated under the provisions of the Companies Act, 2013, where such expression shall, unless repugnant to the context thereof, be deemed to include its respective representatives, administrators, employees, directors, officers, agents and their successors and assigns.</p>
                    <p>By accessing the Website, whether through a mobile device, mobile application or computer, the User agrees to be bound by these Terms of Use (“Terms”), whether or not the User creates an account to avail of the Service. If the User wishes to avail of the Service, the User does hereby unequivocally accept and agree to the contents of these Terms</p>
                    <p>The use of the Website by the User is solely governed by these Terms read with the Privacy Policy and any modifications or amendments made thereto by the Company from time to time, at its sole discretion. Accessing the Website and/or using any of the information or Services provided therein shall be deemed to signify the User’s unequivocal acceptance of these Terms and the aforementioned Policy, and the User expressly agrees to be bound by the same, regardless of however the User or anyone on the User’s behalf has accessed, installed, downloaded or used the Website.</p>
                    <p>The User expressly agrees and acknowledges that the Terms and Policy are co-terminus, and that expiry / termination of either one will lead to the termination of the other. The User may not access the Website or avail of any of the Services provided by the Company if the User does not accept and agree to be bound by the Terms and Policy. In the event that the User or anyone acting on the User’s behalf does not wish to be bound by the Terms and the Policy, the User (or the legal person/entity acting on the User’s behalf) unequivocally agrees to refrain from accessing, using or retaining the Website on any device in any manner whatsoever. The User agrees that anything done or caused to be done by the User or anyone acting on the User’s behalf, whether expressly or impliedly in contravention with the Terms or Policy, will render the User liable for legal and punitive action.</p>
                    <p>vmedulife reserves the right to update and change the Terms of Service from time to time without notice. Any new features that augment or enhance the current Service, including the release of new tools and resources, shall be subject to the Terms of Service. Continued use of the Service after any such changes shall constitute your consent to such changes. You can review the most current version of the Terms of Service at any time at https://www.vmedulife.com/web/terms.</p>
                    <p>Violation of any of the terms below will result in the termination of your account. While vmedulife prohibits such conduct and Content on the Service, you understand and agree that vmedulife cannot be responsible for the Content posted on the Service and you nonetheless may be exposed to such materials. You agree to use the Service at your own risk.</p>
                </div>
                <div className="eligibility">
                    <div className="term-number">
                        <h6>1)</h6>
                    </div>
                    <div className="term-text">
                        <h6>ELIGIBILITY</h6>
                        <p>The User represents and warrants that he/she is competent and eligible to enter into legally binding agreements and that he/she has the requisite authority to bind himself/herself to these Terms, as determined solely by the provisions of the Indian Contract Act, 1872. The User may not use the Website if he/she is not competent to contract under the Indian Contract Act, 1872, or is disqualified from doing so by any other applicable law, rule, regulation or judicial verdict currently in force.</p>
                    </div>
                </div>
                <div className="accountCreation">
                    <div className="term-number">
                        <h6>2)</h6>
                    </div>
                    <div className="term-text">
                        <h6>CREATION OF AN ACCOUNT AND REGISTRATION</h6>
                        <p>In order to use Service, the User must first provide certain details to the Company through the Website, including but not limited to the User’s legal full name, a valid email address, contact number, and any other pertinent information requested in order to complete the signup process to create an account that can subsequently be used to avail of the Service. The User agrees that all information provided to the Company shall be accurate to the best of the User’s knowledge, belief and information, and shall be updated whenever appropriate, in a timely manner. The User hereby authorizes the Company to access and use any and all such information provided, as governed by these Terms and the Policy.</p>
                        <p>The User must be a human. Accounts registered by "bots" or other automated methods are not permitted. Your login may only be used by one(1) person - a single login shared by multiple people shall not be permitted.</p>
                    </div>
                </div>
                <div className="termination">
                    <div className="term-number">
                        <h6>3)</h6>
                    </div>
                    <div className="term-text">
                        <h6>TERM AND TERMINATION</h6>
                        <p>These Terms will remain in full force and effect while the User avails of the Service and/or is in possession of an active account. The User is aware that the Company may terminate or suspend the User’s account at any time, without cause or notice, if the Company believes that the User has breached the Terms or Policy, or for any other reason, in its sole discretion. The User is further aware and expressly agrees that even after the User’s account is terminated for any reason, these Terms and the Policy shall survive such termination, and continue to operate in full force and effect, except for any terms that by their nature expire or are fully satisfied.</p>
                        <p>At the time of termination, It is Your responsibility to ensure that Your account has been properly cancelled. An arbitrary email requesting an account to be cancelled is not considered definitive.</p>
                        <p>All of Your content will be immediately deleted from the Service should you initiate cancellation. This information is irretrievable once deleted.</p>
                        <p>Your cancellation will be effective immediately once initiated. You will not be charged again from that point forth.</p>
                        <p>In the event of non-payment for a continuous period of 45 days, vmedulife reserves the right to discontinue the Services to You and delete all information in your Account.</p>
                    </div>
                </div>
                <div className="nonCommercial">
                    <div className="term-number">
                        <h6>4)</h6>
                    </div>
                    <div className="term-text">
                        <h6>NON – COMMERCIAL USE BY USER</h6>
                        <p>The Service is only for the personal use of the User. The User may not use the Service or any content contained in the Service (including, but not limited to, content of other users, designs, text, graphics, images, video, information, logos, software, audio files and computer code) in connection with any commercial endeavour, including but not limited to advertising or soliciting any user to buy or sell any products or services not offered by the Company or through the Website.</p>
                        <p>The User may not use any information obtained from the Service to contact, advertise to, solicit, or sell any product/service to any other user, either within the Website or otherwise. Organizations, companies, and/or businesses may not use the Website or the Service for any purpose. The Company may investigate and take any available legal action in response to illegal and/or unauthorized uses of the Service, including the collection of usernames and/or email addresses of other users by electronic or other means, for the purpose of sending unsolicited email and unauthorized framing of or linking to the Website or Service.</p>
                    </div>
                </div>
                <div className="userResponsibility">
                    <div className="term-number">
                        <h6>5)</h6>
                    </div>
                    <div className="term-text">
                        <h6>ROLE AND RESPONSIBILITY OF USER</h6>
                        <p>Users agree that a basic prerequisite for use of the Website is easy access to the internet and internet browsing facilities supported by the Website, as well as a working phone number by which the User is accessible. All expenses in relation to the same are to be borne entirely by the Users, and Users further agree that the Company shall not be liable for any shortcomings/deficiencies/interruptions/mistakes/downtime of any nature in the services provided for by the internet/telecom service provider(s).</p>
                        <p>If it is reported or found by us that any User's profile is misleading or inaccurate or any User is not in conformity with these Terms and Privacy Policy, then We reserve the right to suspend, terminate or block such a User at the discretion of the Company.</p>
                        <p>Users are expected to refrain from :</p>
                        <p>Entering into financial transactions with other Users.</p>
                        <p>Dealing with other Users in any manner without the.</p>
                        <p>Entering into any deal/contract with any User outside the Website.</p>
                        <p>Sharing of confidential and personal data with each other but not limited to sharing of bank details, etc.</p>
                        <p>You shall not assign or transfer Your Usership to any other person or entity.</p>
                        <p>Single centre plan must only be used for one(1) centre only, and a single centre used for multiple centres shall not be permitted.</p>
                        <p>We and our partners may contact you over phone / e-mail / SMS and in such technological modes as are prevailing in market, and by submitting the registration, you waive any right to treat such communication as unsolicited commercial communication notwithstanding the fact that your number is registered under the National Do Not Call Registry or any other registry established for similar purposes.</p>
                        <p>In the event of any contravention of these Terms or Policy by any User, the Company reserves our right to initiate appropriate legal action against the concerned User.</p>
                    </div>
                </div>
                <div className="accountSecurity">
                    <div className="term-number">
                        <h6>6)</h6>
                    </div>
                    <div className="term-text">
                        <h6>ACCOUNT SECURITY</h6>
                        <p>The User is responsible for maintaining the confidentiality of his/her username and password, assigned to the User during the registration process, and the User is solely responsible for all activities that occur under the User’s username and password. The User agrees to immediately notify the Company of any disclosure or unauthorized use of the User’s username or password, or any other breach of security with respect to the User’s account, and also ensure that he/she logs out from his/her account at the end of each session.</p>
                    </div>
                </div>
                <div className="interaction">
                    <div className="term-number">
                        <h6>7)</h6>
                    </div>
                    <div className="term-text">
                        <h6>INTERACTIONS WITH OTHER USERS</h6>
                        <p>The User agrees and acknowledges that the User is solely responsible for his/her interactions with other users. The User understands that the Company does not conduct any background checks, medical checks or screenings on any of its Users. The User is further aware that the Company also does not inquire into the backgrounds of any of its users or attempt to verify the statements of its Users. The Company makes no representations or warranties as to the conduct of its Users, or the information / material made available by them to other Users, and the User expressly agrees and acknowledges that the Company will not be liable for the same in any manner whatsoever.</p>
                    </div>
                </div>
                <div className="proprietary">
                    <div className="term-number">
                        <h6>8)</h6>
                    </div>
                    <div className="term-text">
                        <h6>PROPRIETARY RIGHTS</h6>
                        <p>The User is aware that the Company owns and retains all proprietary rights in the Service, and in all content, trademarks, trade names, service marks and other intellectual property rights related thereto. The Service contain the copyrighted material, trademarks, and other proprietary information of the Company, and the User agrees not to copy, modify, transmit, create any derivative works from, make use of, or reproduce in any way any copyrighted material, trademarks, trade names, service marks, or other intellectual property or proprietary information accessible through the Service, without first obtaining the prior written consent of the Company.</p>
                    </div>
                </div>
                <div className="contents">
                    <div className="term-number">
                        <h6>9)</h6>
                    </div>
                    <div className="term-text">
                        <h6>CONTENT POSTED BY THE USER</h6>
                        <p>The User acknowledges and agrees that he/she is solely responsible for the content and information that he/she posts, uploads, publishes, links to, transmits, records, displays or otherwise makes available on the Service or transmits to other users, including text messages, chat, photographs, whether posted on the Website or Website, or privately transmitted to other Users. The User may not Post as part of the Service, or transmit to the Company or to any other user (either on or off the Service), any offensive, inaccurate, incomplete, abusive, obscene, profane, threatening, intimidating, harassing, racially offensive, or illegal material, or any material that infringes or violates another person’s rights (including intellectual property rights, and rights of privacy and publicity). The User represents and warrants that (i) all information that the User submits upon creation of the account is accurate and truthful, and that the User will promptly update any information that subsequently becomes inaccurate, incomplete, misleading or false.</p>
                        <p>The User understands and agrees that the Company may, but is not obligated to, monitor or review any content the User Posts as part of the Service. The Company may delete any content, in whole or in part, that in the sole judgment of the Company violates these Terms or may harm the reputation of the Website, Website, Service or Company.</p>
                        <p>The User may not Post, upload, display or otherwise make available content that:</p>
                        <p>promotes racism, bigotry, hatred or physical harm of any kind against any group or individual; advocates harassment or intimidation of another person;</p>
                        <p>is intended to otherwise defraud, other users of the Service;</p>
                        <p>involves the transmission of “junk mail”, “chain letters,” or unsolicited mass mailing or “spamming” (or “spimming”, “phishing”, “trolling” or similar activities);</p>
                        <p>promotes information that is false or misleading, or promotes illegal activities or conduct that is defamatory, libellous or otherwise objectionable;</p>
                        <p>promotes an illegal or unauthorized copy of another person’s copyrighted work;</p>
                        <p>contains video, audio photographs, or images of another person without his or her permission (or in the case of a minor, the minor’s legal guardian);</p>
                        <p>contains restricted or password only access pages, or hidden pages or images (those not linked to or from another accessible page);</p>
                        <p>provides material that exploits people in a sexual, violent or other illegal manner, or solicits personal information from anyone under the age of 18;</p>
                        <p>provides instructional information about illegal activities such as making or buying illegal weapons or drugs, violating someone’s privacy, or providing, disseminating or creating computer viruses;</p>
                        <p>contains viruses, time bombs, trojan horses, cancelbots, worms or other harmful, or disruptive codes, components or devices;</p>
                        <p>impersonates, or otherwise misrepresents affiliation, connection or association with, any person or entity;</p>
                        <p>provides information or data the User do not have a right to make available under law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information);</p>
                        <p>disrupts the normal flow of dialogue, causes a screen to “scroll” faster than other users are able to type, or otherwise negatively affects other users’ ability to engage in real time exchanges;</p>
                        <p>solicits passwords or personal identifying information for commercial or unlawful purposes from other users or disseminates another person’s personal information without his or her permission; and publicity or promotes commercial activities and/or sales without our prior written consent such as contests, sweepstakes, barter, advertising, and pyramid schemes.</p>
                        <p>The Company reserves the right, in its sole discretion, to investigate and take any legal action against any User who violates this provision, including removing the offending communication from the Service and terminating or suspending the account of such User. The User’s use of the Service, including all content Posted by the User through the Service, must comply with all applicable laws and regulations. The User agrees and acknowledges that the Company may access, preserve and disclose the User account information and content if required to do so by law, or if it in good faith believes that such access, preservation or disclosure is reasonably necessary, to: (i) comply with any legal process; (ii) enforce these Terms; (iii) respond to claims that any content violates the rights of third parties; (iv) respond to the User’s requests for customer service, or allow the User to use the Service in the future; or (v) protect the rights, property or personal safety of the Company or any other person. The User agrees that any content the User Posts on the Service may be viewed by other users, and may be viewed by any person visiting or participating in the Service.</p>
                    </div>
                </div>
                <div className="paymentTaxes">
                    <div className="term-number">
                        <h6>10)</h6>
                    </div>
                    <div className="term-text">
                        <h6>PAYMENT, TAXES, REFUNDS, UPGRADING AND DOWNGRADING TERMS</h6>
                        <p>The Service bills in advance on a yearly basis and is non-refundable. There will be no refunds or credits for partial months of service or upgrade/downgrade refunds. We will not make any exceptions, however extreme the circumstance. Your account subscription fees will be charged in INR (Indian Rupees). All fees are exclusive of taxes. GST of 18% is levied on every purchase.</p>
                        <p>We consider the payment process to be complete only on receipt of the amount to vmedulife's designated bank account. Services will be delivered immediately upon successful payment of fees. In case of cheque payment, the billing will be activated once the cheque is cleared. In case of cheque bounce, INR 500 fine will be charged in addition to the value of the cheque that remains payable. vmedulife reserves the right to modify the fee structure by providing a 10 days prior notice, either by notice on the Website or through email to the Authorized User, which shall be considered as valid and agreed communication. We do not entertain refund and cancellation in any circumstances.</p>
                    </div>
                </div>
                <div className="smsRates">
                    <div className="term-number">
                        <h6>11)</h6>
                    </div>
                    <div className="term-text">
                        <h6>SMS RATES AND DELIVERY TERMS</h6>
                        <p>The User confirms that the mobile phone numbers to which messages will be sent using vmedulife are authentic and belong to people You have personal, professional or business relationships with. You further confirm that you have not included in the list any number of any person(s) who is not known to you.</p>
                        <p>You confirm that you can provide, on request, an auditable acceptance or permission from all such people whom you intend to send messages to using this service, to receive messages from you via SMS.</p>
                        <p>You understand and agree that it is your responsibility to consume the service within validity period offered to you, else the service will lapse after the validity period.</p>
                        <p>You agree that delivery reports of messages sent through the service are dependent on data provided to vmedulife by telecom operators, which in turn is shared by vmedulife with you. If the telecom operator does not provide data on delivery reports to vmedulife, vmedulife will not be able to pass on the same to You.</p>
                        <p>You agree to follow all laws of the land and respect and abide by all directives, guidelines and circulars issued by TRAI, as maybe applicable, from time to time.</p>
                        <p>You understand and agree that telecom operators can increase the price of services at anytime, and such increase being beyond the control of vmedulife, vmedulife can pass on such increase to you, fully or partially, by way of levying additional charges and /or deduction of proportionate credits from your account with vmedulife.</p>
                    </div>
                </div>
                <div className="indemnity">
                    <div className="term-number">
                        <h6>12)</h6>
                    </div>
                    <div className="term-text">
                        <h6>INDEMNITY</h6>
                        <p>Any User's conduct, personal interaction either over the Website or otherwise, and any communication or transactions of any nature are outside the purview of the scope of service of the Company, and each User agrees to indemnify and hold Us harmless in relation to the same. The User shall indemnify vmedulife against all complaints and claims arising out of violation of the NCPR provisions due to Your acts.</p>
                    </div>
                </div>
                <div className="prohibited">
                    <div className="term-number">
                        <h6>13)</h6>
                    </div>
                    <div className="term-text">
                        <h6>PROHIBITED ACTIVITIES</h6>
                        <p>The Company reserves the right to investigate, suspend and/or terminate the User account if the User has misused the Service or behaved in a manner the Company regards as inappropriate or unlawful, including actions or communications that occur off the Service, but involve users of the Service or information obtained through the Service. The following is a non-exclusive list of actions and activities that the User is not permitted to engage in while availing of the Service: impersonate any person or entity;</p>
                        <p>post any content that is prohibited by these Terms or any applicable law currently in force;</p>
                        <p>stalk or otherwise harass any person;</p>
                        <p>express or imply that any statements the User makes are endorsed by the Company without the Company’s specific prior written consent; use the Service in an illegal manner or to commit an illegal act;</p>
                        <p>ask or use users to conceal the identity, source, or destination of any illegally gained money or products;</p>
                        <p>use any robot, spider, site search/retrieval Website, or other manual or automatic device or process to retrieve, index, “data mine”, or in any way reproduce or circumvent the navigational structure or presentation of the Service or its contents;</p>
                        <p>collect usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email or unauthorized framing of or linking to the Service;</p>
                        <p>interfere with or disrupt the Service or the servers or networks connected to the Service;</p>
                        <p>email or otherwise transmit any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</p>
                        <p>forge headers or otherwise manipulate identifiers in order to disguise the origin of any information transmitted to or through the Service (either directly or indirectly through use of third party software);</p>
                        <p>“frame” or “mirror” any part of the Service, without the Company's prior written authorization;</p>
                        <p>use meta tags or code or other devices containing any reference to the Company or the Service (or any trademark, trade name, service mark, logo or slogan of the Company) to direct any person to any other website for any purpose;</p>
                        <p>modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile or otherwise disassemble any portion of the Service any software used on or for the Service, or cause others to do so;</p>
                        <p>post, use, transmit or distribute, directly or indirectly, (e.g. screen scrape) in any manner or media any content or information obtained from the Service other than solely in connection with the User use of the Service in accordance with these Terms.</p>
                    </div>
                </div>
                <div className="customerService">
                    <div className="term-number">
                        <h6>14)</h6>
                    </div>
                    <div className="term-text">
                        <h6>CUSTOMER SERVICE</h6>
                        <p>The Company may provide assistance and guidance through its customer care representatives to Users having paid accounts with the Website. When communicating with the Company’s customer care representatives, the User agrees to not be abusive, obscene, profane, offensive, sexist, threatening, harassing, racially offensive, and to not otherwise behave inappropriately. If the Company is of the impression that the User’s behaviour towards any of its customer care representatives or other employees is at any time threatening or offensive as described hereinabove, the Company reserves the right to immediately terminate the User’s account.</p>
                    </div>
                </div>
                <div className="serviceModification">
                    <div className="term-number">
                        <h6>15)</h6>
                    </div>
                    <div className="term-text">
                        <h6>MODIFICATIONS TO SERVICE</h6>
                        <p>The Company reserves the right at any time to modify or discontinue, temporarily or permanently, the Website or Service (or any part thereof) with or without notice. The User agrees and acknowledges that the Company shall not be liable to the User or to any third party for any modification, suspension or discontinuance of the Service. To protect the integrity of the Service, the Company reserves the right to block users from certain IP addresses from accessing the Service, at any time and in its sole discretion.</p>
                    </div>
                </div>
                <div className="disclaimer">
                    <div className="term-number">
                        <h6>16)</h6>
                    </div>
                    <div className="term-text">
                        <h6>DISCLAIMERS</h6>
                        <p>The User acknowledges and agrees that neither the Company nor its affiliates or third party partners are responsible for, and shall not be deemed to have any liability - directly or indirectly, any loss or damage, including personal injury or death, as a result of or alleged to be the result of</p>
                        <p>any incorrect or inaccurate content posted in the Service, whether caused by users or any of the equipment or programming associated with or utilized in the Service;</p>
                        <p>the timeliness, deletion or removal, incorrect delivery or failure to store any content, communications or personalization settings; the conduct, whether online or offline, of any user;</p>
                        <p>any error, omission or defect in, interruption, deletion, alteration, delay in operation or transmission, theft or destruction of, or unauthorized access to, any user or user communications;</p>
                        <p>any problems, failure or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure of email or players on account of technical problems or traffic congestion on the Internet or at any website or combination thereof, including injury or damage to users or to any other person’s computer or device related to or resulting from participating or downloading materials in connection with the Internet and/or in connection with the Service, and the User hereby expressly acknowledges and agrees that the Company has no responsibility or control over the same.</p>
                        <p>The User is aware and acknowledges that to the maximum extent allowed by applicable laws, the Company provides the Service on an “as is” and “as available” basis, and grants no warranties of any kind, whether express, implied, statutory or otherwise with respect to the Service (including all content contained therein), including (without limitation) any implied warranties of satisfactory quality, merchantability, fitness for a particular purpose or non-infringement. The Company does not represent or warrant that the Service will be uninterrupted or error free, secure or that any defects or errors in the Service will be corrected.</p>
                        <p>The Company may make third party information or content available through the Service. The User is aware that all such third-party content is the responsibility of the respective authors thereof, and the Company does not make any warranties or guarantees with respect to the same. The User is further aware that the Company does not: (i) guarantee the accuracy, completeness, or usefulness of any third-party content provided through the Service, or (ii) adopt, endorse or accept responsibility for the accuracy or reliability of any opinion, advice, or statement made by any party that appears in the Service. The User agrees and acknowledges that under no circumstances will the Company or its affiliates be responsible or liable for any loss or damage resulting from the user reliance on information or other Content posted in the Service or transmitted to or by any users.</p>
                    </div>
                </div>
                <div className="liability">
                    <div className="term-number">
                        <h6>17)</h6>
                    </div>
                    <div className="term-text">
                        <h6>LIMITATION OF LIABILITY</h6>
                        <p>To the fullest extent allowed by applicable law, in no event will the Company, or its affiliates be liable to the User or any third person for any indirect, reliance, consequential, exemplary, incidental, special or punitive damages, including, without limitation, loss of goodwill, damages for loss, corruption or breaches of data or programs, service interruptions and procurement of substitute services, even if the Company has been advised of the possibility of such damages.</p>
                        <p>The Parties expressly agree that in the event of any statute, rule, regulation or amendment coming into force that would result in the Company/ Website incurring any form of liability whatsoever, these Terms and the Policy will stand terminated one (1) day before the coming into effect of such statute, rule, regulation or amendment.</p>
                    </div>
                </div>
                <div className="jurisdiction">
                    <div className="term-number">
                        <h6>18)</h6>
                    </div>
                    <div className="term-text">
                        <h6>DISPUTE RESOLUTION AND JURISDICTION</h6>
                        <p>It is expressly agreed to by the Parties hereto that the formation, interpretation and performance of the Terms and any disputes arising herefrom will be resolved through a two-step Alternate Dispute Resolution (“ADR”) mechanism.</p>
                        <p>Mediation: In case of any dispute between the parties, the Parties will attempt to resolve the same amicably amongst themselves, to the mutual satisfaction of both Parties. In the event that the Parties are unable to reach such an amicable solution within thirty (30) days of one Party communicating the existence of a dispute to the other Party, the dispute will be resolved by arbitration, as detailed hereinbelow;</p>
                        <p>Arbitration: In the event that the Parties are unable to amicably resolve a dispute by mediation, said dispute will be referred to arbitration by a sole arbitrator to be appointed by the Company, and the award passed by such sole arbitrator will be valid and binding on both Parties. The Parties shall bear their own costs for the proceedings, although the sole arbitrator may, in his/her sole discretion, direct either Party to bear the entire cost of the proceedings. The arbitration shall be conducted in English, and the seat of Arbitration shall be Mumbai.</p>
                        <p>The Parties expressly agree that the Terms, Policy and any other agreements entered into between the Parties are governed by the laws, rules and regulations of India, and that the Courts at Mumbai, in the state of Maharashtra, India shall have exclusive jurisdiction over any disputes arising between the Parties.</p>
                    </div>
                </div>
                <div className="notices">
                    <div className="term-number">
                        <h6>19)</h6>
                    </div>
                    <div className="term-text">
                        <h6>NOTICES</h6>
                        <p>All notices shall be in writing and shall be made either via email or conventional mail. Notices to us must be sent to the attention of Customer Service at info@vmedulife.com, if by email, or at VM EDULIFE Private Limited if by conventional mail. Notices to you may be sent to the address supplied by you as part of your Registration Data. In addition, we may broadcast notices or messages through the site to inform you of changes to the site or other matters of importance, and such broadcasts shall constitute notice to you at the time of sending.</p>
                    </div>
                </div>
                <div className="provisions">
                    <div className="term-number">
                        <h6>20)</h6>
                    </div>
                    <div className="term-text">
                        <h6>MISCELLANEOUS PROVISIONS</h6>
                        <p>Entire Agreement: These Terms, read with the Policy and any specific guidelines or rules that are separately posted for particular services or offers in the Service, comprises the entire agreement between the User and the Company regarding the User’s use of the Service. If any provision of these Terms or the Policy is held invalid by any court or governmental authority of requisite jurisdiction, the remainder of these Terms shall continue in full force and effect and the Parties shall attempt to give full effect to their original intention.</p>
                        <p>Waiver: The failure of either Party at any time to require performance of any provision of these Terms shall in no manner affect such Party's right at a later time to enforce the same. No waiver by either Party of any breach of these Terms, whether by conduct or otherwise, in any one or more instances, shall be deemed to be or construed as a further or continuing waiver of any such breach, or a waiver of any other breach of these Terms.</p>
                        <p>Severability: If any provision/clause of these Terms is held to be invalid, illegal or unenforceable by any court or authority of competent jurisdiction, the validity, legality and enforceability of the remaining provisions/clauses of these Terms shall in no way be affected or impaired thereby, and each such provision/clause of these Terms shall be valid and enforceable to the fullest extent permitted by law. In such case, these Terms shall be reformed to the minimum extent necessary to correct any invalidity, illegality or unenforceability, while preserving to the maximum extent the original rights, intentions and commercial expectations of the Parties hereto, as expressed herein.</p>
                        <p>Non-Transferability: The User agrees that his/her account is non-transferable and all of the User’s rights to the profile or contents within the account shall terminate upon the User’s death or termination of the User’s access to the Service. The User is aware that he/she is not permitted to allow another individual to use of access his/her personal account, or use the Service in any manner whatsoever.</p>
                    </div>
                </div>
                <div className="relationships">
                    <div className="term-number">
                        <h6>21)</h6>
                    </div>
                    <div className="term-text">
                        <h6>RELATIONSHIP BETWEEN THE PARTIES</h6>
                        <p>The User is aware and agrees that no agency, partnership, joint venture or employment is created as a result of these Terms, and the User may not make any representations or bind the Company in any manner.</p>
                    </div>
                </div>
                <div className="contact-message">
                    <h6>Any questions about the Terms of Service should be sent to <span style={{ color: '#007bff' }}>info@vmedulife.com</span></h6>
                </div>
            </>
        );
    }
}

export default ServiceTerms;