import React from 'react';
import 'WEBSITE/WebsiteFooter/webfooter.css';
import twitter from 'WEBSITE/WebsiteFooter/images/twitter.png';
import instagram from 'WEBSITE/WebsiteFooter/images/instagram.png';
import facebook from 'WEBSITE/WebsiteFooter/images/facebook.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class WebsiteFooter extends React.Component {
    render() {
        return (
            <div className="footer-container">
                <div className="footer-links">
                    <div className="aboutUs col-md-3">
                        <h3 style={{ fontWeight: 'bold', marginBottom: 20 }}>About Us</h3>
                        <Link to="/about-vmedulife" style={{ color: '#212529' }}><h5>About Company</h5></Link>
                        <Link to="/our-presence" style={{ color: '#212529' }}><h5>Our Presence</h5></Link>
                        <Link to="/certifications" style={{ color: '#212529' }}><h5>Certifications</h5></Link>
                        <Link to="/events" style={{ color: '#212529' }}><h5>Product Trainings & FDP's</h5></Link>
                        <a  href="https://vmedulife.freshteam.com/jobs" target="_blank" style={{ color: '#212529' }}><h5>Careers</h5></a>


                       
                    </div>
                    <div className="connectUs col-md-3">
                        <h3 style={{ fontWeight: 'bold', marginBottom: 20 }}>Connect With Us</h3>
                        {/* <h5>Contact Sales</h5>
                        <h5>Connect with Experts</h5> */}
                        <Link to="/contact-us" style={{ color: '#212529' }}><h5>Sales Enquiry</h5></Link>
                        <Link to="/implementation" style={{ color: '#212529' }}><h5>Implementation</h5></Link>
                        <Link to="/customer-success" style={{ color: '#212529' }}><h5>Customer Success</h5></Link>
                        <Link to="/earning-support" style={{ color: '#212529' }}><h5>Learning & Support</h5></Link>
                        <Link to="/knowledge-base" style={{ color: '#212529' }}><h5>Knowledge Base</h5></Link>


                    </div>
                    <div className="content col-md-3">
                        <h3 style={{ fontWeight: 'bold', marginBottom: 20 }}>Content</h3>
                        <Link to="/case-studies" style={{ color: '#212529' }}><h5>Case Studies</h5></Link>
                        <Link to="/business-cases" style={{ color: '#212529' }}><h5>Business Cases</h5></Link>

                        <Link to="/webinar-on-demand" style={{ color: '#212529' }}><h5>Webinars</h5></Link>
                        <Link to="/sitemap" style={{ color: '#212529' }}><h5>Sitemap</h5></Link>
                        <Link to="/privacy-policy" style={{ color: '#212529' }}><h5>Privacy Policy</h5></Link>
                        <Link to="/terms-of-service" style={{ color: '#212529' }}><h5>Terms of Use</h5></Link>
                        <a href="https://blog.vmedulife.com/" target='blank' style={{ color: '#212529' }}><h5>Blogs</h5></a>
                    </div>
                    <div className="socialMedia col-md-3">
                        <h3 style={{ fontWeight: 'bold', marginBottom: 20 }}>Social Media</h3>
                        <div className="social-media">
                            <a href="https://twitter.com/vmedulife"><img src={`${twitter}`} className="social-img" /></a>
                            <a href="https://www.instagram.com/vmedulife/"><img src={`${instagram}`} className="social-img" /></a>
                            <a href="https://www.facebook.com/vmedulife"><img src={`${facebook}`} className="social-img" /></a>
                        </div>
                    </div>
                </div>
                <div className="contact-details">
                    <div className="aboutUs col-md-4">
                        <h5>Sales : <a href="tel:+91 9623964758">+91 9623964758</a></h5>
                        <address><a href="mailto:sales@vmedulife.com"> sales@vmedulife.com </a></address>
                    </div>
                    <div className="connectUs col-md-4">
                        <h5>Support :  <a href="tel:+91 9623964758">+91 8390701133</a></h5>
                        <address><a href="mailto:info@vmedulife.com"> info@vmedulife.com</a></address>

                        <h5></h5>
                    </div>
                    <div className="content col-md-4">
                        <h5>HR :  <a href="tel:080-473-60704 ">080-473-60704 </a> </h5>
                        <address><a href=" mailto:hr@vmedulife.com"> hr@vmedulife.com</a></address>
                    </div>
               
                </div>
                

                <div className="rights-reserved">
                    <div className="rights col-md-4" style={{ padding: 0 }}>
                        <h5>vmedulife<sup>&reg;</sup>  2021. All Rights Reserved.</h5>
                    </div>
                    <div className="sendEnquiry col-md-4">
                        <Link to="contact-us">
                        <button className="btn send-enquiry-button">
                            <FontAwesomeIcon icon={['fas','envelope']} size="lg" style={{ marginRight: 5 }} />
                            Sales Enquiry
                        </button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default WebsiteFooter;