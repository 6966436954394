import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import inventoryView from 'WEBSITE/Components/InventoryManagement/images/inventoryView.png';
import manageStock from 'WEBSITE/Components/InventoryManagement/images/manageStock.png';
import assetManagement from 'WEBSITE/Components/InventoryManagement/images/assetManagement.png';
import generalPurchase from 'WEBSITE/Components/InventoryManagement/images/generalPurchase.png';
import purchaseRequest from 'WEBSITE/Components/InventoryManagement/images/purchaseRequest.png';
import indents from 'WEBSITE/Components/InventoryManagement/images/indents.png';
import purchaseOrder from 'WEBSITE/Components/InventoryManagement/images/purchaseOrder.png';
import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/LibraryManagement/images/quickBenefits.png';
import consumption from 'WEBSITE/Components/InventoryManagement/images/consumption.png';
import importAction from 'WEBSITE/Components/InventoryManagement/images/import.png';
import breakage from 'WEBSITE/Components/InventoryManagement/images/breakage.png';
import longTerm from 'WEBSITE/Components/LibraryManagement/images/longTerm.png';
import costSaving from 'WEBSITE/Components/InventoryManagement/images/costSaving.png';
import lowStock from 'WEBSITE/Components/InventoryManagement/images/lowStock.png';
import precisePlanning from 'WEBSITE/Components/InventoryManagement/images/precisePlanning.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/LibraryManagement/librarymanagement.css';
import 'WEBSITE/Components/InventoryManagement/inventorymanagement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class InventoryManagement extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Inventory</h1>
                    </div>
                    <div className="main-text">
                        <h4>
                            Inventory management is a systematic approach to sourcing and storing Assets. It is an online system that manages assets, keeps track of purchase requests, indents and daily consumption of assets in an organization.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report" onClick={() => { document.getElementById('report').scrollIntoView(); }}>
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="inventory-view">
                    <div className="inventoryView">
                        <img src={`${inventoryView}`} className="inventoryView-img" />
                    </div>
                </div>
                <div className="features-container2" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                    </div>
                    <div className="manage-stock">
                        <div className="manage-stock-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Manage Stock</h5>
                            <div className="feature-description">
                                <p>Manage Stock helps in storing, tracking and controlling the assets and it’s use, it ensures that asset is well maintained and gives the forecasting when the asset is running low in quantity.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Export data
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Identify Asset with Unique Stock Inward Number
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Linking of Purchase Order
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="manageStock-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${manageStock}`} className="manageStock-img" />
                        </div>
                    </div>
                    <div className="asset-management">
                        <div className="assetManagement-image col-md-8" style={{ paddingLeft: 0 }}>
                            <img src={`${assetManagement}`} className="assetManagement-img" />
                        </div>
                        <div className="asset-management-text col-md-4" style={{ paddingRight: 0 }}>
                            <h5>Asset Management</h5>
                            <div className="feature-description">
                                <p>Asset Management is the process that guides the asset and it’s daily use. It helps in Maintaining and tracking the records of asset which leads into improvising the inventory of an organization.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Quick Import of existing asset into system.
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Export data for reconciliation of an asset
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Search Assets with simple and easy Tags
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="general-purchase">
                        <div className="general-purchase-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>General Purchase</h5>
                            <div className="feature-description">
                                <p>General Purchase enables an inventory personnel to maintain records of assets and its cost which are bought on daily basis for various purpose.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Use for tracking general expenses.
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Helps to maintain record of general purchase
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Export data on the go.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="generalPurchase-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${generalPurchase}`} className="generalPurchase-img" />
                        </div>
                    </div>
                    <div className="purchase-request">
                        <div className="purchaseRequest-image col-md-8" style={{ paddingLeft: 0 }}>
                            <img src={`${purchaseRequest}`} className="purchaseRequest-img" />
                        </div>
                        <div className="purchase-request-text col-md-4" style={{ paddingRight: 0 }}>
                            <h5>Purchase Request</h5>
                            <div className="feature-description">
                                <p><span style={{ color: '#10356D' }}>“vmedulife”</span> Inventory Management System gives you surety of raising Purchase Request against any asset which is low in stock OR to buy new asset at your finger tips in easiest and simplest way.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Raise Purchase Request
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Link Asset to Purchase Request
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Share Purchase Request with Vendors Instantly
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="indents">
                        <div className="indents-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Indents</h5>
                            <div className="feature-description">
                                <p>Indent is the process where asset is being requested from an inventory personnel, for use.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Multi level approver for indent
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Keep track  of raised indent with status
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="indents-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${indents}`} className="indents-img" />
                        </div>
                    </div>
                    <div className="purchase-order">
                        <div className="purchaseOrder-image col-md-8" style={{ paddingLeft: 0 }}>
                            <img src={`${purchaseOrder}`} className="purchaseOrder-img" />
                        </div>
                        <div className="purchase-order-text col-md-4" style={{ paddingRight: 0 }}>
                            <h5>Purchase Order</h5>
                            <div className="feature-description">
                                <p><span style={{ color: '#10356D' }}>“vmedulife”</span> offers effortlessly creation of Purchase Order, maintaining records for the same and easily accessible on the go.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Record every details related to Purchase Order
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Interlinked with asset and Purchase Request
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Accessible on the go
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="benefits-container1" id="benefits">
                    <div className="benefits-info">
                        <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                            <h3>Benefits</h3>
                            <div className="benefits-text">
                                <p>Perhaps the most important benefit of inventory is saving organization money. Enhanced the efficiency & productivity in operations. Gives transparency, decreases inaccuracies and avoid redundancy.</p>
                            </div>
                        </div>
                        {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                            <img src={`${benefitsLink}`} className="benefitLink-img" />
                        </div> */}
                    </div>
                    <div className="benefits-data">
                        <div className="quickBenefits1">
                            <div className="quickBenefits1-header">
                                <img src={`${quickBenefits}`} className="quickBenefits-img" />
                                <h3>Quick Benefits</h3>
                            </div>
                            <div className="quickBenefits1-list">
                                <div className="consumption">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${consumption}`} className="consumption-img" />
                                    </div>
                                    <h5>Daily Consumption of Asset</h5>
                                    <p>Daily Consumption of assets, helps identify the asset and their usage as per respective employees on daily basis.</p>
                                </div>
                                <div className="import">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${importAction}`} className="import-img" />
                                    </div>
                                    <h5>Import Action</h5>
                                    <p>This function gives access to import the existing data into the Inventory System which makes easy and hassle free to maintain records of previous and latest assets.</p>
                                </div>
                                <div className="breakage">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${breakage}`} className="breakage-img" />
                                    </div>
                                    <h5>Record of Breakage / Wastage Asset</h5>
                                    <p>Now you can maintain the records of breakage / wastage of asset which also helps to identify which asset has been broken or wasted by users.</p>
                                </div>
                            </div>
                        </div>
                        <div className="longTerm-benefits1">
                            <div className="longTerm-benefits1-header">
                                <img src={`${longTerm}`} className="longTerm-benefits-img" />
                                <h3>Long Term Benefits</h3>
                            </div>
                            <div className="longBenefits1-list">
                                <div className="valueSaving">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${costSaving}`} className="valueSaving-img" />
                                    </div>
                                    <h5>High Cost Saving</h5>
                                    <p>Inventory Management helps to save the cost at huge amount by keeping track on the records and maintenance of asset’s which eventually leads to  increase in the organization growth.</p>
                                </div>
                                <div className="lowStock">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${lowStock}`} className="lowStock-img" />
                                    </div>
                                    <h5>Low Stock Asset Management</h5>
                                    <p>Inventory Management ensures that asset is not completely vanished by keeping an eye on every asset with small details.</p>
                                </div>
                                <div className="precisePlanning">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${precisePlanning}`} className="precisePlanning-img" />
                                    </div>
                                    <h5>Precise Planning</h5>
                                    <p>Inventory Management gives a upper hand on Forecasting of Assets with precisely managing and executing the asset usage on daily basis.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container" id="report">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Available Quantity</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Check-in / Check-out Asset Report</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Stock Verification Report</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Categorized Asset</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Location</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Low Stock Quantity</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Utilized Quantity</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Report of Asset linked to Category</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Inward Verification Report</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Student Cumulative Cost Report</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Breakage & Waste Quantity</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per UnCategorized Asset</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Expiry Date</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Report of Asset Linked to Location</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Purchase Request Report</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Student Issue Cumulative Quantity Report</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Quotation Comparison</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Individual Breakage & Waste Report</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Received Date</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Report of Asset Linked to Department</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Daily Material Movement</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Regular Maintenance</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Equipment Corrective Maintenance Report</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Purchase Order Report</p>
                        </div>
                    </div>
                </div>
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                         
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default InventoryManagement;