import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import webinar from 'WEBSITE/Components/WebinarDemand/images/webinar.png';
import 'WEBSITE/Components/WebinarDemand/webinardemand.css';

class WebinarDemand extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="webinar-header">
                    <h5>Webinars</h5>
                </div>
                <div className="webinar-message">
                    <h4>“vmedulife proudly offers you our free webinar and a window of faith to see glimpse into the future of eLearning and learn the trends that will shape the eLearning industry”</h4>
                    <div className="team-name">
                        <h5 className="text-muted">- Team vmedulife</h5>
                    </div>
                </div>
                <div className="row ml-2 mr-3">
                    <div className="col-md-6">
                <div className="text-center">
                    <h5>For Webinar please fill the form</h5>
                    <iframe className="sales-enquiry-form" src='https://crm.zoho.com/crm/WebFormServeServlet?rid=20c5ee73b3c6c2352cdc8b4cb384297b2e952982d6cb96147cccbfef7c7f441agid412e4dcdb9aa879f2c10c0102d6c6fbf9e6d8b57fbd544c7342c0bbd73d8a809'></iframe>
                    </div>
                    </div>
                    <div className="col-md-6">
                        <div >
                        <img style={{maxWidth:'100%'}} src={webinar}/>
                        </div>
                    </div>

                </div>
              
            </>
        );
    }
}

export default WebinarDemand;