import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import grievanceView from 'WEBSITE/Components/OnlineGrievance/images/grievanceView.png';
import grievanceList from 'WEBSITE/Components/OnlineGrievance/images/grievanceList.png';
import grievanceChecked from 'WEBSITE/Components/OnlineGrievance/images/grievanceChecked.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/ResultAnalysis/images/quickBenefits.png';
import cooperation from 'WEBSITE/Components/CommitteeMeetings/images/cooperation.png';
import powerDispersion from 'WEBSITE/Components/CommitteeMeetings/images/powerDispersion.png';
import maintainData from 'WEBSITE/Components/CommitteeMeetings/images/maintainData.png';
import longTerm from 'WEBSITE/Components/ResultAnalysis/images/longTerm.png';
import onGoAccess from 'WEBSITE/Components/CommitteeMeetings/images/onGoAccess.png';
import preciseDecision from 'WEBSITE/Components/CommitteeMeetings/images/preciseDecision.png';
import workStructure from 'WEBSITE/Components/CommitteeMeetings/images/workStructure.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/OnlineGrievance/onlinegrievance.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class OnlineGrievance extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>e-Grievances Redressal Portal</h1>
                    </div>
                    <div className="main-text">
                        <h4>
                        vmedulife brings to you ‘eGrievance’, a Grievance Redressal System, to manage and redress grievances of the AICTE and UGC approved institutions with greater transparency, establishing a very positive ambience in the campus. Led by a team of committed professionals with combined expertise in the industry, vmedulife has pioneered progressive and groundbreaking solutions that are hallmarks of efficiency. Our unique industry-based approach helps clients envision, build, run and revolutionize the education system on a shoestring. The on-time service of e-Grievance helps to report any issues easily.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report" onClick={() => { document.getElementById('report').scrollIntoView(); }}>
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="grievance-view">
                    <div className="grievanceView">
                    <div className="d-flex justify-content-center">
                        <img src={`${grievanceView}`} className="grievanceView-img" />
                        </div>
                    </div>
                </div>
                <div className="grievance-features-container" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                    </div>
                    <div className="grievanceList">
                        <div className="grievanceList-text">
                            <div className="feature-description">
                                <p>e-Grievance, an online grievance redressal system, has features that make it appealing to all AICTE and UGC approved institutions. The easy-to-use and secure portal for students, faculty, parents, alumni, management, and non-teaching staff help to maintain confidentiality in grievance dealing procedures. It gives an opportunity for the institutions to implement a set of specific measures to ensure accountability and maximum output, by managing grievances of the stakeholders and increasing transparency, thereby establishing an affirmative ambiance.</p>
                            </div>
                        </div>
                        <div className="grievanceList-image">
                            <img src={`${grievanceList}`} className="grievanceList-img" />
                        </div>
                    </div>
                </div>
                <div className="grievanceInterface-container">
                    <div className="userInterface col-md-6">
                        <div className="userInterface-header">
                            <h5>User Interface</h5>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    Report generating feature to create account on the total, pending and closed complaints by assigning start and end date
                                </p>
                            </div>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    View grievances sent by the users
                                </p>
                            </div>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    Check the authenticity of complaints
                                </p>
                            </div>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    Verify and approve registered complaints by checking their authenticity
                                </p>
                            </div>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    ‘Reminder’ to view the alert message reminding pending complaints
                                </p>
                            </div>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    Generate reports on a monthly basis in word/pdf/excel format
                                </p>
                            </div>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    Option to send reply to the complaints along with attachments, if any
                                </p>
                            </div>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    Resolve grievances and give satisfactory solution
                                </p>
                            </div>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    Re-open complaints
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="grievanceInterface col-md-6">
                        <div className="grievanceInterface-header">
                            <h5>e-Grievance Cell In-charge Interface</h5>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    Students, parents, faculty, alumni, non-teaching staff and management belonging to AICTE approved institutions can lodge complaints
                                </p>
                            </div>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    One-stop platform to seek redressal of grievances
                                </p>
                            </div>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    Verify email and submit OTP before lodging any grievance
                                </p>
                            </div>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    View notifications and status of the complaints posted
                                </p>
                            </div>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    ‘Reminder’ option that sends alerts to remind about pending issues
                                </p>
                            </div>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    ‘Reopen’ option to recommence the case for further investigation
                                </p>
                            </div>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    Provision to give detailed account of the complaint in not less than 4000 words
                                </p>
                            </div>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    Attach supporting files in favor of the grievance
                                </p>
                            </div>
                        </div>
                        <div className="grievance-feature">
                            <div className="checked-image">
                                <img src={`${grievanceChecked}`} className="grievance-checked-img" />
                            </div>
                            <div className="grievance-feature-text">
                                <p className='grievance-feature-text-secondary'>
                                    View the reply for the complaint posted
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="benefits-container" id="benefits">
                    <div className="benefits-header col-md-12" style={{ paddingLeft: 0 }}>
                        <h3>Benefits</h3>
                        <div className="benefits-text">
                            <p>The ultimate goal of e-Grievance is to help users solve their problems easily by using the online grievance redressal portal. e-Grievance assures that files will be kept confidential and publishing or sharing of the grievances lodged will not happen in any case. The online grievance redressal system can be used by all institutes approved by AICTE and UGC to raise their grievance and it is taken up by the grievance cell in charge to give the best solution to the problem. The user-friendly interface of the system reduces the distance and time barrier between users and institute management to create a responsive and cordial situation in the institute.</p>
                        </div>
                    </div>
                    {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                        <img src={`${benefitsLink}`} className="benefitLink-img" />
                    </div> */}
                </div>
                <div className="grievance-benefits-header p-2">
                    <h4 className="" style={{paddingBottom:'0' ,fontSize:'20px' ,fontWeight:'bold'}}>Some of the numerous benefits of e-Grievance</h4>
                </div>
                <div className="grievance-benefits-list">
                    <div className="grievance-benefit">
                        <p>Students, parents, faculty, alumni, non-teaching staff or management need not go all the way to the grievance consultants to register complaints</p>
                    </div>
                    <div className="grievance-benefit">
                        <p>Encourages users to raise grievances without fear</p>
                    </div>
                    <div className="grievance-benefit">
                        <p>Provides a fair and speedy means of grievance handling</p>
                    </div>
                    <div className="grievance-benefit">
                        <p>Save time of aggrieved person and cell members</p>
                    </div>
                    <div className="grievance-benefit">
                        <p>Students, parents, faculty, alumni, non-teaching staff and management can lodge complaints in a discreet manner</p>
                    </div>
                    <div className="grievance-benefit">
                        <p>Greater confidentiality and transparency in grievance dealing procedure</p>
                    </div>
                    <div className="grievance-benefit">
                        <p>Helps to build peaceful atmosphere in the institute with openness and trust</p>
                    </div>
                    <div className="grievance-benefit">
                        <p>Automates entire complaint process right from email verification to closure of complaint</p>
                    </div>
                    <div className="grievance-benefit">
                        <p>Advantage over paper-based systems as e-Grievance can alert users immediately on the grievance, action taken etc.</p>
                    </div>
                    <div className="grievance-benefit">
                        <p>Online grievance redressal system is available 24*7</p>
                    </div>
                    <div className="grievance-benefit">
                        <p>Improved communication and alerts via SMS / Email</p>
                    </div>
                    <div className="grievance-benefit">
                        <p>Systematic and trustworthy grievance reporting</p>
                    </div>
                </div>
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container" id="report">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Monthly Open Grievances Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Monthly Closed Grievances Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Monthly Pending Grievances Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Custom Datewise Grievances Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Custom Statuswise Grievances Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Individual Grievance Report</p>
                        </div>
                    </div>
                </div>
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container1" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                          
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default OnlineGrievance;