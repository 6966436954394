import React from 'react';
import onBusiness from 'WEBSITE/Components/CustomerSuccess/images/onBusiness.png';
import longLasting from 'WEBSITE/Components/CustomerSuccess/images/longLasting.png';
import obsessedCulture from 'WEBSITE/Components/CustomerSuccess/images/obsessedCulture.png';
import 'WEBSITE/Components/CustomerSuccess/customersuccess.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

class CustomerSuccess extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="supports-header">
                    <h5>Customer Success</h5>
                </div>
                <div className="support-message">
                    <h1>“Customers are central pillar to our</h1>
                    <h1>mission as a company”</h1>
                </div>
                <div className="success-container">
                    <h5>We put your success first, by</h5>
                    <div className="successDetails">
                        <div className='ongoing-business'>
                            <img src={`${onBusiness}`} className="onBusiness-img" />
                            <p>Approaching our relationships with customers as an ongoing business partnership</p> 
                        </div>
                        <div className='long-lasting'>
                            <img src={`${longLasting}`} className="longLasting-img" />
                            <p>Acknowledging that our customer relationships are our long-lasting most valuable competitive advantage</p> 
                        </div>
                        <div className='obsessed-culture'>
                            <img src={`${obsessedCulture}`} className="obsessedCulture-img" />
                            <p>Promoting a customer-obsessed culture across departments, from our Product teams right through to implementation</p>
                        </div>
                    </div>
                </div>
                <div >
                    <h4 className="d-flex justify-content-center pl-5">"Our goal is to build a long-term strategy to achieve your organization
                    goals, starting with successful adoption of vmedulife platform"</h4>
                
                </div>
                <br></br>
                <div className="successContact">
                    <h5>Contact us to learn more about our approach to customer success</h5>
                    <Link to="contact-us">
                    <button className="btn contactUsButton">CONTACT US</button>
                    </Link>
                </div>
            </>
        );
    }
}

export default CustomerSuccess;