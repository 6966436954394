import React from 'react';
import { Link } from 'react-router-dom';
import studentProfile from 'WEBSITE/Components/AcademicManagement/images/studentProfile.png';
import LMS from 'WEBSITE/Components/AcademicManagement/images/LMS.png';
import mentoring from 'WEBSITE/Components/AcademicManagement/images/mentoring.png';
import trainingPlacement from 'WEBSITE/Components/AcademicManagement/images/trainingPlacement.png';
import autonomy from 'WEBSITE/Components/AcademicManagement/images/autonomy.png';
import eventsManagement from 'WEBSITE/Components/AcademicManagement/images/eventsManagement.png';
import noticeManagement from 'WEBSITE/Components/AcademicManagement/images/noticeManagement.png';
import assignment from 'WEBSITE/Components/AcademicManagement/images/assignment.png';
import meeting from 'WEBSITE/Components/AcademicManagement/images/meeting.png';
import academicPlanning from 'WEBSITE/Components/AcademicManagement/images/academicPlanning.png';
import 'WEBSITE/Components/AcademicManagement/academicmanagement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AcademicManagement extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <div className="academic-container">
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="academic-Header">
                    <h2>Academic Management Software</h2>
                </div>
                <div className="academic">
                    <div className="academicData-header">
                        <Link to="/academic-planning" style={{ color: '#212529' }}><h3>Academic Planning</h3></Link>
                    </div>
                    <div className="academic-info">
                        <div className="academic-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${academicPlanning}`} className="academic-img" />
                        </div>
                        <div className="academic-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                Academic Planning module offers best in class features of online classes, timetable generation, proposed syllabus, completion report, gap analysis, teaching aids/methodologies and student’s attendance of sessions.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="assignment-management">
                    <div className="assignmentManagement-header">
                        <Link to="/assignment-management" style={{ color: '#212529' }}><h3>Assignment Management</h3></Link>
                    </div>
                    <div className="assignmentManagement-info">
                        <div className="assignmentManagement-text col-md-5" style={{ paddingLeft: 0 }}>
                            <p>
                                “vmedulife” Assignment Module put forward the process of conducting assignments for students performance evaluation which lag behind in their Academics. This can be done easily by assigning assignment to slow learner’s as well as advance learner’s individually.
                            </p>
                        </div>
                        <div className="assignmentManagement-image col-md-7" style={{ paddingRight: 0 }}>
                            <img src={`${assignment}`} className="assignmentManagement-img" />
                        </div>
                    </div>
                </div>
                <div className="autonomy">
                    <div className="autonomy-header">
                        <Link to="/autonomous-exam-management" style={{ color: '#212529' }}><h3>Autonomous Examination</h3></Link>
                    </div>
                    <div className="autonomy-info">
                        <div className="autonomy-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${autonomy}`} className="autonomy-img" />
                        </div>
                        <div className="autonomy-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                Conducting and managing examination data for autonomous institute is now effortless with "vmedulife" Autonomous Examination Software module. Starting from examination scheduling, question paper generation, student enrollment, pre as well as post examination work can be easily managed in the system.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="LMS">
                    <div className="LMS-header">
                        <Link to="/learning-management-system" style={{ color: '#212529' }}><h3>Learning Management System</h3></Link>
                    </div>
                    <div className="LMS-info">
                        <div className="LMS-text col-md-5" style={{ paddingLeft: 0 }}>
                            <p>
                                A learning management system (LMS) is for the administration, documentation, tracking, reporting, automation and delivery of educational courses, training programs, or learning and development programs.
                            </p>
                        </div>
                        <div className="LMS-image col-md-7" style={{ paddingRight: 0 }}>
                            <img src={`${LMS}`} className="LMS-img" />
                        </div>
                    </div>
                </div>
                <div className="student-profile">
                    <div className="studentProfile-header">
                        <Link to="/student-profile" style={{ color: '#212529' }}><h3>Student Profile</h3></Link>
                    </div>
                    <div className="studentProfile-info">
                        <div className="studentProfile-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${studentProfile}`} className="studentProfile-img" />
                        </div>
                        <div className="studentProfile-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                Easily access your student data on few click and customize the multiple student profile as per your need. Keep record of genuine data.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mentoring">
                    <div className="mentoring-header">
                        <Link to="/mentoring" style={{ color: '#212529' }}><h3>Mentoring</h3></Link>
                    </div>
                    <div className="mentoring-info">
                        <div className="mentoring-text col-md-5" style={{ paddingLeft: 0 }}>
                            <p>
                                Guide and Train your student with our vmedulife mentoring portal. Maintain customize mentoring rule and guideline for different department. Keep track and record of mentors, their mentee and mentoring session conducted till date.
                            </p>
                        </div>
                        <div className="mentoring-image col-md-7" style={{ paddingRight: 0 }}>
                            <img src={`${mentoring}`} className="mentoring-img" />
                        </div>
                    </div>
                </div>
                <div className="analysis">
                    <div className="analysis-header">
                        <Link to="/result-analysis" style={{ color: '#212529' }}><h3>Result Analysis</h3></Link>
                    </div>
                    <div className="analysis-info">
                        <div className="analysis-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${noticeManagement}`} className="analysis-img" />
                        </div>
                        <div className="analysis-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                Conducting and managing examination data for offline exam is now effortless with the "vmedulife" Result Analysis module. Starting from examination scheduling, question paper generation, student enrollment, and student performance can be easily managed in the system.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="meeting">
                    <div className="meeting-header">
                        <Link to="/committee-meetings-management" style={{ color: '#212529' }}><h3>Committee & Meeting Management</h3></Link>
                    </div>
                    <div className="meeting-info">
                        <div className="meeting-text col-md-5" style={{ paddingLeft: 0 }}>
                            <p>
                                “vmedulife” brings you the Committee and Meeting Management Software where one can easily manage committees of organization and their members & meetings effortlessly and more economically.
                            </p>
                        </div>
                        <div className="meeting-image col-md-7" style={{ paddingRight: 0 }}>
                            <img src={`${meeting}`} className="meeting-img" />
                        </div>
                    </div>
                </div>
                <div className="training-placement">
                    <div className="trainingPlacement-header">
                        <Link to="/training-placement" style={{ color: '#212529' }}><h3>Training & Placement Management</h3></Link>
                    </div>
                    <div className="training-info">
                        <div className="training-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${trainingPlacement}`} className="training-img" />
                        </div>
                        <div className="training-data col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                Training & Placement Management is catered to meet everyone’s needs from student to colleges to recruiters where each and every detail is covered from student’s point of view, TPO’s point of view and recruiter point of view.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="notice">
                    <div className="notice-header">
                        <Link to="/noticeboard" style={{ color: '#212529' }}><h3>Notice Management</h3></Link>
                    </div>
                    <div className="notice-info">
                        <div className="notice-text col-md-5" style={{ paddingLeft: 0 }}>
                            <p>
                                Smoothly manage the notices of organization with customize notice category, from posting notice to assign the notice to the limited stakeholders or to all. Maintain records of notices posted till date.
                            </p>
                        </div>
                        <div className="notice-image col-md-7" style={{ paddingRight: 0 }}>
                            <img src={`${noticeManagement}`} className="notice-img" />
                        </div>
                    </div>
                </div>
                <div className="event-management">
                    <div className="eventManagement-header">
                        <Link to="/event-management" style={{ color: '#212529' }}><h3>Events Management</h3></Link>
                    </div>
                    <div className="event-info">
                        <div className="event-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${eventsManagement}`} className="event-img" />
                        </div>
                        <div className="event-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                Now the process of conducting events become easy with our event management software. Effortlessly plan and coordinate events of organization and keep tracks of events conducted till date.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AcademicManagement;