import React from 'react';
import knowledgeBase from 'WEBSITE/Components/LearningSupport/images/knowledgeBase.png';
import trainTeam from 'WEBSITE/Components/LearningSupport/images/trainTeam.png';
import empowerLearner from 'WEBSITE/Components/LearningSupport/images/empowerLearner.png';
import 'WEBSITE/Components/LearningSupport/learningsupport.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

class LearningSupport extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="learningSupport-header">
                    <h5>Learning & Support</h5>
                </div>
                <div className="learningSupport-message">
                    <h1>“At vmedulife we integrate customer service into every aspect of our business”</h1>
                </div>
                <div className="learningSupport-container">
                    <div className="learningSupportDetails">
                        <div className='knowledge-base'>
                            <img src={`${knowledgeBase}`} className="knowledgeBase-img" />
                            <h5>Grow your knowledge base</h5> 
                        </div>
                        <div className='train-team'>
                            <img src={`${trainTeam}`} className="trainTeam-img" />
                            <h5>Train your team</h5>  
                        </div>
                        <div className='empower-learner'>
                            <img src={`${empowerLearner}`} className="empowerLearner-img" />
                            <h5>Empower learners</h5> 
                        </div>
                    </div>
                </div>
                <div className="vmedulifeLearning-message">
                    <h4>We are responsible for transferring key product knowledge to customers. While we strive to answer as many of your questions as possible, ourselves, on some occasions we may also enlist the help of other departments and act as liaison between your team and technical resource.</h4>
                   
                </div>
                <div className="successContact">
                    <h5>Contact us to learn more about our approach to customer success</h5>
                    <Link to="contact-us">
                    <button className="btn contactSupportButton">CONTACT US</button>
                    </Link>
                </div>
            </>
        );
    }
}

export default LearningSupport;