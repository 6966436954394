import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import WebsiteHeader from 'WEBSITE/WebsiteHeader/WebsiteHeader';
import WebsiteRoutes from 'WEBSITE/WebsiteRoutes/WebsiteRoutes';
import WebsiteFooter from 'WEBSITE/WebsiteFooter/WebsiteFooter';

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <WebsiteHeader />
                    <WebsiteRoutes />
                <WebsiteFooter />
            </BrowserRouter>
        );
    }
}

export default App;