import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import 'WEBSITE/Components/Sitemap/sitemap.css';

class Sitemap extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="sitemap-header">
                    <h5>Sitemap</h5>
                </div>
                <div className="link-container">
                    <div className="homeLink">
                        <Link to="/" style={{ color: '#212529' }}><h5>Home</h5></Link>
                    </div>
                    <div className="product-menu">
                        <h5>PRODUCTS</h5>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="outcomeMenu">
                                    <Link to="/outcome-education" style={{ color: '#212529' }}><p style={{ fontWeight: 'bold' }}>Outcome Based Eduction Software</p></Link>
                                    <Link to="/academic-management" style={{ color: '#212529' }}><p style={{ fontWeight: 'bold' }}>Academic Management Software</p></Link>
                                    <Link to="/office-automation" style={{ color: '#212529' }}><p style={{ fontWeight: 'bold' }}>Office Automation (ERP)</p></Link>
                                </div>
  
                            </div>
                            <div className="col-md-3 academicMenu">
                                <h6 style={{ fontWeight: 'bold', marginBottom: 20 }}>ACADEMICS & EXAMINATION</h6>
                                <Link to="/academic-planning" style={{ color: '#212529' }}><p>Academic Planning</p></Link>
                                <Link to="/outcome" style={{ color: '#212529' }}><p>Outcome Software</p></Link>
                                <Link to="/online-exam" style={{ color: '#212529' }}><p>Online Exam Software</p></Link>
                                <Link to="/assignment-management" style={{ color: '#212529' }}><p> Online Assignment Software</p></Link>
                                <Link to="/result-analysis" style={{ color: '#212529' }}><p>Result Analysis</p></Link>
                                <Link to="/rubrics" style={{ color: '#212529' }}><p>Rubrics</p></Link>
                                <Link to="/iqac-reports" style={{ color: '#212529' }}><p>IQAC Report</p></Link>
                                <Link to="/autonomous-exam-management" style={{ color: '#212529' }}><p>Autonomy Exam Management</p></Link>
                                <Link to="/learning-management-system" style={{ color: '#212529' }}><p>Learning Management System</p></Link>
                                <Link to="/course-file" style={{ color: '#212529' }}><p>Course File - Academic</p></Link>
                                <Link to="/faculty-profile" style={{ color: '#212529' }}><p>Faculty Profile</p></Link>
                                <Link to="/library-management" style={{ color: '#212529' }}><p >Integrated Library Management System</p></Link>
                                <Link to="/inventory-management" style={{ color: '#212529' }}><p>Inventory Management</p></Link>
                                <Link to="/training-placement" style={{ color: '#212529' }}><p >Training & Placement Management</p></Link>
                                <Link to="/alumni-management" style={{ color: '#212529' }}><p >Alumini Management</p></Link>
                            </div>
                            <div className="col-md-3 adminMenu">
                                <h6 style={{ fontWeight: 'bold', marginBottom: 20 }}>ADMINISTRATIVE</h6>
                                <Link to="/employee-management" style={{ color: '#212529' }}><p>Employee Management</p></Link>
                                <Link to="/admission-management" style={{ color: '#212529' }}><p>Admission</p></Link>
                                <Link to="/online-feedback" style={{ color: '#212529' }}><p>Online Feedback</p></Link>
                                <Link to="/committee-meetings-management" style={{ color: '#212529' }}><p>Committee & Meeting Management</p></Link>
                                <Link to="/online-grievance" style={{ color: '#212529' }}><p>Online Grievance Redressal Portal</p></Link>
                                <Link to="/forms" style={{ color: '#212529' }}><p>Forms</p></Link>
                                <Link to="/leave-management" style={{ color: '#212529' }}><p>Attendance & Leave Management System</p></Link>
                                <Link to="/event-management" style={{ color: '#212529' }}><p>Trainings & FDP's</p></Link>
                                <Link to="/noticeboard" style={{ color: '#212529' }}><p>Notice Board</p></Link>
                                <Link to="/transport" style={{ color: '#212529' }}><p>Transport Management System</p></Link>
                                <Link to="/sms-panel" style={{ color: '#212529' }}><p>SMS Panel </p></Link>
                                <Link to="/inward-outward" style={{ color: '#212529' }}><p>Inward / Outward</p></Link>
                                <Link to="/hostel-management" style={{ color: '#212529' }}><p>Hostel Management </p></Link>
                            </div>
                            <div className="col-md-2 accountingMenu">
                                <h6 style={{ fontWeight: 'bold', marginBottom: 20 }}>ACCOUNTING</h6>
                                <Link to="/accounting" style={{ color: '#212529' }}><p>Accounting</p></Link>
                                <Link to="/payroll" style={{ color: '#212529' }}><p>Payroll</p></Link>
                                <Link to="/fees" style={{ color: '#212529' }}><p>Fees Management Software</p></Link>
                                <h6 style={{ fontWeight: 'bold', paddingTop: 20, marginBottom: 20 }}>STUDENTS</h6>
                                <Link to="/student-profile" style={{ color: '#212529' }}><p>Student Profile</p></Link>
                                <Link to="/certificates" style={{ color: '#212529' }}><p>Certificate</p></Link>
                                <Link to="/mentoring" style={{ color: '#212529' }}><p>Mentoring</p></Link>
                            </div>
                        </div>
                    </div>
                    <div className="success-menu">
                        <h5>SUCCESS</h5>
                        <div className="success-links">
                            <div className="col-md-3" style={{ paddingLeft: 0 }}>
                                <Link to="/implementation" style={{ color: '#212529' }}><p>Implementation</p></Link>
                            </div>
                            <div className="col-md-3">
                                <Link to="/customer-success" style={{ color: '#212529' }}><p>Customer Success</p></Link>
                            </div>
                            <div className="col-md-3">
                                <Link to="/learning-support" style={{ color: '#212529' }}><p>Learning & Support</p></Link>
                            </div>
                            <div className="col-md-3" style={{ paddingRight: 0 }}>
                                <Link to="/knowledge-base" style={{ color: '#212529' }}><p>Knowledge Base</p></Link>
                            </div>
                        </div>
                    </div>
                    <div className="resource-menu">
                        <h5>RESOURCES</h5>
                        <div className="resource-links">
                            <div className="col-md-3" style={{ paddingLeft: 0 }}>
                                <Link to="/case-studies" style={{ color: '#212529' }}><p>Case Studies</p></Link>
                            </div>
                            <div className="col-md-3">
                                <Link to="/business-cases" style={{ color: '#212529' }}><p>Business Cases</p></Link>
                            </div>
                            <div className="col-md-3">
                                <Link to="/webinar-on-demand" style={{ color: '#212529' }}><p>Webinars</p></Link>
                            </div>
                        </div>
                    </div>
                    <div className="about-menu">
                        <h5>ABOUT</h5>
                        <div className="about-links">
                            <div className="col-md-3" style={{ paddingLeft: 0 }}>
                                <Link to="/about-vmedulife" style={{ color: '#212529' }}><p>About Company</p></Link>
                            </div>
                            <div className="col-md-3">
                                <Link to="/our-presence" style={{ color: '#212529' }}><p>Our Presence</p></Link>
                            </div>
                            <div className="col-md-3">
                                <Link to="/certifications" style={{ color: '#212529' }}><p>Certifications</p></Link>
                            </div>
                            <div className="col-md-3" style={{ paddingRight: 0 }}>
                                <Link to="/events" style={{ color: '#212529' }}><p>Events</p></Link>
                            </div>
                        </div>
                    </div>
                    <div className="loginLink">
                    <a href="https://www.vmedulife.com/vmlogin.php" target="_blank" style={{ color: '#212529' }}>Login</a>
                    </div>
                </div>
            </>
        );
    }
}

export default Sitemap;