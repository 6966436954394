import { Route, Switch } from 'react-router-dom';
import React from 'react';
import WebsiteInfo from 'WEBSITE/Components/WebsiteInfo/WebsiteInfo';
import OutcomeEducation from 'WEBSITE/Components/OutcomeEducation/OutcomeEducation';
import LibraryManagement from 'WEBSITE/Components/LibraryManagement/LibraryManagement';
import InventoryManagement from 'WEBSITE/Components/InventoryManagement/InventoryManagement';
import TrainingPlacement from 'WEBSITE/Components/TrainingPlacement/TrainingPlacement';
import AlumniManagement from 'WEBSITE/Components/AlumniManagement/AlumniManagement';
import AcademicManagement from 'WEBSITE/Components/AcademicManagement/AcademicManagement';
import OfficeAutomation from 'WEBSITE/Components/OfficeAutomation/OfficeAutomation';
import AssignmentManagement from 'WEBSITE/Components/AssignmentManagement/AssignmentManagement';
import OnlineExam from 'WEBSITE/Components/OnlineExam/OnlineExam';
import ResultAnalysis from 'WEBSITE/Components/ResultAnalysis/ResultAnalysis';
import RubricsProjects from 'WEBSITE/Components/RubricsProjects/RubricsProjects';
import AcademicPlanning from 'WEBSITE/Components/AcademicPlanning/AcademicPlanning';
import Outcome from 'WEBSITE/Components/Outcome/Outcome';
import IqacReports from 'WEBSITE/Components/IqacReports/IqacReports';
import AutonomyExamManagement from 'WEBSITE/Components/AutonomyExamManagement/AutonomyExamManagement';
import LearningManagement from 'WEBSITE/Components/LearningManagement/LearningManagement';
import CourseFile from 'WEBSITE/Components/CourseFile/CourseFile';
import FacultyProfile from 'WEBSITE/Components/FacultyProfile/FacultyProfile';
import EmployeeManagement from 'WEBSITE/Components/EmployeeManagement/EmployeeManagement';
import AdmissionManagement from 'WEBSITE/Components/AdmissionManagement/AdmissionManagement';
import OnlineFeedback from 'WEBSITE/Components/OnlineFeedback/OnlineFeedback';
import CommitteeMeetings from 'WEBSITE/Components/CommitteeMeetings/CommitteeMeetings';
import OnlineGrievance from 'WEBSITE/Components/OnlineGrievance/OnlineGrievance';
import Forms from 'WEBSITE/Components/Forms/Forms';
import LeaveManagement from 'WEBSITE/Components/LeaveManagement/LeaveManagement';
import EventManagement from 'WEBSITE/Components/EventManagement/EventManagement';
import Noticeboard from 'WEBSITE/Components/Noticeboard/Noticeboard';
import Transport from 'WEBSITE/Components/Transport/Transport';
import SMSPanel from 'WEBSITE/Components/SMSPanel/SMSPanel';
import InwardOutward from 'WEBSITE/Components/InwardOutward/InwardOutward';
import HostelManagement from 'WEBSITE/Components/HostelManagement/HostelManagement';
import Accounting from 'WEBSITE/Components/Accounting/Accounting';
import Payroll from 'WEBSITE/Components/Payroll/Payroll';
import Fees from 'WEBSITE/Components/Fees/Fees';
import StudentProfile from 'WEBSITE/Components/StudentProfile/StudentProfile';
import Certificate from 'WEBSITE/Components/Certificate/Certificate';
import Mentoring from 'WEBSITE/Components/Mentoring/Mentoring';
import Implementation from 'WEBSITE/Components/Implementation/Implementation';
import CustomerSuccess from 'WEBSITE/Components/CustomerSuccess/CustomerSuccess';
import LearningSupport from 'WEBSITE/Components/LearningSupport/LearningSupport';
import KnowledgeBase from 'WEBSITE/Components/KnowledgeBase/KnowledgeBase';
import CaseStudy from 'WEBSITE/Components/CaseStudy/CaseStudy';
import BusinessCase from 'WEBSITE/Components/BusinessCase/BusinessCase';
import WebinarDemand from 'WEBSITE/Components/WebinarDemand/WebinarDemand';
import AboutCompany from 'WEBSITE/Components/AboutCompany/AboutCompany';
import OurPresence from 'WEBSITE/Components/OurPresence/OurPresence';
import Certifications from 'WEBSITE/Components/Certifications/Certifications';
import Events from 'WEBSITE/Components/Events/Events';
import PrivacyPolicy from 'WEBSITE/Components/PrivacyPolicy/PrivacyPolicy';
import ServiceTerms from 'WEBSITE/Components/ServiceTerms/ServiceTerms';
import Sitemap from 'WEBSITE/Components/Sitemap/Sitemap';
import ContactUs from 'WEBSITE/Components/ContactUs/ContactUs';

class WebsiteRoutes extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={WebsiteInfo} />
                <Route path="/outcome-education" component={OutcomeEducation} />
                <Route path="/academic-management" component={AcademicManagement} />
                <Route path="/office-automation" component={OfficeAutomation} />
                <Route path="/library-management" component={LibraryManagement} />
                <Route path="/inventory-management" component={InventoryManagement} />
                <Route path="/training-placement" component={TrainingPlacement} />
                <Route path="/alumni-management" component={AlumniManagement} />
                <Route path="/academic-planning" component={AcademicPlanning} />
                <Route path="/outcome" component={Outcome} />
                <Route path="/assignment-management" component={AssignmentManagement} />
                <Route path="/online-exam" component={OnlineExam} />
                <Route path="/result-analysis" component={ResultAnalysis} />
                <Route path="/rubrics" component={RubricsProjects} />
                <Route path="/iqac-reports" component={IqacReports} />
                <Route path="/autonomous-exam-management" component={AutonomyExamManagement} />
                <Route path="/learning-management-system" component={LearningManagement} />
                <Route path="/course-file" component={CourseFile} />
                <Route path="/faculty-profile" component={FacultyProfile} />
                <Route path="/employee-management" component={EmployeeManagement} />
                <Route path="/admission-management" component={AdmissionManagement} />
                <Route path="/online-feedback" component={OnlineFeedback} />
                <Route path="/committee-meetings-management" component={CommitteeMeetings} />
                <Route path="/online-grievance" component={OnlineGrievance} />
                <Route path="/forms" component={Forms} />
                <Route path="/leave-management" component={LeaveManagement} />
                <Route path="/event-management" component={EventManagement} />
                <Route path="/noticeboard" component={Noticeboard} />
                <Route path="/transport" component={Transport} />
                <Route path="/sms-panel" component={SMSPanel} />
                <Route path="/inward-outward" component={InwardOutward} />
                <Route path="/hostel-management" component={HostelManagement} />
                <Route path="/accounting" component={Accounting} />
                <Route path="/payroll" component={Payroll} />
                <Route path="/fees" component={Fees} />
                <Route path="/student-profile" component={StudentProfile} />
                <Route path="/certificates" component={Certificate} />
                <Route path="/mentoring" component={Mentoring} />
                <Route path="/implementation" component={Implementation} />
                <Route path="/customer-success" component={CustomerSuccess} />
                <Route path="/learning-support" component={LearningSupport} />
                <Route path="/knowledge-base" component={KnowledgeBase} />
                <Route path="/case-studies" component={CaseStudy} />
                <Route path="/business-cases" component={BusinessCase} />
                <Route path="/webinar-on-demand" component={WebinarDemand} />
                <Route path="/about-vmedulife" component={AboutCompany} />
                <Route path="/our-presence" component={OurPresence} />
                <Route path="/certifications" component={Certifications} />
                <Route path="/events" component={Events} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/terms-of-service" component={ServiceTerms} />
                <Route path="/sitemap" component={Sitemap} />
                <Route path="/contact-us" component={ContactUs} />
            </Switch>
        );
    }
}

export default WebsiteRoutes;