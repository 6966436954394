import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import outcomeView from 'WEBSITE/Components/Outcome/images/outcomeView.png';
import coMapping from 'WEBSITE/Components/Outcome/images/coMapping.png';
// import manageFaculty from 'WEBSITE/Components/AcademicPlanning/images/manageFaculty.png';
// import courseSyllabus from 'WEBSITE/Components/AcademicPlanning/images/courseSyllabus.png';
// import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/LibraryManagement/images/quickBenefits.png';
import clarifiedCurriculum from 'WEBSITE/Components/Outcome/images/clarifiedCurriculum.png';
import teachingFocus from 'WEBSITE/Components/Outcome/images/teachingFocus.png';
import robustFramework from 'WEBSITE/Components/Outcome/images/robustFramework.png';
import longTerm from 'WEBSITE/Components/ResultAnalysis/images/longTerm.png';
import benchmarkAchievement from 'WEBSITE/Components/Outcome/images/benchmarkAchievement.png';
import standardAchievement from 'WEBSITE/Components/Outcome/images/standardAchievement.png';
import accreditationPreparation from 'WEBSITE/Components/Outcome/images/accreditationPreparation.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/LibraryManagement/librarymanagement.css';
import 'WEBSITE/Components/InventoryManagement/inventorymanagement.css';
import 'WEBSITE/Components/TrainingPlacement/trainingplacement.css';
import 'WEBSITE/Components/Outcome/outcome.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Outcome extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Outcome Software</h1>
                    </div>
                    <div className="main-text">
                        <h4>
                            Outcome Based Education is an approach to education in which decisions about the curriculum, instruction and assessment are driven by the exit learning outcomes that the students should demonstrate at the end of a program or a course.
                            In outcome-based education, “product defines process ”.
                            It is the opposite of input-based education where the emphasis is on the teaching and the system is happy to accept whatever is the result.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report" onClick={() => { document.getElementById('report').scrollIntoView(); }}>
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="outcome-view">
                    <div className="outcomeView">
                        <img src={`${outcomeView}`} className="outcomeView-img" />
                    </div>
                </div>
                <div className="features-container1" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                    </div>
                    <div className="co-mapping">
                        <div className="co-mapping-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>CO - PO Mapping Software</h5>
                            <div className="mapping-description">
                                <p>Mapping of course outcomes can be a tedious task for the staff and management. vmedulife has an Outcome based education software built right in to bring flexibility & efficiency to the lives of teachers by actively supporting OBE.</p>
                                {/* <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Fully Customized
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Maintaining Course Code & Course Credit
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Maintaining Academic Year-wise data of each course
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Customize student list for courses as per requirements
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="coMapping-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${coMapping}`} className="coMapping-img" />
                        </div>
                    </div>
                </div>
                <div className="outcome-features-header">
                    <h4>Some of the numerous features of Outcome Software</h4>
                </div>
                <div className="outcome-features-list">
                    <div className="outcome-feature">
                        <p>Define Outcome Attainment Levels</p>
                    </div>
                    <div className="outcome-feature">
                        <p>Link CO with PO</p>
                    </div>
                    <div className="outcome-feature">
                        <p>CO PO Mapping Justification</p>
                    </div>
                    <div className="outcome-feature">
                        <p>Questionwise Analysis and outcome attainment</p>
                    </div>
                    <div className="outcome-feature">
                        <p>Linking outcome with feedback/surveys </p>
                    </div>
                    <div className="outcome-feature">
                        <p>Direct & Indirect Outcome Attainment</p>
                    </div>
                    <div className="outcome-feature">
                        <p>Course-wise Outcome Attainment</p>
                    </div>
                    <div className="outcome-feature">
                        <p>Batch-wise Outcome Attainment</p>
                    </div>
                    <div className="outcome-feature">
                        <p>Gap Analysis</p>
                    </div>
                    <div className="outcome-feature">
                        <p>Assigning of Bloom's Levels</p>
                    </div>
                    <div className="outcome-feature">
                        <p>Involve Competencies and Performance Indicators</p>
                    </div>
                    <div className="outcome-feature">
                        <p>Decide Quality of Question Paper</p>
                    </div>
                </div>
                <div className="benefits-container1" id="benefits">
                    <div className="benefits-info">
                        <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                            <h3>Benefits</h3>
                            {/* <div className="benefits-text">
                                <p>Perhaps the most important benefit of inventory is saving organisation money. Enhanced the efficiency & productivity in operations. Gives transparency, decreases inaccuracies and avoid redundancy.</p>
                            </div> */}
                        </div>
                        {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                            <img src={`${benefitsLink}`} className="benefitLink-img" />
                        </div> */}
                    </div>
                    <div className="benefits-data">
                        <div className="quickBenefits1">
                            <div className="quickBenefits1-header">
                                <img src={`${quickBenefits}`} className="quickBenefits-img" />
                                <h3>Quick Benefits</h3>
                            </div>
                            <div className="quickBenefits1-list">
                                <div className="clarifiedCurriculum">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${clarifiedCurriculum}`} className="clarifiedCurriculum-img" />
                                    </div>
                                    <h5>Curriculum Clarification</h5>
                                    <p>Clarifies the curriculum for both students and teachers</p>
                                </div>
                                <div className="teachingFocus">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${teachingFocus}`} className="teachingFocus-img" />
                                    </div>
                                    <h5>Focus on Teaching</h5>
                                    <p className="text-center">Provides a focus for teaching and learning</p>
                                </div>
                                <div className="robustFramework">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${robustFramework}`} className="robustFramework-img" />
                                    </div>
                                    <h5>Robust Framework</h5>
                                    <p>Robust framework for integration of the curriculum</p>
                                </div>
                            </div>
                        </div>
                        <div className="longTerm-benefits1">
                            <div className="longTerm-benefits1-header">
                                <img src={`${longTerm}`} className="longTerm-benefits-img" />
                                <h3>Long Term Benefits</h3>
                            </div>
                            <div className="longBenefits1-list">
                                <div className="benchmarkAchievement">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${benchmarkAchievement}`} className="benchmarkAchievement-img" />
                                    </div>
                                    <h5>Benchmark Achievement</h5>
                                    <p>Achieve benchmarks against which the curriculum can be judged</p>
                                </div>
                                <div className="standardAchievement">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${standardAchievement}`} className="standardAchievement-img" />
                                    </div>
                                    <h5>Standards Achievement</h5>
                                    <p>Achieve standards in teaching and learning in the long run</p>
                                </div>
                                <div className="accreditationPreparation">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${accreditationPreparation}`} className="accreditationPreparation-img" />
                                    </div>
                                    <h5>Accreditation Preparation</h5>
                                    <p>Establish the correlation between the courses and the Program Outcomes (POs) and Program Specific Outcomes (PSO's)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container" id="report">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Course Outcome</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Desired CO PO Mapping Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Course - PO Matrix Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Direct Attainment Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Indirect Attainment Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Batchwise Attainment Report</p>
                        </div>
                    </div>
                </div>
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container1" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                        
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Outcome;