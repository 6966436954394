import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import studentProfileView from 'WEBSITE/Components/StudentProfile/images/studentProfileView.png';
import studentProfile from 'WEBSITE/Components/StudentProfile/images/studentProfile.png';
import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/LibraryManagement/images/quickBenefits.png';
import accessQuickly from 'WEBSITE/Components/StudentProfile/images/accessQuickly.png';
import digitalRegister from 'WEBSITE/Components/StudentProfile/images/digitalRegister.png';
import cloudStorage from 'WEBSITE/Components/StudentProfile/images/cloudStorage.png';
import longTerm from 'WEBSITE/Components/LibraryManagement/images/longTerm.png';
import unlimitedStorage from 'WEBSITE/Components/StudentProfile/images/unlimitedStorage.png';
import dataGlance from 'WEBSITE/Components/StudentProfile/images/dataGlance.png';
import everyAccess from 'WEBSITE/Components/StudentProfile/images/everyAccess.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/StudentProfile/studentprofile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class StudentProfile extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Student Profile</h1>
                    </div>
                    <div className="main-text">
                        <h4>
                            Easily access your student data on few click and customize the multiple student profile as per your need. Keep record of genuine data.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report" onClick={() => { document.getElementById('report').scrollIntoView(); }}>
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="studentProfile-view">
                    <div className="studentProfileView">
                        <img src={`${studentProfileView}`} className="studentProfileView-img" />
                    </div>
                </div>
                <div className="features-container2" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                    </div>
                    <div className="students-profile">
                        <div className="student-profile-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Customize Profile</h5>
                            <div className="feature-description">
                                <p>To keep track of students activity and maintain its record, organization can customize respective student profile for better segregation. This customize profile can then assign to particular stream or group and can be edit existing profile and add new components to the profile on the go.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            One can easily assign different profile template to different student or group of students
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Student can request for change in details
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Approve student details once submitted by students
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Employees as well as student can download and print their respective data
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="studentProfile-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${studentProfile}`} className="studentProfile-img" />
                        </div>
                    </div>
                </div>
                <div className="benefits-container" id="benefits">
                    <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                        <h3>Benefits</h3>
                        {/* <div className="benefits-text">
                            <p>Offers the industry leading technology to collect fees in well systematic way, where it reduces the long queues of students, valuable time of both student & staff and paperless process which leads in “Go Green” movement.</p>
                        </div> */}
                    </div>
                    {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                        <img src={`${benefitsLink}`} className="benefitLink-img" />
                    </div> */}
                </div>
                <div className="quickBenefits">
                    <div className="quickBenefits-header">
                        <img src={`${quickBenefits}`} className="quickBenefits-img" />
                        <h3>Quick Benefits</h3>
                    </div>
                    <div className="quickBenefits-list">
                        <div className="accessQuickly">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${accessQuickly}`} className="accessQuickly-img" />
                            </div>
                            <h5>Quick Access</h5>
                            <p>Offers you the best in concept software with multiple features abd facilities where access to student data is instant and simple.</p>
                        </div>
                        <div className="digitalRegister">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${digitalRegister}`} className="digitalRegister-img" />
                            </div>
                            <h5>Digital Register</h5>
                            <p>Stores and maintain the student’s important data and documents on the cloud having access to digital register within few clicks at your finger tips.</p>
                        </div>
                        <div className="cloudStorage">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${cloudStorage}`} className="cloudStorage-img" />
                            </div>
                            <h5>Cloud Storage</h5>
                            <p>Data is maintained and kept tracking of made simple and easy by storing all on the cloud and having access to the same data on the go.</p>
                        </div>
                    </div>
                </div>
                <div className="longTerm-benefits">
                    <div className="longTerm-benefits-header">
                        <img src={`${longTerm}`} className="longTerm-benefits-img" />
                        <h3>Long Term Benefits</h3>
                    </div>
                    <div className='longTerm-benefits-list'>
                        <div className="unlimitedStorage">
                            <div className="unlimitedStorage-image">
                                <img src={`${unlimitedStorage}`} className="unlimitedStorage-img" />
                            </div>
                            <div className="unlimitedStorage-text">
                                <h5>Unlimited Storage</h5>
                                <p>Offers you the unlimited space of storage where data is stored in high end security and with utter most safety.</p>
                            </div>
                        </div>
                        <div className="dataGlance">
                            <div className="dataGlance-image">
                                <img src={`${dataGlance}`} className="dataGlance-img" />
                            </div>
                            <div className="dataGlance-text">
                                <h5>Glance on Past  Data</h5>
                                <p>One can have easy access on the past out student’s data with few simple clicks and at finger tips.</p>
                            </div>
                        </div>
                        <div className="everyAccess">
                            <div className="everyAccess-image">
                                <img src={`${everyAccess}`} className="everyAccess-img" />
                            </div>
                            <div className="everyAccess-text">
                                <h5>24*7 Access</h5>
                                <p>Have access to  data from anywhere and anytime.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container" id="report">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Student List Genderwise Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Student List Streamwise Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Student Academic Yearwise Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Student Admission Yearwise Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Student Statewise Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>NRI Student Report</p>
                        </div>
                    </div>
                </div>
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                        
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default StudentProfile;