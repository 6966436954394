import React from 'react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import InstituteStructure from 'WEBSITE/Components/WebsiteInfo/images/institute_structure.png';
import outcomeEducation from 'WEBSITE/Components/WebsiteInfo/images/pencil.png';
import outcomeHeader from 'WEBSITE/Components/WebsiteInfo/images/pencilHeader.png';
import graduation from 'WEBSITE/Components/WebsiteInfo/images/graduation.png';
import graduationHeader from 'WEBSITE/Components/WebsiteInfo/images/graduationHeader.png';
import settings from 'WEBSITE/Components/WebsiteInfo/images/settings.png';
import settingsHeader from 'WEBSITE/Components/WebsiteInfo/images/settingsHeader.png';
import teams from 'WEBSITE/Components/WebsiteInfo/images/team.png';
import state from 'WEBSITE/Components/WebsiteInfo/images/flag.png';
import aws from 'WEBSITE/Components/WebsiteInfo/images/aws.png';
import googleCloud from 'WEBSITE/Components/WebsiteInfo/images/googleCloud.png';
import sendgrid from 'WEBSITE/Components/WebsiteInfo/images/sendgrid.png';
import textLocal from 'WEBSITE/Components/WebsiteInfo/images/textLocal.png';
import googlemeet from 'WEBSITE/Components/WebsiteInfo/images/googlemeet.png';
import zoom from 'WEBSITE/Components/WebsiteInfo/images/zoom.png';
import razorpay from 'WEBSITE/Components/WebsiteInfo/images/razorpay.png';
import payU from 'WEBSITE/Components/WebsiteInfo/images/payU.png';
import automateio from 'WEBSITE/Components/WebsiteInfo/images/automateio.png';
import clients from 'WEBSITE/Components/WebsiteInfo/images/value.png';
import clipboard from 'WEBSITE/Components/WebsiteInfo/images/clipboard.png';
import calendar from 'WEBSITE/Components/WebsiteInfo/images/calendar.png';
import feedback from 'WEBSITE/Components/WebsiteInfo/images/feedback.png';
import correct from 'WEBSITE/Components/WebsiteInfo/images/correct.png';
import research from 'WEBSITE/Components/WebsiteInfo/images/research.png';
import presentation from 'WEBSITE/Components/WebsiteInfo/images/presentation.png';
import project from 'WEBSITE/Components/WebsiteInfo/images/project.png';
import assignment from 'WEBSITE/Components/WebsiteInfo/images/assignment.png';
import report from 'WEBSITE/Components/WebsiteInfo/images/report.png';
import profile from 'WEBSITE/Components/WebsiteInfo/images/profile.png';
import learning from 'WEBSITE/Components/WebsiteInfo/images/learning.png';
import mentoring from 'WEBSITE/Components/WebsiteInfo/images/mentoring.png';
import training from 'WEBSITE/Components/WebsiteInfo/images/training.png';
import autonomy from 'WEBSITE/Components/WebsiteInfo/images/autonomy.png';
import events from 'WEBSITE/Components/WebsiteInfo/images/event.png';
import meeting from 'WEBSITE/Components/WebsiteInfo/images/meeting.png';
import inventory from 'WEBSITE/Components/WebsiteInfo/images/inventory.png';
import library from 'WEBSITE/Components/WebsiteInfo/images/library.png';
import employee from 'WEBSITE/Components/WebsiteInfo/images/employee.png';
import admission from 'WEBSITE/Components/WebsiteInfo/images/admission.png';
import facultyprofile from 'WEBSITE/Components/WebsiteInfo/images/facultyprofile.png';
import grievance from 'WEBSITE/Components/WebsiteInfo/images/grievance.png';
import forms from 'WEBSITE/Components/WebsiteInfo/images/forms.png';
import leave from 'WEBSITE/Components/WebsiteInfo/images/leave.png';
import accounting from 'WEBSITE/Components/WebsiteInfo/images/accounting.png';
import fees from 'WEBSITE/Components/WebsiteInfo/images/fees.png';
import payroll from 'WEBSITE/Components/WebsiteInfo/images/payroll.png';
import inward from 'WEBSITE/Components/WebsiteInfo/images/inward.png';
import hostel from 'WEBSITE/Components/WebsiteInfo/images/hostel.png';
import noticeboard from 'WEBSITE/Components/WebsiteInfo/images/noticeboard.png';
import transport from 'WEBSITE/Components/WebsiteInfo/images/transport.png';
import sms from 'WEBSITE/Components/WebsiteInfo/images/sms.png';
import maharashtra from 'WEBSITE/Components/WebsiteInfo/images/maharashtra.png';
import delhi from 'WEBSITE/Components/WebsiteInfo/images/delhi.png';
import uttarpradesh from 'WEBSITE/Components/WebsiteInfo/images/uttarpradesh.png';
import tamilnadu from 'WEBSITE/Components/WebsiteInfo/images/tamilnadu.png';
import andhra from 'WEBSITE/Components/WebsiteInfo/images/andhra.png';
import karnataka from 'WEBSITE/Components/WebsiteInfo/images/karnataka.png';
import himachal from 'WEBSITE/Components/WebsiteInfo/images/himachal.png';
import mizoram from 'WEBSITE/Components/WebsiteInfo/images/mizoram.png';
import hyderabad from 'WEBSITE/Components/WebsiteInfo/images/hyderabad.png';
import kerala from 'WEBSITE/Components/WebsiteInfo/images/kerala.png';
import gujrat from 'WEBSITE/Components/WebsiteInfo/images/gujrat.png';
import bihar from 'WEBSITE/Components/WebsiteInfo/images/bihar.png';
import robert from 'WEBSITE/Components/WebsiteInfo/images/robert.png';
import marvin from 'WEBSITE/Components/WebsiteInfo/images/marvin.png';
import 'WEBSITE/Components/WebsiteInfo/webinfo.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class WebsiteInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            siteKey: '6LeSD6YUAAAAAJ2WT-mbD42c5DqVsa1q_KScQ-Xy'
        };
    }

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <div className="webInfo-container">
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="pageDetails-container">
                    <div className="pageHeader">
                        <h2>Academic Management, Accreditation & Office Automation Cloud Software</h2>
                    </div>
                    <div className="pageInfo">
                        <p>Cloud based, successful complete integrated solution for your organization.</p>
                    </div>
                </div>
                <div className="key-features col-md-12">
                    <div className="col-md-4">
                        <div className="outcome-education" onClick={() => { document.getElementById('outcomeEducation').scrollIntoView(); }}>
                            <img src={`${outcomeEducation}`} style={{ marginRight: 10 }} />
                            <h5 style={{ fontWeight: 'bold', textAlign: 'center', color: '#007bff' }}>Outcome Based Education Software (Accreditation)</h5>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="academic-software" onClick={() => { document.getElementById('academicManagement').scrollIntoView(); }}>
                            <img src={`${graduation}`} style={{ marginRight: 10 }} />
                            <h5 style={{ fontWeight: 'bold', color: '#007bff' }}>Academic Management Software</h5>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="office-automation" onClick={() => { document.getElementById('officeAutomation').scrollIntoView(); }}>
                            <img src={`${settings}`} style={{ marginRight: 10 }} />
                            <h5 style={{ fontWeight: 'bold', color: '#007bff' }}>Office Automation (ERP)</h5>
                        </div>
                    </div>
                    {/* <div className="institute-structure col-md-6">
                        <img src={`${InstituteStructure}`}  className="institute-img" />
                    </div> */}
                </div>
                <br />
                <br />
                <div className="client-count col-md-12">
                    <div className="state-presence  col-md-4 " >
                        <div>
                            <img src={`${teams}`} className="client-img" />
                        </div>
                        <div >
                            <h3 style={{ fontWeight: 'bold' }}>1 Million +</h3>
                            <h6>Active Users</h6>
                        </div>
                    </div>
                    <div className="state-presence col-md-4">
                        <div>
                            <img src={`${state}`} className="client-img" />
                        </div>
                        <div>
                            <h3 style={{ fontWeight: 'bold' }}>25 +</h3>
                            <h6>Products </h6>
                        </div>
                    </div>
                    <div className="active-clients col-md-4">
                        <div>
                            <img src={`${clients}`} className="client-img" />
                        </div>
                        <div>
                            <h3 style={{ fontWeight: 'bold' }}>8 + </h3>
                            <h6>Years of Shaping Innovation</h6>
                        </div>
                    </div>
                </div>
                <div className="featured-title">
                    <p className="featured-header">Featured Solutions</p>
                </div>
                <div className="outcomeEducation" id="outcomeEducation">
                    <div className="outcome-header">
                        <img src={`${outcomeHeader}`} className="outcome-header-image" style={{ marginRight: 40 }} />
                        <Link to="/outcome-education" style={{ color: '#212529' }}><h3 style={{ fontWeight: 'bold' }}>Outcome Based Education Software (Accreditation)</h3></Link>
                    </div>
                    <div className="options-container">
                        <div className="outcome-options">
                            <Link to="/outcome" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${clipboard}`}/>
                                    <p>Outcome Software</p>
                                </div>
                            </Link>
                            <Link to="/academic-planning" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${calendar}`}/>
                                    <p>Academic Planning</p>
                                </div>
                            </Link>
                            <Link to="/online-feedback" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${feedback}`}/>
                                    <p>Online Feedback</p>
                                </div>
                            </Link>
                            <Link to="/online-exam" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${correct}`}/>
                                    <p>Online Exam</p>
                                </div>
                            </Link>
                            <Link to="/result-analysis" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${research}`}/>
                                    <p>Result Analysis</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="options-container">
                        <div className="outcome-options">
                            <Link to="/faculty-profile" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${presentation}`}/>
                                    <p>Faculty Profile</p>
                                </div>
                            </Link>
                            <Link to="/rubrics" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${project}`}/>
                                    <p>Rubrics</p>
                                </div>
                            </Link>
                            <Link to="/assignment-management" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${assignment}`}/>
                                    <p>Assignment</p>
                                </div>
                            </Link>
                            <Link to="/iqac-reports" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${report}`}/>
                                    <p>IQAC Report</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="academicManagement" id="academicManagement">
                    <div className="academic-header">
                        <img src={`${graduationHeader}`} className="outcome-header-image" style={{ marginRight: 40 }} />
                        <Link to="/academic-management" style={{ color: '#212529' }}><h3 style={{ fontWeight: 'bold' }}>Academic Management Software</h3></Link>
                    </div>
                    <div className="options-container">
                        <div className="outcome-options">
                            <Link to="/student-profile" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${profile}`}/>
                                    <p>Student Profile</p>
                                </div>
                            </Link>
                            <Link to="/learning-management-system" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${learning}`}/>
                                    <p>Learning Management System</p>
                                </div>
                            </Link>
                            <Link to="/mentoring" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${mentoring}`}/>
                                    <p>Mentoring</p>
                                </div>
                            </Link>
                            <Link to="/training-placement" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${training}`}/>
                                    <p>Training & Placement Management</p>
                                </div>
                            </Link>
                            <Link to="/autonomous-exam-management" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${autonomy}`}/>
                                    <p>Autonomous Examination</p>
                                </div>
                            </Link>
                        </div>    
                    </div>
                    <div className="options-container">
                        <div className="outcome-options">
                            <Link to="/academic-planning" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${calendar}`}/>
                                    <p>Academic Planning</p>
                                </div>
                            </Link>
                            <Link to="/event-management" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${events}`}/>
                                    <p>Events</p>
                                </div>
                            </Link>
                            <Link to="/committee-meetings-management" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${meeting}`}/>
                                    <p>Committee & Meeting Management</p>
                                </div>
                            </Link>
                            <Link to="/assignment-management" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${assignment}`}/>
                                    <p>Assignment</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="officeAutomation" id="officeAutomation">
                    <div className="automation-header">
                        <img src={`${settingsHeader}`} className="outcome-header-image" style={{ marginRight: 40 }} />
                        <Link to="/office-automation" style={{ color: '#212529' }}><h3 style={{ fontWeight: 'bold' }}>Office Automation Software (ERP)</h3></Link>
                    </div>
                    <div className='options-container'>
                        <div className="outcome-options">
                            <Link to="/inventory-management" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${inventory}`}/>
                                    <p>Inventory Management</p>
                                </div>
                            </Link>
                            <Link to="/library-management" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${library}`}/>
                                    <p>Integrated Library Management System</p>
                                </div>
                            </Link>
                            <Link to="/employee-management" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${employee}`}/>
                                    <p>Employee Management</p>
                                </div>
                            </Link>
                            <Link to="/admission-management" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${admission}`}/>
                                    <p>Admission</p>
                                </div>
                            </Link>
                            <Link to="/faculty-profile" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${facultyprofile}`}/>
                                    <p>Faculty Profile</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='options-container'>
                        <div className="outcome-options">
                            <Link to="/online-feedback" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${feedback}`}/>
                                    <p>Online Feedback</p>
                                </div>
                            </Link>
                            <Link to="/online-grievance" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${grievance}`}/>
                                    <p>Online Grievance</p>
                                </div>
                            </Link>
                            <Link to="/forms" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${forms}`}/>
                                    <p>Forms</p>
                                </div>
                            </Link>
                            <Link to="/leave-management" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${leave}`}/>
                                    <p>Leave Management</p>
                                </div>
                            </Link>
                            <Link to="/accounting" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${accounting}`}/>
                                    <p>Accounting</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='options-container'>
                        <div className="outcome-options">
                            <Link to="/fees" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${fees}`}/>
                                    <p>Fees</p>
                                </div>
                            </Link>
                            <Link to="/payroll" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${payroll}`}/>
                                    <p>Payroll</p>
                                </div>
                            </Link>
                            <Link to="/inward-outward" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${inward}`}/>
                                    <p>Inward / Outward</p>
                                </div>
                            </Link>
                            <Link to="/hostel-management" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${hostel}`}/>
                                    <p>Hostel Management</p>
                                </div>
                            </Link>
                            <Link to="/noticeboard" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${noticeboard}`}/>
                                    <p>Noticeboard</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='options-container'>
                        <div className="outcome-options">
                            <Link to="/transport" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${transport}`}/>
                                    <p>Transport</p>
                                </div>
                            </Link>
                            <Link to="/sms-panel" style={{ color: '#212529' }}>
                                <div className="option-item">
                                    <img src={`${sms}`}/>
                                    <p>SMS Panel</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="our-presence col-md-12">
                    <h2>Our Presence</h2>
                    <div className="presence-container">
                        <div className="presence-card">
                            <img src={`${maharashtra}`} className="presence-img" />
                            <h6 style={{ fontWeight: 'bold' }}>MAHARASHTRA</h6>
                        </div>
                        <div className="presence-card">
                            <img src={`${delhi}`} className="presence-img" />
                            <h6 style={{ fontWeight: 'bold' }}>DELHI</h6>
                        </div>
                        <div className="presence-card">
                            <img src={`${uttarpradesh}`} className="presence-img" />
                            <h6 style={{ fontWeight: 'bold' }}>UTTAR PRADESH</h6>
                        </div>
                        <div className="presence-card">
                            <img src={`${tamilnadu}`} className="presence-img" />
                            <h6 style={{ fontWeight: 'bold' }}>TAMIL NADU</h6>
                        </div>
                        <div className="presence-card">
                            <img src={`${andhra}`} className="presence-img" />
                            <h6 style={{ fontWeight: 'bold' }}>ANDHRA PRADESH</h6>
                        </div>
                        <div className="presence-card">
                            <img src={`${karnataka}`} className="presence-img" />
                            <h6 style={{ fontWeight: 'bold' }}>KARNATAKA</h6>
                        </div>
                        <div className="presence-card">
                            <img src={`${himachal}`} className="presence-img" />
                            <h6 style={{ fontWeight: 'bold' }}>HIMACHAL PRADESH</h6>
                        </div>
                        <div className="presence-card">
                            <img src={`${mizoram}`} className="presence-img" />
                            <h6 style={{ fontWeight: 'bold' }}>MIZORAM</h6>
                        </div>
                        <div className="presence-card">
                            <img src={`${hyderabad}`} className="presence-img" />
                            <h6 style={{ fontWeight: 'bold' }}>TELANGANA</h6>
                        </div>
                        <div className="presence-card">
                            <img src={`${kerala}`} className="presence-img" />
                            <h6 style={{ fontWeight: 'bold' }}>KERALA</h6>
                        </div>
                        <div className="presence-card">
                            <img src={`${gujrat}`} className="presence-img" />
                            <h6 style={{ fontWeight: 'bold' }}>GUJARAT</h6>
                        </div>
                        <div className="presence-card">
                            <img src={`${bihar}`} className="presence-img" />
                            <h6 style={{ fontWeight: 'bold' }}>BIHAR</h6>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: 'center', marginTop: 40 }}>
                    <p style={{ fontWeight: 'bold' }}>Continuing our journey of implementation across the nation...</p>
                </div>
                
                <div className="technology-partners">
                    <h3>Technology Partners</h3>
                    <div className="row ">
                        <div className="col-md-3">
                        <div className="technology-partner ">
                            <img src={`${aws}`} className="aws-img" />
                        </div>
                        </div>
                        <div className="col-md-3">
                        <div className="technology-partner ">
                            <img src={`${googleCloud}`} className="googleCloud-img" />
                        </div>
                        </div>

                        <div className="col-md-3">
                        <div className="technology-partner ">
                            <img src={`${sendgrid}`} className="sendgrid-img" />
                        </div>
                        </div>

                        <div className="col-md-3">

                        <div className="technology-partner ">
                            <img src={`${textLocal}`} className="textLocal-img" />
                        </div>
                        </div>

                    </div>
                </div>
                <div className="company-integrations">
                    <h3>Integrations</h3>
                    
                    <div className="company-integrations-list ">
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-2">
                                <div className="company-integration  text-center" style={{height:"60%"}}>
                                <img className="text-center" src={`${googlemeet}`} height="80%"  />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="company-integration">
                                <img src={`${zoom}`}   />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="company-integration">
                                <img src={`${razorpay}`}   />
                                </div>
                            </div>  
                            <div className="col-md-2">
                                <div className="company-integration">
                                <img src={`${payU}`}   />
                                </div>
                            </div> 
                            <div className="col-md-2">
                                <div className="company-integration" >
                                <img src={`${automateio}`}  />
                                </div>
                            </div>   
                            <div className="col-md-1"></div>                         
                        </div>
    
                    </div>
                </div>
                <div className="company-certifications">
                    <h3>Company Certifications</h3>
                    <div className="company-certifications-list">
                        <div className="company-certification">
                            <div className="trophies">
                                <FontAwesomeIcon icon={['fas', 'award']} color="#0ABCB1" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#0ABCB1" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#0ABCB1" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#0ABCB1" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#0ABCB1" style={{ marginRight: 5 }} />
                            </div>
                            <div className="ISO-header">
                                <h5>ISO 9001:2015 Company</h5>
                            </div>
                            <div className="ISO-details">
                                <h6>ISO Certified Company</h6>
                            </div>
                        </div>
                        <div className="company-certification">
                            <div className="trophies">
                                <FontAwesomeIcon icon={['fas', 'award']} color="#0ABC11" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#0ABC11" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#0ABC11" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#0ABC11" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#0ABC11" style={{ marginRight: 5 }} />
                            </div>
                            <div className="SCGJ-header">
                                <h5>Industry Associate of SCGJ, India</h5>
                            </div>
                            <div className="SCGJ-details">
                                <h6>An Industry Partner of SCGJ, India</h6>
                                <h6>Govt. Of India, Initiative</h6>
                            </div>
                        </div>
                        <div className="company-certification">
                            <div className="trophies">
                                <FontAwesomeIcon icon={['fas', 'award']} color="#AC1539" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#AC1539" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#AC1539" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#AC1539" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#AC1539" style={{ marginRight: 5 }} />
                            </div>
                            <div className="NASSCOM-header">
                                <h5>Official NASSCOM Member</h5>
                            </div>
                            <div className="NASSCOM-details">
                                <h6>NASSCOM Member</h6>
                            </div>
                        </div>
                        <div className="company-certification">
                            <div className="trophies">
                                <FontAwesomeIcon icon={['fas', 'award']} color="#0A26BC" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#0A26BC" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#0A26BC" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#0A26BC" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#0A26BC" style={{ marginRight: 5 }} />
                            </div>
                            <div className="DIPP-header">
                                <h5>Department of Industrial</h5>
                                <h5>Policy and Promotion</h5>
                            </div>
                            <div className="DIPP-details">
                                <h6>Certificate of Recognition</h6>
                            </div>
                        </div>
                        <div className="company-certification">
                            <div className="trophies">
                                <FontAwesomeIcon icon={['fas', 'award']} color="#630ABC" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#630ABC" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#630ABC" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#630ABC" style={{ marginRight: 5 }} />
                                <FontAwesomeIcon icon={['fas', 'award']} color="#630ABC" style={{ marginRight: 5 }} />
                            </div>
                            <div className="RGAP-header">
                                <h5>Certificate of Recognition -</h5>
                                <h5>Govt. of Andhra Pradesh</h5>
                            </div>
                            <div className="RGAP-details">
                                <h6>AP Innovation Society</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="testimonials-container">
                        <div className="testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="enquiry col-md-12">
                    <h2>For Enquiry</h2>
                    <div className="enquiry-container">
                        <div className="enquiryData col-md-4">
                            <div className="send-enquiry">
                                <iframe className="sales-enquiry-form" src='https://crm.zoho.com/crm/WebFormServeServlet?rid=20c5ee73b3c6c2352cdc8b4cb384297b2e952982d6cb96147cccbfef7c7f441agid412e4dcdb9aa879f2c10c0102d6c6fbf9e6d8b57fbd544c7342c0bbd73d8a809'></iframe>
                            </div>
                            <div className="row mt-3">
                            <div className="col-md-4">
                                <div >
                                Sales-
                                </div>
                            </div>
                            <div className="col-md-4">
                            <a href="tel:+91 9623964758">+919623964758</a>
                            </div>
                            <div className="col-md-4">
                            <a href=" mailto:sales@vmedulife.com"> sales@vmedulife.com</a>
                            </div>
                        </div>
                        <div className="row ">
                        <div className="col-md-4">
                            <div >
                            Support-
                            </div>
                        </div>
                        <div className="col-md-4">
                        <a href="tel:+91 8390701133">+918390701133</a>
                        </div>
                        <div className="col-md-4">
                        <a href=" mailto:info@vmedulife.com">info@vmedulife.com</a>
                        </div>
                    </div>
                    <div className="row ">
                    <div className="col-md-4">
                        <div >
                        HR-
                        </div>
                    </div>
                    <div className="col-md-4">
                        <a href="tel:080-473-60704">080-473-60704 </a>
                    </div>
                    <div className="col-md-4">
                        <a href=" mailto:hr@vmedulife.com">hr@vmedulife.com</a>
                    </div>
                </div>
                            <div className="team-numbers">
                             
                            </div>
                        </div>
                        <div className="office-locations col-md-4">
                            <div className="pune-office">
                                <div className="office-header" style={{ display: 'flex' }}>
                                    <FontAwesomeIcon icon={['fas','map-marker-alt']} className="location-icon" size="lg" color="#921E1E" />
                                    <h3>Pune</h3>
                                </div>
                                <div>
                                    <p className="address">vmedulife Software</p>
                                </div>
                                <div>
                                    <p className="address">2nd Floor, EFC Business Centre,</p>
                                </div>
                                <div>
                                    <p className="address">Rajiv Gandhi Infotech Park,</p>
                                </div>
                                <div>
                                    <p className="address">Hinjewadi Phase 1,</p>
                                </div>
                                <div>
                                    <p className="address">Pune, Maharashtra</p>
                                </div>
                                <div>
                                    <p className="address">Pin - 411 057</p>
                                </div>
                            </div>
                            <hr />
                            <div className="banglore-office">
                                <div className="office-header" style={{ display: 'flex' }}>
                                    <FontAwesomeIcon icon={['fas','map-marker-alt']} size="lg" className="location-icon" color="#921E1E" />
                                    <h3>Bengaluru</h3>
                                </div>
                                <div>
                                    <p className="address">vmedulife Software</p>
                                </div>
                                <div>
                                    <p className="address">Brigade IRV Centre,</p>
                                </div>
                                <div>
                                    <p className="address">09th &10th Floor,</p>
                                </div>
                                <div>
                                    <p className="address">Nallurahalli, Whitefield,</p>
                                </div>
                                <div>
                                    <p className="address">Bangalore,India</p>
                                </div>
                                <div>
                                    <p className="address">PIN - 560 066</p>
                                </div>
                            </div>
                            <hr />
                            <div className="hyderabad-office">
                                <div className="office-header" style={{ display: 'flex' }}>
                                    <FontAwesomeIcon icon={['fas','map-marker-alt']} size="lg" className="location-icon" color="#921E1E" />
                                    <h3>Hyderabad</h3>
                                </div>
                                <div>
                                    <p className="address">vmedulife Software</p>
                                </div>
                                <div>
                                    <p className="address">Unit No 203, 2nd Floor,</p>
                                </div>
                                <div>
                                    <p className="address">SBR CV Towers, Sector-I,</p>
                                </div>
                                <div>
                                    <p className="address">Survey No. 64, HUDA Techno Enclave,</p>
                                </div>
                                <div>
                                    <p className="address">Madhapur, Hyderabad - 500081</p>
                                </div>
                            </div>
                            <hr />
                            <div className="kerala-office">
                                <div className="office-header" style={{ display: 'flex' }}>
                                    <FontAwesomeIcon icon={['fas','map-marker-alt']} size="lg" className="location-icon" color="#921E1E" />
                                    <h3>Kerala</h3>
                                </div>
                                <div>
                                    <p className="address">vmedulife Software</p>
                                </div>
                                <div>
                                    <p className="address">Spacelance,</p>
                                </div>
                                <div>
                                    <p className="address">No. 4/461, 2nd Floor,</p>
                                </div>
                                <div>
                                    <p className="address">Valamkottil Towers, Judgemukku,</p>
                                </div>
                                <div>
                                    <p className="address">Kakkanad, Cochin</p>
                                </div>
                                <div>
                                    <p className="address">Kerala, India 682 021</p>
                                </div>
                            </div>
                            <hr />
                            <div className="gujrat-office">
                                <div className="office-header" style={{ display: 'flex' }}>
                                    <FontAwesomeIcon icon={['fas','map-marker-alt']} className="location-icon" size="lg" color="#921E1E" />
                                    <h3>Gujarat</h3>
                                </div>
                                <div>
                                    <p className="address">vmedulife Software</p>
                                </div>
                                <div>
                                    <p className="address">501, 5th Floor, Shapath 1,</p>
                                </div>
                                <div>
                                    <p className="address">Suite No.1061 SG Highway,</p>
                                </div>
                                <div>
                                    <p className="address">Bodakdev,</p>
                                </div>
                                <div>
                                    <p className="address">Ahmedabad, Gujarat</p>
                                </div>
                                <div>
                                    <p className="address">Pin - 380 054</p>
                                </div>
                            </div>
                            <hr />
                            <div className="delhi-office">
                                <div className="office-header" style={{ display: 'flex' }}>
                                    <FontAwesomeIcon icon={['fas','map-marker-alt']} className="location-icon" size="lg" color="#921E1E" />
                                    <h3>Delhi</h3>
                                </div>
                                <div>
                                    <p className="address">vmedulife Software</p>
                                </div>
                                <div>
                                    <p className="address">Regus Assotech, Upper Ground Level,</p>
                                </div>
                                <div>
                                    <p className="address">Tower 2, Assotech Business Cresterra,</p>
                                </div>
                                <div>
                                    <p className="address">Sec – 135,</p>
                                </div>
                                <div>
                                    <p className="address">Noida, UP</p>
                                </div>
                                <div>
                                    <p className="address">Pin - 201 301</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WebsiteInfo;