import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import 'WEBSITE/Components/BusinessCase/businesscase.css';

class BusinessCase extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="businessCase-header">
                    <h5>Business Cases</h5>
                </div>
                <div className="businessCase-message">
                    <h4>“Understanding industry trends and new techniques can help your organization stay ahead of the competition, “vmedulife” offers you the best-in-class business cases to boost your organization all the way up”</h4>
                  
                </div>
                <div className="businessCase-enquiry-container">
                    <div className="businessCase-enquiry-info col-md-6" style={{ padding: 0 }}>
                        <div className="enquiryDetails">
                            <h5>Understand the business cases for vmedulife need and implement at your own organization</h5>
                        </div>
                        <div className="enquiryMessage">
                            <h6>For Information Contact Us by filling form</h6>
                        </div>
                    </div>
                    <div className="businessCase-enquiry-form col-md-6">
                        <iframe className="sales-enquiry-form" src='https://crm.zoho.com/crm/WebFormServeServlet?rid=20c5ee73b3c6c2352cdc8b4cb384297b2e952982d6cb96147cccbfef7c7f441agid412e4dcdb9aa879f2c10c0102d6c6fbf9e6d8b57fbd544c7342c0bbd73d8a809'></iframe>
                    </div>
                </div>
            </>
        );
    }
}

export default BusinessCase;