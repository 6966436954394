import React from 'react';
import { Link } from 'react-router-dom';
import outcome from 'WEBSITE/Components/OutcomeEducation/images/outcome.png';
import academicPlanning from 'WEBSITE/Components/OutcomeEducation/images/academicPlanning.png';
import feedback from 'WEBSITE/Components/OutcomeEducation/images/feedback.png';
import exam from 'WEBSITE/Components/OutcomeEducation/images/exam.png';
import resultAnalysis from 'WEBSITE/Components/OutcomeEducation/images/resultAnalysis.png';
import rubrics from 'WEBSITE/Components/OutcomeEducation/images/rubrics.png';
import assignment from 'WEBSITE/Components/OutcomeEducation/images/assignment.png';
import IQAC from 'WEBSITE/Components/OutcomeEducation/images/IQAC.png';
import 'WEBSITE/Components/OutcomeEducation/outcomeeducation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class OutcomeEducation extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <div className="outcome-container">
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="outcome-Header">
                    <h2>Outcome Based Education Software  (Accreditation)</h2>
                </div>
                <div className="outcome-data">
                    <div className="outcomeData-header">
                        <Link to="/outcome" style={{ color: '#212529' }}><h3>Outcome Software</h3></Link>
                    </div>
                    <div className="outcome-info">
                        <div className="outcome-text col-md-5" style={{ paddingLeft: 0 }}>
                            <p>
                                Outcome Based Education is an approach to education in which decisions about the curriculum, instruction and assessment are driven by the exit learning outcomes that the students should demonstrate at the end of a program or a course.
                                In outcome-based education, “product defines process ”.
                                It is the opposite of input-based education where the emphasis is on the teaching and the system is happy to accept whatever is the result.
                            </p>
                        </div>
                        <div className="outcome-image col-md-7" style={{ paddingRight: 0 }}>
                            <img src={`${outcome}`} className="outcome-img" />
                        </div>
                    </div>
                </div>
                <div className="academic-planning">
                    <div className="academicPlanning-header">
                        <Link to="/academic-planning" style={{ color: '#212529' }}><h3>Academic Planning</h3></Link>
                    </div>
                    <div className="planning-info">
                        <div className="planning-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${academicPlanning}`} className="planning-img" />
                        </div>
                        <div className="planning-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                Academic Planning module offers best in class features of online classes, timetable generation, proposed syllabus, completion report, gap analysis, teaching aids/methodologies and student’s attendance of sessions.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="online-feedback">
                    <div className="onlineFeedback-header">
                        <Link to="/online-feedback" style={{ color: '#212529' }}><h3>Online Feedback</h3></Link>
                    </div>
                    <div className="feedback-info">
                        <div className="feedback-text col-md-5" style={{ paddingLeft: 0 }}>
                            <p>
                                It is not only the collection of feedback but it involves the built in analysis, visualization, and response monitoring required for NAAC/ NBA/ NIRF. Indirect attainment level of PO & PSO is determined based on the student exit surveys, employer surveys, co-curricular activities, extracurricular activities etc. PO attainment level will be based on attainment levels of direct assessment and indirect assessment.
                            </p>
                        </div>
                        <div className="feedback-image col-md-7" style={{ paddingRight: 0 }}>
                            <img src={`${feedback}`} className="feedback-img" />
                        </div>
                    </div>
                </div>
                <div className="online-exam">
                    <div className="onlineExam-header">
                        <Link to="/online-exam" style={{ color: '#212529' }}><h3>Online Exam</h3></Link>
                    </div>
                    <div className="exam-info">
                        <div className="exam-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${exam}`} className="exam-img" />
                        </div>
                        <div className="exam-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                Experience the Automation and Intelligence in online exam features & functionality, which makes “vmedulife” the most trusted and best Online Exam Software & Assessment Partner for Colleges, Institutes and Academies.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="result-analysis">
                    <div className="resultAnalysis-header">
                        <Link to="/result-analysis" style={{ color: '#212529' }}><h3>Result Analysis</h3></Link>
                    </div>
                    <div className="result-info">
                        <div className="result-text col-md-5" style={{ paddingLeft: 0 }}>
                            <p>
                                Conducting and managing examination data for offline exam is now effortless with the "vmedulife" Result Analysis module. Starting from examination scheduling, question paper generation, student enrollment, and student performance can be easily managed in the system.
                            </p>
                        </div>
                        <div className="result-image col-md-7" style={{ paddingRight: 0 }}>
                            <img src={`${resultAnalysis}`} className="result-img" />
                        </div>
                    </div>
                </div>
                <div className="rubrics-projects">
                    <div className="rubrics-header">
                        <Link to="/rubrics" style={{ color: '#212529' }}><h3>Rubrics</h3></Link>
                    </div>
                    <div className="rubrics-info">
                        <div className="rubrics-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${rubrics}`} className="rubrics-img" />
                        </div>
                        <div className="rubrics-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                Rubrics is an inspiring guide and a powerful tool for learning, assessment and grading of individual as well as multiple students work. Rubric plays an important role for communicating faculty expectations to students in the assessment and what important is considered.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="assignment">
                    <div className="assignment-header">
                        <Link to="/assignment-management" style={{ color: '#212529' }}><h3>Assignment</h3></Link>
                    </div>
                    <div className="assignment-info">
                        <div className="assignment-text col-md-5" style={{ paddingLeft: 0 }}>
                            <p>
                                “vmedulife” Assignment Module put forward the process of conducting assignments for students performance evaluation which lag behind in their Academics. This can be done easily by assigning assignment to slow learner’s as well as advance learner’s individually.
                            </p>
                        </div>
                        <div className="assignment-image col-md-7" style={{ paddingRight: 0 }}>
                            <img src={`${assignment}`} className="assignment-img" />
                        </div>
                    </div>
                </div>
                <div className="IQAC">
                    <div className="IQAC-header">
                        <Link to="/iqac-reports" style={{ color: '#212529' }}><h3>IQAC Reports</h3></Link>
                    </div>
                    <div className="IQAC-info">
                        <div className="IQAC-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${IQAC}`} className="IQAC-img" />
                        </div>
                        <div className="IQAC-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                As quality enhancement is a continuous process, the IQAC becomes a part of the institution's system and works towards realization of the goals of quality enhancement and sustenance. This IQAC module will play key important role for conscious, consistent and catalytic improvement in the overall performance of institutions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OutcomeEducation;