import React from 'react';
import { Link } from 'react-router-dom';
import inventory from 'WEBSITE/Components/OfficeAutomation/images/inventory.png';
import ILMS from 'WEBSITE/Components/OfficeAutomation/images/ILMS.png';
import employeeManagement from 'WEBSITE/Components/OfficeAutomation/images/employeeManagement.png';
import admission from 'WEBSITE/Components/OfficeAutomation/images/admission.png';
import facultyProfile from 'WEBSITE/Components/OfficeAutomation/images/facultyProfile.png';
import feedback from 'WEBSITE/Components/OfficeAutomation/images/feedback.png';
import grievance from 'WEBSITE/Components/OfficeAutomation/images/grievance.png';
import forms from 'WEBSITE/Components/OfficeAutomation/images/forms.png';
import leaveManagement from 'WEBSITE/Components/OfficeAutomation/images/leaveManagement.png';
import accounting from 'WEBSITE/Components/OfficeAutomation/images/accounting.png';
import fees from 'WEBSITE/Components/OfficeAutomation/images/fees.png';
import payroll from 'WEBSITE/Components/OfficeAutomation/images/payroll.png';
import inward from 'WEBSITE/Components/OfficeAutomation/images/inward.png';
import hostel from 'WEBSITE/Components/OfficeAutomation/images/hostel.png';
import noticeboard from 'WEBSITE/Components/OfficeAutomation/images/noticeboard.png';
import transport from 'WEBSITE/Components/OfficeAutomation/images/transport.png';
import smsPanel from 'WEBSITE/Components/OfficeAutomation/images/smsPanel.png';
import 'WEBSITE/Components/OfficeAutomation/officeautomation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class OfficeAutomation extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <div className="automation-container">
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="automation-Header">
                    <h2>Office Automation (ERP)</h2>
                </div>
                <div className="admission">
                    <div className="admission-header">
                        <Link to="/admission-management" style={{ color: '#212529' }}><h3>Admission Management</h3></Link>
                    </div>
                    <div className="admission-info">
                        <div className="admission-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${admission}`} className="admission-img" />
                        </div>
                        <div className="admission-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                Fully determined, automated and focused management of admission process from enquiry for admission to registration of student for respective academic year with 24X7 assist of tracking the status of student applications throughout the admission process. Generate customized reports based on number of admissions of current as well as previous academic years.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="fees">
                    <div className="fees-header">
                        <Link to="/fees" style={{ color: '#212529' }}><h3>Fees Management</h3></Link>
                    </div>
                    <div className="fees-info">
                        <div className="fees-text col-md-5" style={{ paddingLeft: 0 }}>
                            <p>
                                “vmedulife” Fees Management is well designed and executed software for everyone with supreme features and latest integration of payment gateways mode which will help you to collect fees instantly in hassle free process and with multiple reports just a click away.
                            </p>
                        </div>
                        <div className="fees-image col-md-7" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                                 <img src={`${fees}`} className="fees-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accounting">
                    <div className="accounting-header">
                        <Link to="/accounting" style={{ color: '#212529' }}><h3>Accounting</h3></Link>
                    </div>
                    <div className="accounting-info">
                        <div className="accounting-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${accounting}`} className="accounting-img" />
                        </div>
                        <div className="accounting-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                Industry leading and prominent management of accounting is done by its dominant features of billing, invoicing and reporting; where daily general expenses can be sync balance your sheet to make taxation and audit process seamless.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="employee-management">
                    <div className="employeeManagement-header">
                        <Link to="/employee-management" style={{ color: '#212529' }}><h3>Employee Management</h3></Link>
                    </div>
                    <div className="employeeManagement-info">
                        <div className="employeeManagement-text col-md-5" style={{ paddingLeft: 0 }}>
                            <p>
                                Employee Management is a cloud-based system which allows you to automate and digitize manual data entry and saves time and human efforts. Assist in Managing and storing all important data as well as personal details of employees in a secured way. Tracing and recording of the employee data had never been easier.
                            </p>
                        </div>
                        <div className="employeeManagement-image col-md-7" style={{ paddingRight: 0 }}>
                            <img src={`${employeeManagement}`} className="employeeManagement-img" />
                        </div>
                    </div>
                </div>
                <div className="faculty-profile">
                    <div className="facultyProfile-header">
                        <Link to="/faculty-profile" style={{ color: '#212529' }}><h3>Faculty Profile</h3></Link>
                    </div>
                    <div className="facultyProfile-info">
                        <div className="facultyProfile-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${facultyProfile}`} className="facultyProfile-img" />
                        </div>
                        <div className="facultyProfile-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                Conducting and managing examination data for autonomous institute is now effortless with "vmedulife" Autonomous Examination Software module. Starting from examination scheduling, question paper generation, student enrollment, pre as well as post examination work can be easily managed in the system.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="leave-management">
                    <div className="leaveManagement-header">
                        <Link to="/leave-management" style={{ color: '#212529' }}><h3>Attendance & Leave Management System (AMS)</h3></Link>
                    </div>
                    <div className="leaveManagement-info">
                        <div className="leaveManagement-text col-md-5" style={{ paddingLeft: 0 }}>
                            <p>
                                With the vmedulife Attendance & Leave management system you can fully automate the Attendance & Leave management process of your organisation. From managing the annual holidays to keeping tracks of employee attendance and leaves request all process can be handle effortlessly in few clicks.
                            </p>
                        </div>
                        <div className="leaveManagement-image col-md-7" style={{ paddingRight: 0 }}>
                            <img src={`${leaveManagement}`} className="leaveManagement-img" />
                        </div>
                    </div>
                </div>
                <div className="payroll">
                    <div className="payroll-header">
                        <Link to="/payroll" style={{ color: '#212529' }}><h3>Payroll</h3></Link>
                    </div>
                    <div className="payroll-info">
                        <div className="payroll-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${payroll}`} className="payroll-img" />
                        </div>
                        <div className="payroll-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                Payroll Management offers you the best in class features to plan and execute employees salary and their deductions with customize function of salary templates and payslips with respect to their employees requirements.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="inward">
                    <div className="inward-header">
                        <Link to="/inward-outward" style={{ color: '#212529' }}><h3>Inward / Outward</h3></Link>
                    </div>
                    <div className="inward-info">
                        <div className="inward-text col-md-5" style={{ paddingLeft: 0 }}>
                            <p>
                                Now managing the communication via letter or documents of organization or within organization becomes easy with our "vmedulife" inward and outward module. Keep tracks of inward and outward letters with uniquely generated numbers.
                            </p>
                        </div>
                        <div className="inward-image col-md-7" style={{ paddingRight: 0 }}>
                            <img src={`${inward}`} className="inward-img" />
                        </div>
                    </div>
                </div>
                <div className="smsPanel">
                    <div className="smsPanel-header">
                        <Link to="/sms-panel" style={{ color: '#212529' }}><h3>SMS Panel</h3></Link>
                    </div>
                    <div className="smsPanel-info">
                        <div className="smsPanel-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${smsPanel}`} className="smsPanel-img" />
                        </div>
                        <div className="smsPanel-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                The most innovative, easy to use, and comprehensive SMS service platform. Scale up seamlessly with our robust SMS gateway and send SMS to all registered stakeholders of the organisation. Provide your stakeholders with important updates, notifications, & more by automating internal communication with a unique integrated SMS panel.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="transport">
                    <div className="transport-header">
                        <Link to="/transport" style={{ color: '#212529' }}><h3>Transport Management</h3></Link>
                    </div>
                    <div className="transport-info">
                        <div className="transport-text col-md-5" style={{ paddingLeft: 0 }}>
                            <p>
                                Effortlessly manage your organization transport with our transport management system from managing vehicles, driver details to planning and scheduling trips within few clicks.
                            </p>
                        </div>
                        <div className="transport-image col-md-7" style={{ paddingRight: 0 }}>
                            <img src={`${transport}`} className="transport-img" />
                        </div>
                    </div>
                </div>
                <div className="onlineData-feedback">
                    <div className="onlineFeedback-header">
                        <Link to="/online-feedback" style={{ color: '#212529' }}><h3>Online Feedback</h3></Link>
                    </div>
                    <div className="onlineFeedback-info">
                        <div className="onlineFeedback-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${feedback}`} className="onlineFeedback-img" />
                        </div>
                        <div className="onlineFeedback-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                Collect feedback smartly from your stakeholders like students, employees of the institute, alumni, employers, and parents within a few clicks. Analyze the responses and take the necessary action for overall development.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="grievance">
                    <div className="grievance-header">
                        <Link to="/online-grievance" style={{ color: '#212529' }}><h3>Online Grievance</h3></Link>
                    </div>
                    <div className="grievance-info">
                        <div className="grievance-text col-md-5" style={{ paddingLeft: 0 }}>
                            <p>
                                vmedulife brings to you ‘eGrievance’, a Grievance Redressal System, to manage and redress grievances of the AICTE approved institutions with greater transparency, establishing a very positive ambience in the campus. Led by a team of committed professionals with a combined expertise in the industry, vmedulife has pioneered progressive and groundbreaking solutions that are hallmarks of efficiency. Our unique industry-based approach helps clients envision, build, run and revolutionize the education system on a shoestring. The on-time service of eGrievance helps to report any issues easily.
                            </p>
                        </div>
                        <div className="grievance-image col-md-7" style={{ paddingRight: 0 }}>
                            <img src={`${grievance}`} className="grievance-img" />
                        </div>
                    </div>
                </div>
                <div className="inventory">
                    <div className="inventory-header">
                        <Link to="/inventory-management" style={{ color: '#212529' }}><h3>Inventory Management</h3></Link>
                    </div>
                    <div className="inventory-info">
                        <div className="inventory-image col-md-7" style={{ paddingLeft: 0 }}>
                        <div className="d-flex justify-content-center"></div>
                            <img src={`${inventory}`} className="inventory-img" />
                        </div>
                        <div className="inventory-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                Inventory management is a systematic approach to sourcing and storing Assets. It is an online system that manages assets, keeps track of purchase requests, indents and daily consumption of assets in an organization.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="ILMS">
                    <div className="ILMS-header">
                        <Link to="/library-management" style={{ color: '#212529' }}><h3>Integrated Library Management System</h3></Link>
                    </div>
                    <div className="ILMS-info">
                        <div className="ILMS-text col-md-5" style={{ paddingLeft: 0 }}>
                            <p>
                                Integrated Library Management System also knowns as (ILMS), helps to convert your library into computerized library. ILMS easily handles catalogue books, tracking and maintaining records of books issued, returned and overdue by user. The books can also be stored categorywise which enhances to find the book easily and quickly by user as well as by librarian.
                            </p>
                        </div>
                        <div className="ILMS-image col-md-7" style={{ paddingRight: 0 }}>
                            <img src={`${ILMS}`} className="ILMS-img" />
                        </div>
                    </div>
                </div>
                <div className="forms">
                    <div className="forms-header">
                        <Link to="/forms" style={{ color: '#212529' }}><h3>Forms</h3></Link>
                    </div>
                    <div className="forms-info">
                        <div className="forms-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${forms}`} className="forms-img" />
                        </div>
                        <div className="forms-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                Create and design the various forms as per your requirement. Facility to add public and learner type form. Share the form public links or assign them to students and keep track and record of form responses.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="hostel">
                    <div className="hostel-header">
                        <Link to="/hostel-management" style={{ color: '#212529' }}><h3>Hostel Management</h3></Link>
                    </div>
                    <div className="hostel-info">
                        <div className="hostel-text col-md-5" style={{ paddingLeft: 0 }}>
                            <p>
                                Now you can manage all your hostel activities efficiently with our "vmedulife" hostel management module. Hostel management includes process from admission to room allocation and canteen management.
                            </p>
                        </div>
                        <div className="hostel-image col-md-7" style={{ paddingRight: 0 }}>
                            <img src={`${hostel}`} className="hostel-img" />
                        </div>
                    </div>
                </div>
                <div className="noticeboard">
                    <div className="noticeboard-header">
                        <Link to="/noticeboard" style={{ color: '#212529' }}><h3>Noticeboard</h3></Link>
                    </div>
                    <div className="noticeboard-info">
                        <div className="noticeboard-image col-md-7" style={{ paddingLeft: 0 }}>
                            <img src={`${noticeboard}`} className="noticeboard-img" />
                        </div>
                        <div className="noticeboard-text col-md-5" style={{ paddingRight: 0 }}>
                            <p>
                                Smoothly manage the notices of organization with customize notice category, from posting notice to assign the notice to the limited stakeholders or to all. Maintain records of notices posted till date.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OfficeAutomation;