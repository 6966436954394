import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import leaveView from 'WEBSITE/Components/LeaveManagement/images/leaveView.png';
import manageHoliday from 'WEBSITE/Components/LeaveManagement/images/manageHoliday.png';
import leavePolicy from 'WEBSITE/Components/LeaveManagement/images/leavePolicy.png';
import attendanceLog from 'WEBSITE/Components/LeaveManagement/images/attendanceLog.png';
import leaveApprover from 'WEBSITE/Components/LeaveManagement/images/leaveApprover.png';
import workManagement from 'WEBSITE/Components/LeaveManagement/images/workManagement.png';
import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/LibraryManagement/images/quickBenefits.png';
import goPaperless from 'WEBSITE/Components/LeaveManagement/images/goPaperless.png';
import highCommunication from 'WEBSITE/Components/LeaveManagement/images/highCommunication.png';
import preventError from 'WEBSITE/Components/LeaveManagement/images/preventError.png';
import longTerm from 'WEBSITE/Components/LibraryManagement/images/longTerm.png';
import realTimeData from 'WEBSITE/Components/LeaveManagement/images/realTimeData.png';
import leaveBalance from 'WEBSITE/Components/LeaveManagement/images/leaveBalance.png';
import organizationValue from 'WEBSITE/Components/LeaveManagement/images/organizationValue.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/LeaveManagement/leavemanagement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class LeaveManagement extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Attendance & Leave Management System </h1>
                    </div>
                    <div className="main-text">
                        <h4>
                            With the vmedulife Attendance & Leave management system you can fully automate the Attendance & Leave management process of your organization. From managing the annual holidays to keeping tracks of employee attendance and leaves request all process can be handle effortlessly in few clicks.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report" onClick={() => { document.getElementById('report').scrollIntoView(); }}>
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="leave-view">
                    <div className="leaveView">
                    <div className="d-flex justify-content-center">
                        <img src={`${leaveView}`} className="leaveView-img" />
                        </div>
                    </div>
                </div>
                <div className="features-container2" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                    </div>
                    <div className="manage-holiday">
                        <div className="manage-holiday-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Manage Annual Holidays</h5>
                            <div className="feature-description">
                                <p>Manage annual holidays of the organization departmentwise.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Short code facility
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Access based - only authorize person can manage holidays.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="manageHoliday-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${manageHoliday}`} className="manageHoliday-img" />
                        </div>
                    </div>
                    <div className="leave-policy">
                        <div className="leavePolicy-image col-md-8" style={{ paddingLeft: 0 }}>
                            <img src={`${leavePolicy}`} className="leavePolicy-img" />
                        </div>
                        <div className="leave-policy-text col-md-4" style={{ paddingRight: 0 }}>
                            <h5>Leave Policy</h5>
                            <div className="feature-description">
                                <p>Facility to add fully customize leave policy. Configure the leave policy with customize leave type. Simple way to assign leave policy to employee within selected date range. Facilities to assign multiple leave policies to an employee hassle free.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Copy leave policy option    
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Easily assign or remove leave policy
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Update date range of leave policy assign to the employees
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="attendance-log">
                        <div className="attendance-log-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Attendance Log</h5>
                            <div className="feature-description">
                                <p>Daily attendance of employees can be easily managed by importing attendance and the attendance of employees can be easily managed using filter.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Import attendance facility
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Simple filter to get an employee attendance
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="attendanceLog-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${attendanceLog}`} className="attendanceLog-img" />
                        </div>
                    </div>
                    <div className="leave-approver">
                        <div className="leaveApprover-image col-md-8" style={{ paddingLeft: 0 }}>
                            <img src={`${leaveApprover}`} className="leaveApprover-img" />
                        </div>
                        <div className="leave-approver-text col-md-4" style={{ paddingRight: 0 }}>
                            <h5>Multi Level Leave Approver</h5>
                            <div className="feature-description">
                                <p>Multi level leave and attendance regularization approver can be assigned to the employee. Approvers will accept or reject the leave request and regularization request of the employee.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Only approvers will manage the employee leave requests
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Keep record of requested leave as per the leave year
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Filter to keep track of leaves as per the status
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="workManagement">
                        <div className="workManagement-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Work Load Management</h5>
                            <div className="feature-description">
                                <p>Employees can manage their workload for leave days. They can easily assign their task or work to the other employees effortlessly.</p>
                                {/* <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Multi level approver for indent
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Keep track  of raised indent with status
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="workManagement-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${workManagement}`} className="workManagement-img" />
                        </div>
                    </div>
                </div>
                <div className="benefits-container1" id="benefits">
                    <div className="benefits-info">
                        <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                            <h3>Benefits</h3>
                            {/* <div className="benefits-text">
                                <p>Perhaps the most important benefit of inventory is saving organization money. Enhanced the efficiency & productivity in operations. Gives transparency, decreases inaccuracies and avoid redundancy.</p>
                            </div> */}
                        </div>
                        {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                            <img src={`${benefitsLink}`} className="benefitLink-img" />
                        </div> */}
                    </div>
                    <div className="benefits-data">
                        <div className="quickBenefits1">
                            <div className="quickBenefits1-header">
                                <img src={`${quickBenefits}`} className="quickBenefits-img" />
                                <h3>Quick Benefits</h3>
                            </div>
                            <div className="quickBenefits1-list">
                                <div className="goPaperless">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${goPaperless}`} className="goPaperless-img" />
                                    </div>
                                    <h5>Go Paperless !!!</h5>
                                    <p>Helps to reduce usage of paper and automate the process effortlessly within few hassle free clicks and at your finger tips.</p>
                                </div>
                                <div className="highCommunication">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${highCommunication}`} className="highCommunication-img" />
                                    </div>
                                    <h5>Higher Communication</h5>
                                    <p>Ensures that all leaves and holidays are conveyed back and forth from organization to employee and vice versa with zero communication gap.</p>
                                </div>
                                <div className="preventError">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${preventError}`} className="preventError-img" />
                                    </div>
                                    <h5>Prevents Errors</h5>
                                    <p>It offers best in concept features and facilities which aids in eliminating errors such as pay errors and ensures legal compliance.</p>
                                </div>
                            </div>
                        </div>
                        <div className="longTerm-benefits1">
                            <div className="longTerm-benefits1-header">
                                <img src={`${longTerm}`} className="longTerm-benefits-img" />
                                <h3>Long Term Benefits</h3>
                            </div>
                            <div className="longBenefits1-list">
                                <div className="realTimeData">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${realTimeData}`} className="realTimeData-img" />
                                    </div>
                                    <h5>Real Time Data</h5>
                                    <p>One can easily manage and have access to real time data within few clicks which reduces mis communication and increases transparency in the process.</p>
                                </div>
                                <div className="leaveBalance">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${leaveBalance}`} className="leaveBalance-img" />
                                    </div>
                                    <h5>Auto Update Leave Balance</h5>
                                    <p>Without any errors  and shortcomings, leaves of employees within organization are auto updated if he/she has failed to update their respective leaves.</p>
                                </div>
                                <div className="organizationValue">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={`${organizationValue}`} className="organizationValue-img" />
                                    </div>
                                    <h5>Level up the Organization Value</h5>
                                    <p>Using completely automated process of software and reducing work load of employees eventually leads to grow the organization beyond the sky.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container" id="report">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Leave Policy Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Monthly Leave Summary</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Regularization Summary</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Employees Leave Count Report</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Individual Employee Leave Summary</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Daily Attendance Summary</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Monthly Attendance Summary</p>
                        </div>
                    </div>
                </div>
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container1" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                       
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default LeaveManagement;