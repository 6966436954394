import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ISO from 'WEBSITE/Components/Certifications/images/ISO.png';
import SCGJ from 'WEBSITE/Components/Certifications/images/SCGJ.png';
import NASSCOM from 'WEBSITE/Components/Certifications/images/NASSCOM.png';
import DIPP from 'WEBSITE/Components/Certifications/images/DIPP.png';
import RGAP from 'WEBSITE/Components/Certifications/images/RGAP.png';
import 'WEBSITE/Components/Certifications/certifications.css';

class Certifications extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="certification-header">
                    <h5>Certifications</h5>
                </div>
                <div className="certification-info">
                    <h1>“Certificates & Recognition”</h1>
                </div>
                <div className="company-certifications">
                <div className="company-certifications-list">
                    <div className="company-certification">
                        <div className="trophies">
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABCB1" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABCB1" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABCB1" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABCB1" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABCB1" style={{ marginRight: 5 }} />
                        </div>
                        <div className="ISO-header">
                            <h5>ISO 9001:2015 Company</h5>
                        </div>
                        <div className="ISO-details">
                            <h6>ISO Certified Company</h6>
                        </div>
                    </div>
                    <div className="company-certification">
                        <div className="trophies">
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABC11" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABC11" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABC11" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABC11" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABC11" style={{ marginRight: 5 }} />
                        </div>
                        <div className="SCGJ-header">
                            <h5>Industry Associate of SCGJ, India</h5>
                        </div>
                        <div className="SCGJ-details">
                            <h6>An Industry Partner of SCGJ, India</h6>
                            <h6>Govt. Of India, Initiative</h6>
                        </div>
                    </div>
                    <div className="company-certification">
                        <div className="trophies">
                            <FontAwesomeIcon icon={['fas', 'award']} color="#AC1539" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#AC1539" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#AC1539" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#AC1539" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#AC1539" style={{ marginRight: 5 }} />
                        </div>
                        <div className="NASSCOM-header">
                            <h5>Official NASSCOM Member</h5>
                        </div>
                        <div className="NASSCOM-details">
                            <h6>NASSCOM Member</h6>
                        </div>
                    </div>
                    <div className="company-certification">
                        <div className="trophies">
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0A26BC" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0A26BC" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0A26BC" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0A26BC" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0A26BC" style={{ marginRight: 5 }} />
                        </div>
                        <div className="DIPP-header">
                            <h5>Department of Industrial</h5>
                            <h5>Policy and Promotion</h5>
                        </div>
                        <div className="DIPP-details">
                            <h6>Certificate of Recognition</h6>
                        </div>
                    </div>
                    <div className="company-certification">
                        <div className="trophies">
                            <FontAwesomeIcon icon={['fas', 'award']} color="#630ABC" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#630ABC" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#630ABC" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#630ABC" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#630ABC" style={{ marginRight: 5 }} />
                        </div>
                        <div className="RGAP-header">
                            <h5>Certificate of Recognition -</h5>
                            <h5>Govt. of Andhra Pradesh</h5>
                        </div>
                        <div className="RGAP-details">
                            <h6>AP Innovation Society</h6>
                        </div>
                    </div>
                </div>
            </div>
                {/** 
                <div className="ISO-container">
                    <div className="ISO-image">
                        <img src={`${ISO}`} className="ISO-img" />
                    </div>
                    <div className="ISO-text">
                        <div className="trophies">
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABCB1" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABCB1" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABCB1" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABCB1" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABCB1" style={{ marginRight: 5 }} />
                        </div>
                        <div className="ISO-header">
                            <h3>ISO 9001:2015 Company</h3>
                        </div>
                        <div className="ISO-details">
                            <h6>ISO Certified Company</h6>
                        </div>
                    </div>
                </div>
                <div className="SCGJ-container">
                    <div className="SCGJ-image">
                        <img src={`${SCGJ}`} className="SCGJ-img" />
                    </div>
                    <div className="SCGJ-text">
                        <div className="trophies">
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABC11" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABC11" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABC11" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABC11" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0ABC11" style={{ marginRight: 5 }} />
                        </div>
                        <div className="SCGJ-header">
                            <h3>Industry Associate of SCGJ, India</h3>
                        </div>
                        <div className="SCGJ-details">
                            <h6>An Industry Partner of SCGJ, India</h6>
                            <h6>Govt. Of India, Initiative</h6>
                        </div>
                    </div>
                </div>
                <div className="NASSCOM-container">
                    <div className="NASSCOM-image">
                        <img src={`${NASSCOM}`} className="NASSCOM-img" />
                    </div>
                    <div className="NASSCOM-text">
                        <div className="trophies">
                            <FontAwesomeIcon icon={['fas', 'award']} color="#AC1539" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#AC1539" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#AC1539" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#AC1539" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#AC1539" style={{ marginRight: 5 }} />
                        </div>
                        <div className="NASSCOM-header">
                            <h3>Official NASSCOM Member</h3>
                        </div>
                        <div className="NASSCOM-details">
                            <h6>NASSCOM Member</h6>
                        </div>
                    </div>
                </div>
                <div className="DIPP-container">
                    <div className="DIPP-image">
                        <img src={`${DIPP}`} className="DIPP-img" />
                    </div>
                    <div className="DIPP-text">
                        <div className="trophies">
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0A26BC" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0A26BC" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0A26BC" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0A26BC" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#0A26BC" style={{ marginRight: 5 }} />
                        </div>
                        <div className="DIPP-header">
                            <h3>Department of Industrial</h3>
                            <h3>Policy and Promotion</h3>
                        </div>
                        <div className="DIPP-details">
                            <h6>Certificate of Recognition</h6>
                        </div>
                    </div>
                </div>
                <div className="RGAP-container">
                    <div className="RGAP-image">
                        <img src={`${RGAP}`} className="RGAP-img" />
                    </div>
                    <div className="RGAP-text">
                        <div className="trophies">
                            <FontAwesomeIcon icon={['fas', 'award']} color="#630ABC" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#630ABC" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#630ABC" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#630ABC" style={{ marginRight: 5 }} />
                            <FontAwesomeIcon icon={['fas', 'award']} color="#630ABC" style={{ marginRight: 5 }} />
                        </div>
                        <div className="RGAP-header">
                            <h5>Certificate of Recognition</h5>
                            <h5>Govt. of Andhra Pradesh</h5>
                        </div>
                        <div className="RGAP-details">
                            <h6>AP Innovation Society</h6>
                        </div>
                    </div>
                </div>
            */}
            </>
        );
    }
}

export default Certifications;