import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import employeeView from 'WEBSITE/Components/EmployeeManagement/images/employeeView.png';
import employeeRegistration from 'WEBSITE/Components/EmployeeManagement/images/employeeRegistration.png';
import manageEmployee from 'WEBSITE/Components/EmployeeManagement/images/manageEmployee.png';
import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/ResultAnalysis/images/quickBenefits.png';
import information from 'WEBSITE/Components/EmployeeManagement/images/information.png';
import accessDocuments from 'WEBSITE/Components/EmployeeManagement/images/accessDocuments.png';
import automatedProcess from 'WEBSITE/Components/EmployeeManagement/images/automatedProcess.png';
import longTerm from 'WEBSITE/Components/ResultAnalysis/images/longTerm.png';
import accessEmployee from 'WEBSITE/Components/EmployeeManagement/images/accessEmployee.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/EmployeeManagement/employeemanagement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class EmployeeManagement extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Employee Management</h1>
                    </div>
                    <div className="main-text">
                        <h4>
                        Employee Management Software is a cloud-based system that allows you to automate and digitize manual data entry and saves time and human efforts. Assist in managing and storing all important data as well as personal details of employees in a secured way. Tracing and recording the employee data had never been easier.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report" onClick={() => { document.getElementById('report').scrollIntoView(); }}>
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="employee-view">
                    <div className="employeeView">
                        <img src={`${employeeView}`} className="employeeView-img" />
                    </div>
                </div>
                <div className="features-container2" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                    </div>
                    <div className="employee-registration">
                        <div className="employee-registration-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Employee Registration</h5>
                            <div className="feature-description">
                                <p>Offers the quickest way to register the employees of the organization and to add personal as well as work details of the employee at your fingertip.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Quick Registration
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Easy search option
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Assign Role
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Assign Departments
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Export employee list
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="employeeRegistration-image col-md-8" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                                <img src={`${employeeRegistration}`} className="employeeRegistration-img" />
                            </div>
                        </div>
                    </div>
                    <div className="manage-employee">
                    <div className="manage-employee-text mobileViewShow col-md-4"  style={{ paddingRight: 0 }}>
                    <h5>Manage Employee</h5>
                    <div className="feature-description">
                        <p>Register multiple employees at a time with import facility. Manage the access of registered employees effortlessly.</p>
                        <div className="feature">
                            <div className="checked-image">
                                <img src={`${resourceChecked}`} className="resource-checked-img" />
                            </div>
                            <div className="feature-text">
                                <p className='feature-text-secondary'>
                                    Import employee list
                                </p>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="checked-image">
                                <img src={`${resourceChecked}`} className="resource-checked-img" />
                            </div>
                            <div className="feature-text">
                                <p className='feature-text-secondary'>
                                    Manage access of employees
                                </p>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="checked-image">
                                <img src={`${resourceChecked}`} className="resource-checked-img" />
                            </div>
                            <div className="feature-text">
                                <p className='feature-text-secondary'>
                                    Update details as required
                                </p>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="checked-image">
                                <img src={`${resourceChecked}`} className="resource-checked-img" />
                            </div>
                            <div className="feature-text">
                                <p className='feature-text-secondary'>
                                    Reset login credentials
                                </p>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="checked-image">
                                <img src={`${resourceChecked}`} className="resource-checked-img" />
                            </div>
                            <div className="feature-text">
                                <p className='feature-text-secondary'>
                                    Open/Block employee access
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                        <div className="manageEmployee-image col-md-8" style={{ paddingLeft: 0 }}>
                            <img src={`${manageEmployee}`} className="manageEmployee-img" />
                        </div>
                        <div className="manage-employee-text col-md-4 deskTopViewShow" style={{ paddingRight: 0 }}>
                            <h5>Manage Employee</h5>
                            <div className="feature-description">
                                <p>Register multiple employees at a time with import facility. Manage the access of registered employees effortlessly.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Import employee list
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Manage access of employees
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Update details as required
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Reset login credentials
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Open/Block employee access
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="benefits-container2" id="benefits">
                    <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                        <h3>Benefits</h3>
                        {/* <div className="benefits-text">
                            <p>The supreme benefit of Library Management System is to find the educational material at one place and any time with well segragate form.</p>
                        </div> */}
                    </div>
                    {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                        <img src={`${benefitsLink}`} className="benefitLink-img" />
                    </div> */}
                </div>
                <div className="quickBenefits2">
                    <div className="quickBenefits2-header">
                        <img src={`${quickBenefits}`} className="quickBenefits-img" />
                        <h3>Quick Benefits</h3>
                    </div>
                    <div className="quickBenefits-list">
                        <div className="information">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${information}`} className="information-img" />
                            </div>
                            <h5>Information & Collaboration</h5>
                            <p>Instant sharing of information of employees in well secured and in highly safety manner which also promotes for collaborating with one another on the go.</p>
                        </div>
                        <div className="accessDocuments">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${accessDocuments}`} className="accessDocuments-img" />
                            </div>
                            <h5>Access Documents</h5>
                            <p>Even at the breakdown or maintenance one can easily access  documents on our cloud based software where all information related to employees are saved just for you to access them whenever you want.</p>
                        </div>
                        <div className="automatedProcess">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${automatedProcess}`} className="automatedProcess-img" />
                            </div>
                            <h5>Automated Process</h5>
                            <p>Easy to use and upload information related to employees in a hassle free and simple operation which reduces lethargic work.</p>
                        </div>
                    </div>
                </div>
                <div className="longTerm-benefits2">
                    <div className="longTerm-benefits2-header">
                        <img src={`${longTerm}`} className="longTerm-benefits-img" />
                        <h3>Long Term Benefits</h3>
                    </div>
                    <div className='longTerm-benefits-list'>
                        <div className="accessEmployee">
                            <div className="accessEmployee-image">
                                <img src={`${accessEmployee}`} className="accessEmployee-img" />
                            </div>
                            <div className="accessEmployee-text">
                                <h5>Access Ex-employee</h5>
                                <p>Access to all employees, whether they are existing or Ex, access to their information and data is made super easy. The data or information is highly secured so that no one can access it unless and until it is granted by admin.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-container" id="report">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Employee List</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Blocked employee list</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Departmentwise employee list</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Yearly Registered Employee</p>
                        </div>
                        <div className="reportName col-md-4" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Employee Personal Details</p>
                        </div>
                    </div>
                </div>
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                           
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default EmployeeManagement;