import React from 'react';
import features from 'WEBSITE/Components/LibraryManagement/images/features.png';
import benefits from 'WEBSITE/Components/LibraryManagement/images/benefits.png';
import implementation from 'WEBSITE/Components/LibraryManagement/images/implementation.png';
import report from 'WEBSITE/Components/LibraryManagement/images/report.png';
import support from 'WEBSITE/Components/LibraryManagement/images/support.png';
import autonomyView from 'WEBSITE/Components/AutonomyExamManagement/images/autonomyView.png';
import scheduleAssessment from 'WEBSITE/Components/AutonomyExamManagement/images/scheduleAssessment.png';
import setPaper from 'WEBSITE/Components/AutonomyExamManagement/images/setPaper.png';
import assignAssessment from 'WEBSITE/Components/AutonomyExamManagement/images/assignAssessment.png';
import addPerformance from 'WEBSITE/Components/AutonomyExamManagement/images/addPerformance.png';
import autonomyResult from 'WEBSITE/Components/AutonomyExamManagement/images/autonomyResult.png';
import resourceChecked from 'WEBSITE/Components/LibraryManagement/images/resourceChecked.png';
import benefitsLink from 'WEBSITE/Components/LibraryManagement/images/benefitsLink.png';
import quickBenefits from 'WEBSITE/Components/LibraryManagement/images/quickBenefits.png';
import streamProcess from 'WEBSITE/Components/AutonomyExamManagement/images/streamProcess.png';
import microscopy from 'WEBSITE/Components/AutonomyExamManagement/images/microscopy.png';
import safe from 'WEBSITE/Components/AutonomyExamManagement/images/safe.png';
import longTerm from 'WEBSITE/Components/LibraryManagement/images/longTerm.png';
import goGreen from 'WEBSITE/Components/AutonomyExamManagement/images/goGreen.png';
import trackRecord from 'WEBSITE/Components/AutonomyExamManagement/images/maintainRecord.png';
import oneSolution from 'WEBSITE/Components/AutonomyExamManagement/images/oneSolution.png';
import implementationChecked from 'WEBSITE/Components/LibraryManagement/images/implementationChecked.png';
import implementationFlow from 'WEBSITE/Components/LibraryManagement/images/implementationFlow.png';
import reportLine from 'WEBSITE/Components/LibraryManagement/images/reportLine.png';
import robert from 'WEBSITE/Components/LibraryManagement/images/robert.png';
import marvin from 'WEBSITE/Components/LibraryManagement/images/marvin.png';
import supportImage from 'WEBSITE/Components/LibraryManagement/images/supportImage.png';
import 'WEBSITE/Components/LibraryManagement/librarymanagement.css';
import 'WEBSITE/Components/AutonomyExamManagement/autonomymanagement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AutonomyExamManagement extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="main-info">
                    <div className="main-header">
                        <h1>Autonomous Examination Software</h1>
                    </div>
                    <div className="main-text">
                        <h4>
                            Conducting and managing examination data for autonomous institute is now effortless with "vmedulife" Autonomous Examination Software module. Starting from examination scheduling, question paper generation, student enrollment, pre as well as post examination work can be easily managed in the system.
                        </h4>
                    </div>
                </div>
                <div className="page-menus">
                    <div className="features" onClick={() => { document.getElementById('features').scrollIntoView(); }}>
                        <img src={`${features}`} className="menu-img" />
                        <p className="features-menu-text">Features</p>
                    </div>
                    <div className="benefits" onClick={() => { document.getElementById('benefits').scrollIntoView(); }}>
                        <img src={`${benefits}`} className="menu-img" />
                        <p className="benefits-menu-text">Benefits</p>
                    </div>
                    <div className="implementation" onClick={() => { document.getElementById('implementation').scrollIntoView(); }}>
                        <img src={`${implementation}`} className="menu-img" />
                        <p className="implementation-menu-text">Implementation</p>
                    </div>
                    <div className="report">
                        <img src={`${report}`} className="menu-img" />
                        <p className="report-menu-text">Reports</p>
                    </div>
                    <div className="support" onClick={() => { document.getElementById('support').scrollIntoView(); }}>
                        <img src={`${support}`} className="menu-img" />
                        <p className="support-menu-text">Support</p>
                    </div>
                </div>
                <div className="autonomy-view">
                    <div className="autonomyView">
                        <img src={`${autonomyView}`} className="autonomyView-img" />
                    </div>
                </div>
                <div className="features-container2" id="features">
                    <div className="features-list">
                        <div className="features-header">
                            <h3>Features</h3>
                        </div>
                    </div>
                    <div className="schedule-assessment">
                        <div className="schedule-assessment-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Schedule Assessment</h5>
                            <div className="feature-description">
                                <p>Being the first step in the examination, planning the examination using cloud software will be smooth. Include the examination for multiple semesters, departments at a time and generate the timetable within clicks.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Plan the examination for multiple departments
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Include multiple examination schemes such as regular, summer, backlog examinations
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Conduct examination offline as well as online
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Facilitate multiple SET for one subject
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="scheduleAssessment-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${scheduleAssessment}`} className="scheduleAssessment-img" />
                        </div>
                    </div>
                    <div className="set-paper">
                        <div className="setPaper-image col-md-8" style={{ paddingLeft: 0 }}>
                            <img src={`${setPaper}`} className="setPaper-img" />
                        </div>
                        <div className="set-paper-text col-md-4" style={{ paddingRight: 0 }}>
                            <h5>Set Question Paper</h5>
                            <div className="feature-description">
                                <p>Access based system for generating question paper for the examination. Demand multiple SET for one question paper and allocate randomly to the students. Include questions from the question bank as well as import the questions as required.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Various types of questions i.e., objective, one word and descriptive to design question paper
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Facility of import questions
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Shuffle questions as well as answers
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="assign-assessment">
                        <div className="assign-assessment-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Assign Assessment</h5>
                            <div className="feature-description">
                                <p>Assign a particular SET to the students or let the system decide SET for different students. Decide the access timings.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Auto select Question Paper SET
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Customize Access Timings
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Restrict mobile device access
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Restrict access by IP address
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="assignAssessment-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${assignAssessment}`} className="assignAssessment-img" />
                        </div>
                    </div>
                    <div className="add-performance">
                        <div className="addPerformance-image col-md-8" style={{ paddingLeft: 0 }}>
                            <img src={`${addPerformance}`} className="addPerformance-img" />
                        </div>
                        <div className="add-performance-text col-md-4" style={{ paddingRight: 0 }}>
                            <h5>Add Performance</h5>
                            <div className="feature-description">
                                <p>Get the questionwise analysis of every student with quick view of overall performance. One can easily display the student performance during the exam if required, it is accessed by faculty. Customize the performance report. Export the complete report and print if required. Get Outcome Attainment reports quickly after examination is completed.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Analyzes each and every student performance
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Outcome attainment report of every student as well as for complete class
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="autonomyResult">
                        <div className="autonomyResult-text col-md-4" style={{ paddingLeft: 0 }}>
                            <h5>Result</h5>
                            <div className="feature-description">
                                <p>Generate the result of examination of all subjects within clicks. Combine the subjects as well as regular and backlog students in the reports.</p>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Fully secured and confidential
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Store students for as long as you need for many years
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Mark uploading facility for offline examination
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Re-evaluate the performance
                                        </p>
                                    </div>
                                </div>
                                <div className="feature">
                                    <div className="checked-image">
                                        <img src={`${resourceChecked}`} className="resource-checked-img" />
                                    </div>
                                    <div className="feature-text">
                                        <p className='feature-text-secondary'>
                                            Locking system for completed examination
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="autonomyResult-image col-md-8" style={{ paddingRight: 0 }}>
                            <img src={`${autonomyResult}`} className="autonomyResult-img" />
                        </div>
                    </div>
                </div>
                <div className="benefits-container" id="benefits">
                    <div className="benefits-header col-md-6" style={{ paddingLeft: 0 }}>
                        <h3>Benefits</h3>
                        {/* <div className="benefits-text">
                            <p>The supreme benefit of Library Management System is to find the educational material at one place and any time with well segragate form.</p>
                        </div> */}
                    </div>
                    {/* <div className="benefits-image col-md-6" style={{ paddingRight: 0 }}>
                        <img src={`${benefitsLink}`} className="benefitLink-img" />
                    </div> */}
                </div>
                <div className="quickBenefits">
                    <div className="quickBenefits-header">
                        <img src={`${quickBenefits}`} className="quickBenefits-img" />
                        <h3>Quick Benefits</h3>
                    </div>
                    <div className="quickBenefits-list">
                        <div className="streamProcess">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${streamProcess}`} className="streamProcess-img" />
                            </div>
                            <h5>Streamline Process</h5>
                            <p>Offers very smooth and easy process of conducting assessment and assigning to respective students within few clicks at your finger tips</p>
                        </div>
                        <div className="microscopy">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${microscopy}`} className="microscopy-img" />
                            </div>
                            <h5>Microscopic Details</h5>
                            <p>Ensures to have every single details of assessment with respect to individual student which helps to track progress</p>
                        </div>
                        <div className="safe">
                            <div style={{ textAlign: 'center' }}>
                                <img src={`${safe}`} className="safe-img" />
                            </div>
                            <h5>Safe & Secure</h5>
                            <p>The highly secure and safe process with access based system for high-end security.</p>
                        </div>
                    </div>
                </div>
                <div className="longTerm-benefits">
                    <div className="longTerm-benefits-header">
                        <img src={`${longTerm}`} className="longTerm-benefits-img" />
                        <h3>Long Term Benefits</h3>
                    </div>
                    <div className='longTerm-benefits-list'>
                        <div className="go-green">
                            <div className="go-green-image">
                                <img src={`${goGreen}`} className="go-green-img" />
                            </div>
                            <div className="go-green-text">
                                <h5>GO GREEN</h5>
                                <p>Conducting assessments on the go helps to reduce the use of paper and allow you to support the GO GREEN movement.</p>
                            </div>
                        </div>
                        <div className="trackRecord">
                            <div className="trackRecord-image">
                                <img src={`${trackRecord}`} className="trackRecord-img" />
                            </div>
                            <div className="trackRecord-text">
                                <h5>Maintain and Track Records</h5>
                                <p>One can easily and quickly access to the records and logs of each and every student within few clicks of existing students as well as passout students for reference.</p>
                            </div>
                        </div>
                        <div className="oneSolution">
                            <div className="oneSolution-image">
                                <img src={`${oneSolution}`} className="oneSolution-img" />
                            </div>
                            <div className="oneSolution-text">
                                <h5>One Stop Solution</h5>
                                <p>Gives you the liberty and access to have all your important documents related to assessment as well as records at one place in well segregated manner.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="implementation-container" id="implementation">
                    <div className="implementation-header">
                        <h3>Implementation</h3>
                    </div>
                    <div className="implementation-data">
                        <div className="implementation-list col-md-6" style={{ paddingLeft: 0 }}> 
                            <div className="implementation-text" style={{ paddingLeft: 0 }}>
                                <p>vmedulife team will work with you for consistent implementation support to meet your unique requirements and achieve your organization goals.</p>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Identify the pain areas in organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Design and execute Training plan for successful implementation of platform
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Successful Training of platform at organization
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Provide single point of contact for implementation
                                    </p>
                                </div>
                            </div>
                            <div className="feature">
                                <div className="checked-image">
                                    <img src={`${implementationChecked}`} className="implementation-checked-img" />
                                </div>
                                <div className="feature-text">
                                    <p className='feature-text-secondary'>
                                        Help desk portal for any query and custom enhancement
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="implementationFlow-image col-md-6" style={{ paddingRight: 0 }}>
                            <div className="d-flex justify-content-center">
                            <img src={`${implementationFlow}`} className="implementationFlow-img" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="report-container">
                    <div className="report-header">
                        <h3>Reports</h3>
                    </div>
                    <div className="report-list">
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Available Quantity</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Check-in / Check-out Asset Report</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Stock Verification Report</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Categorized Asset</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Location</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Low Stock Quantity</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Utilized Quantity</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Report of Asset linked to Category</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Inward Verification Report</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Student Cumulative Cost Report</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Breakage & Waste Quantity</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per UnCategorized Asset</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Expiry Date</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Report of Asset Linked to Location</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Purchase Request Report</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Student Issue Cumulative Quantity Report</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Quotation Comparison</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Individual Breakage & Waste Report</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Received Date</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Report of Asset Linked to Department</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Daily Material Movement</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Asset Report as per Regular Maintenance</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Equipment Corrective Maintenance Report</p>
                        </div>
                        <div className="reportName col-md-3" style={{ padding: 0 }}>
                            <img src={`${reportLine}`} className="reportLine-img" />
                            <p>Purchase Order Report</p>
                        </div>
                    </div>
                </div> */}
                {/* <div className="testimonials">
                    <h3 style={{ padding: 30 }}>Testimonials</h3>
                    <div className="library-testimonials-container">
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-1">
                            <div className="testimonial-header">
                                <img src={`${robert}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Robert Fox</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                        <div className="library-testimonial-2">
                            <div className="testimonial-header">
                                <img src={`${marvin}`} className="testimonial-pic" />
                                <div>
                                    <p className="empName">Marvin McKinney</p>
                                    <p className="text-muted designation">(Designation)</p>
                                </div>
                            </div>
                            <div className="testimonial-content">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo eu platea hendrerit pellentesque risus dapibus. Turpis malesuada morbi eget tempus. Vel maecenas velit nec quis at mi aliquam accumsan. Scelerisque accumsan bibendum orci, neque amet.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="support-container" id="support">
                    <div className="support-data col-md-6" style={{ padding: 0 }}>
                        <div className="support-header">
                            <h3>Support</h3>
                        </div>
                        <div className="support-text">
                            <div className="support-description">
                            <p>We have a dedicated team to support our valuable users which will help you to achieve the goals with the help of vmedulife's inbuilt Technical Support Portal, from where faculty members of can raise the respective concerns or suggestions. Admin from the organization can also verify logs of queries and suggestions for maintaining the track and records.</p>
                            </div>
                          
                        </div>
                    </div>
                    <div className="support-image col-md-6" style={{ padding: 0 }}>
                        <div className="d-flex justify-content-center">
                        <img src={`${supportImage}`} className="support-img" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AutonomyExamManagement;