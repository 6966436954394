import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import 'mdbreact/dist/css/mdb.css';

import App from 'App';
// NEW LOGIC TO SHOW THE START UP SCREEN

library.add(fab, far, fas);

ReactDOM.render(
	<App />,
	document.getElementById('root')
);
