import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import 'WEBSITE/Components/KnowledgeBase/knowledgebase.css';

class KnowledgeBase extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="knowledge-header">
                    <h5>Knowledge Base</h5>
                </div>
                <div className="knowledge-message">
                    <h3>We are happy to announce that all product related documentation are now available on public domain.</h3>
                </div>
                <div className="enquiry-msg">
                    <h6>For product related documentation contact us by filling form</h6>
                </div>
                <div className="enquiry-form-container">
                <div className="send-enquiry">
                <iframe className="sales-enquiry-form" src='https://crm.zoho.com/crm/WebFormServeServlet?rid=20c5ee73b3c6c2352cdc8b4cb384297b2e952982d6cb96147cccbfef7c7f441agid412e4dcdb9aa879f2c10c0102d6c6fbf9e6d8b57fbd544c7342c0bbd73d8a809'></iframe>
            </div>
                   {/** <div className="enquiry-form col-md-4">
                        <div style={{ display: 'flex', marginBottom: 50 }}>
                            <span style={{ color: 'red' }}>*</span>
                            <input
                                type="text"
                                placeholder="Enter your Email Address"
                                className="form-control"
                            />
                        </div>
                        <div style={{ display: 'flex', marginBottom: 50 }}>
                            <span style={{ color: 'red' }}>*</span>
                            <input
                                type="text"
                                placeholder="Enter your First Name"
                                className="form-control"
                            />
                        </div>
                        <div style={{ display: 'flex', marginBottom: 50 }}>
                            <span style={{ color: 'red' }}>*</span>
                            <input
                                type="text"
                                placeholder="Enter your Last Name"
                                className="form-control"
                            />
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <button className="btn submit-knowledge">Submit</button>
                        </div>
                    </div> */}
                </div>
            </>
        );
    }
}

export default KnowledgeBase;