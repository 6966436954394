import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import contactImage from 'WEBSITE/Components/ContactUs/images/contactImage.png';
import 'WEBSITE/Components/ContactUs/contactus.css';
import axios from 'axios';

class ContactUs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            instituteUrl: this.props.match.params.instituteUrl,
            instituteName: '',
            instituteLogo: '',
            intituteImage: '',
            instituteDetailsFetching: false,
            siteKey: '6LeSD6YUAAAAAJ2WT-mbD42c5DqVsa1q_KScQ-Xy'
        };
    }

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="enquiry col-md-12">
                <h2>Sales Enquiry</h2>
                    <div className="enquiry-container">
                        <div className="enquiryData col-md-4">
                            <div className="send-enquiry">
                                <iframe className="sales-enquiry-form" src='https://crm.zoho.com/crm/WebFormServeServlet?rid=20c5ee73b3c6c2352cdc8b4cb384297b2e952982d6cb96147cccbfef7c7f441agid412e4dcdb9aa879f2c10c0102d6c6fbf9e6d8b57fbd544c7342c0bbd73d8a809'></iframe>
                            </div>
                            <div className="row mt-3">
                            <div className="col-md-4">
                                <div >
                                Sales-
                                </div>
                            </div>
                            <div className="col-md-4">
                            <a href="tel:+91 9623964758">+919623964758</a>
                            </div>
                            <div className="col-md-4">
                            <a href=" mailto:sales@vmedulife.com"> sales@vmedulife.com</a>
                            </div>
                        </div>
                        <div className="row ">
                        <div className="col-md-4">
                            <div >
                            Support-
                            </div>
                        </div>
                        <div className="col-md-4">
                        <a href="tel:+91 8390701133">+918390701133</a>
                        </div>
                        <div className="col-md-4">
                        <a href=" mailto:info@vmedulife.com">info@vmedulife.com</a>
                        </div>
                    </div>
                    <div className="row ">
                    <div className="col-md-4">
                        <div >
                        HR-
                        </div>
                    </div>
                    <div className="col-md-4">
                        <a href="tel:080-473-60704 ">080-473-60704 </a>
                    </div>
                    <div className="col-md-4">
                        <a href=" mailto:hr@vmedulife.com">hr@vmedulife.com</a>
                    </div>
                </div>
                            <div className="team-numbers">
                        

                       
                            </div>
                        </div>
                        <div className="office-locations col-md-4">
                            <div className="pune-office">
                                <div className="office-header" style={{ display: 'flex' }}>
                                    <FontAwesomeIcon icon={['fas','map-marker-alt']} className="location-icon" size="lg" color="#921E1E" />
                                    <h3>Pune</h3>
                                </div>
                                <div>
                                    <p className="address">vmedulife Software </p>
                                </div>
                                <div>
                                    <p className="address">2nd Floor, EFC Business Centre,</p>
                                </div>
                                <div>
                                    <p className="address">Rajiv Gandhi Infotech Park,</p>
                                </div>
                                <div>
                                    <p className="address">Hinjewadi Phase 1,</p>
                                </div>
                                <div>
                                    <p className="address">Pune, Maharashtra</p>
                                </div>
                                <div>
                                    <p className="address">Pin - 411 057</p>
                                </div>
                            </div>
                            <hr />
                         
                        
                            <div className="banglore-office">
                                <div className="office-header" style={{ display: 'flex' }}>
                                    <FontAwesomeIcon icon={['fas','map-marker-alt']} size="lg" className="location-icon" color="#921E1E" />
                                    <h3>Bengaluru</h3>
                                </div>
                                <div>
                                    <p className="address">vmedulife software services</p>
                                </div>
                                <div>
                                    <p className="address">Brigade IRV Centre,</p>
                                </div>
                                <div>
                                    <p className="address">09th &10th Floor,</p>
                                </div>
                                <div>
                                    <p className="address">Nallurahalli, Whitefield,</p>
                                </div>
                                <div>
                                    <p className="address">Bangalore,India</p>
                                </div>
                                <div>
                                    <p className="address">PIN - 560 066</p>
                                </div>
                            </div>
                            <hr />
                            <div className="hyderabad-office">
                                <div className="office-header" style={{ display: 'flex' }}>
                                    <FontAwesomeIcon icon={['fas','map-marker-alt']} size="lg" className="location-icon" color="#921E1E" />
                                    <h3>Hyderabad</h3>
                                </div>
                                <div>
                                    <p className="address">vmedulife Software</p>
                                </div>
                                <div>
                                    <p className="address">Unit No 203, 2nd Floor,</p>
                                </div>
                                <div>
                                    <p className="address">SBR CV Towers, Sector-I,</p>
                                </div>
                                <div>
                                    <p className="address">Survey No. 64, HUDA Techno Enclave,</p>
                                </div>
                                <div>
                                    <p className="address">Madhapur, Hyderabad - 500081</p>
                                </div>
                            </div>
                            <hr />
                            <div className="kerala-office">
                                <div className="office-header" style={{ display: 'flex' }}>
                                    <FontAwesomeIcon icon={['fas','map-marker-alt']} size="lg" className="location-icon" color="#921E1E" />
                                    <h3>Kerala</h3>
                                </div>
                                <div>
                                    <p className="address">vmedulife Software</p>
                                </div>
                                <div>
                                    <p className="address">Spacelance,</p>
                                </div>
                                <div>
                                    <p className="address">No. 4/461, 2nd Floor,</p>
                                </div>
                                <div>
                                    <p className="address">Valamkottil Towers, Judgemukku,</p>
                                </div>
                                <div>
                                    <p className="address">Kakkanad, Cochin</p>
                                </div>
                                <div>
                                    <p className="address">Kerala, India 682 021</p>
                                </div>
                            </div>
                            <hr />
                            <div className="pune-office">
                                <div className="office-header" style={{ display: 'flex' }}>
                                    <FontAwesomeIcon icon={['fas','map-marker-alt']} className="location-icon" size="lg" color="#921E1E" />
                                    <h3>Gujarat</h3>
                                </div>
                                <div>
                                    <p className="address">vmedulife Software</p>
                                </div>
                                <div>
                                    <p className="address">501, 5th Floor, Shapath 1,</p>
                                </div>
                                <div>
                                    <p className="address">Suite No.1061 SG Highway,</p>
                                </div>
                                <div>
                                    <p className="address">Bodakdev,</p>
                                </div>
                                <div>
                                    <p className="address">Ahmedabad, Gujarat</p>
                                </div>
                                <div>
                                    <p className="address">Pin - 380 054</p>
                                </div>
                            </div>
                            <hr />
                            <div className="delhi-office">
                                <div className="office-header" style={{ display: 'flex' }}>
                                    <FontAwesomeIcon icon={['fas','map-marker-alt']} className="location-icon" size="lg" color="#921E1E" />
                                    <h3>Delhi</h3>
                                </div>
                                <div>
                                    <p className="address">vmedulife Software</p>
                                </div>
                                <div>
                                    <p className="address">Regus Assotech, Upper Ground Level,</p>
                                </div>
                                <div>
                                    <p className="address">Tower 2, Assotech Business Cresterra,</p>
                                </div>
                                <div>
                                    <p className="address">Sec – 135,</p>
                                </div>
                                <div>
                                    <p className="address">Noida, UP</p>
                                </div>
                                <div>
                                    <p className="address">Pin - 201 301</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ContactUs;